import {GET_WALMART_SUCCESS} from "./walmart.types";

const initialState = {
    walmartProducts: null,
};

export const walmartReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Walmart Products */

        case GET_WALMART_SUCCESS:
            return {
                ...state,
                walmartProducts: action.payload,
            }

        /** End */

        default:
            return state;
    }
};
