import React from 'react';
import {NameRow} from 'components';
import {Images} from 'assets/images';

const renderButtons = (data, info, load) => {
    return <div className="del-edit-wrapper">
        <button onClick={() => data('edit')}>
            <img src={Images.Edit} alt={'icon'}/>
            <p className="edit-text">Edit</p>
        </button>
        <button onClick={() => data('delete')}>
            <img src={Images.Remove} alt={'icon'}/>
            <p className="delete-text">Delete</p>
        </button>
    </div>;
};

export const shopifyHead = [
    {name: 'searchRequest', nameRow: 'sku', title: 'Shopify SKU', custom: false},
    // { name: 'sku', title: 'Shopify SKU' },
    {name: 'productType', title: 'Type', custom: false},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange'},
];

export const shopifyBody = [
    // { rowText: (item) => <NameRow name={item?.sku} />, notClickable: true },
    // { rowText: (item) => <TextRow  big={true} size={20} name={'item?.erplyCode'} />, notClickable: true },
    // { rowText: (item) => <NameRow name={item?.multiplier} />, notClickable: true },
    // { rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'} />, notClickable: true },
    // { button: (item, info, load) => renderButtons(item, info, load), notClickable: true },
];


export const shopifyOrderHead = [
    {name: 'searchRequest', nameRow: 'orderId', title: ' Shopify Order ID', custom: false},
    {name: 'addedDate', title: 'Added Date,Time', custom: false},
    {name: 'createdAt', title: 'Created Date', custom: false},
    {name: 'Status', title: 'Status', custom: false},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange'},
];

export const shopifyOrderBody = [
    {rowText: (item) => <NameRow name={item?.sku}/>, notClickable: true},
    {rowText: (item) => <NameRow big={true} size={20} name={item?.erplyCode}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.multiplier}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'}/>, notClickable: true},
    {button: (item, info, load) => renderButtons(item, info, load), notClickable: true},
];


export const shopifyInventoryTransferHead = [
    {name: 'transferId', title: 'Transfer ID', custom: false},
    {name: 'transferDate', title: 'Transfer Date', custom: false},
    {name: 'warehouseFrom', title: 'Warehouse From', custom: false},
    {name: 'warehouseTo', title: 'Warehouse To', custom: false},
    {name: 'qty', title: 'Qty', custom: false},
    {name: 'status', title: 'Status', custom: false},
];

export const shopifyInventoryTransferBody = [
    {rowText: (item) => <NameRow name={item?.sku}/>, notClickable: true},
    {rowText: (item) => <NameRow big={true} size={20} name={item?.erplyCode}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.multiplier}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'}/>, notClickable: true},
    {button: (item, info, load) => renderButtons(item, info, load), notClickable: true},
];


