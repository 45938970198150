import React from "react";
import {CircularProgress} from "@mui/material";

export const MinLoader = ({color, margin, style = {}, position, }) => {
    const styles = {
        loaderStyle: {
            position:position ? position : "absolute",
            color: color,
            width: "15px",
            height: "15px",
            margin: margin ? margin :  "5px 0 0 10px",
        },
    };
    return <CircularProgress style={{ ...styles.loaderStyle, ...style }} />;
};



export const InputMinLoader = ({ style = {} }) => {
    const styles = {
        loaderStyle: {
            position: "absolute",
            color: '#ba43b1',
            width: "20px",
            height: "20px",
            right:'10px'
        },
    };
    return <CircularProgress style={{ ...styles.loaderStyle, ...style }} />;
};
