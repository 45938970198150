import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {Users, Amazon, Walmart, Ebay, Erplay, Carecraft, Shopify} from 'pages';

export const Router = () => {
    return (
        <Switch>
            <Route path={'/users'} component={Users} />
            <Route path={'/erply'} component={Erplay} />
            <Route path={'/amazon'} component={Amazon} />
            <Route path={'/shopify'} component={Shopify} />
            {/*<Route path={'/walmart'} component={Walmart} />*/}
            {/*<Route path={'/ebay'} component={Ebay} />*/}
            <Route path={'/carecraft'} component={Carecraft} />
            <Redirect to={'/'} />
        </Switch>
    );
};
