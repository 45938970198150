import {
    addPoNumber,
    getCarecraft,
    sentCareCraft,
    getCareCraftSetup,
    createCareCraftSetup,
    deleteCareCraft,
    addCarecraftMap,
    editCarecraftMap,
    getCarecraftMap,
    deleteCarecraftMap,
} from './carecraft.action';
export { carecraftReducer } from './carecraft.reducer';
export { watchCarecraft } from './carecraft.saga';

export const carecraftActions = {
    /** CareCraft */
    getCarecraft,
    deleteCareCraft,
    sentCareCraft,
    addPoNumber,
    /** End */

    /** CareCraft Setup */
    getCareCraftSetup,
    createCareCraftSetup,
    /** End */

    /** CareCraft Map */
    addCarecraftMap,
    editCarecraftMap,
    getCarecraftMap,
    deleteCarecraftMap,
    /** End */
};
