import React, {useState} from "react";
import {Backdrop, Modal} from "@mui/material";

export const SimpleModal = ({openDefault, handleOpenClose, content, backdropCustom, disableScrollLock}) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        if (handleOpenClose) {
            handleOpenClose()
        } else {
            setOpen(false);
        }
    };

    const body = (
        <div>
            {content}
        </div>
    );

    return (
            <Modal
                disableScrollLock={disableScrollLock}
                open={openDefault ? openDefault : open}
                onClose={handleClose}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                // className={backdropCustom === true ? 'my-profile' : ''}
                style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    // zIndex:'9999'
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}

            >
                {body}
            </Modal>
    );
}
