import { TableCell, TableRow } from '@mui/material';

export const TableNoInfo = ({ text, styles }) => {
    return (
        <TableRow style={{...styles}} className='no-info-wrapper'>
            <TableCell className='no-info-text'>
                {text}
            </TableCell>
        </TableRow>
    );
};