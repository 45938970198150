import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FindError } from 'hooks';
import { FindSuccess } from 'hooks';
import { EmailValidator, SavePage, useModal } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, userActions } from 'store';
import { CloseButton, AddModalButton } from 'components';
import { TextField } from '@mui/material';
import { TEXT_FIELD_STYLES_SMALL } from './constants';

export const InviteUser = ({ info }) => {
    const { handleSubmit, control, register, reset, formState: { errors } } = useForm({
            defaultValues: { userName: '', email: '' },
        });
    const ACTION_TYPE = info ? 'EDIT_USER' : 'INVITE_USER';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const backError = FindError();
    const success = FindSuccess(ACTION_TYPE);
    const { close } = useModal();

    useEffect(() => {
        if (info) {
            reset({
                userName: info?.firstName,
                email: info?.email,
            });
        }
    }, [info]);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            SavePage(history, pushInfo, { ...pushInfo });
        }
    }, [success]);

    const handleClear = () => {
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
    };

    const emailError = () => {
        if (errors?.email?.type === 'pattern') {
            return 'Not Valid Email';
        } else if (backError && backError?.error === 'User already exists') {
            return 'User already exists';
        }
    };

    const onSubmit = (data) => {
        if (info) {
            const sendInfo = {
                ...data,
                firstName: data.userName,
            };
            delete sendInfo.userName;
            dispatch(userActions.editUser(sendInfo, info?.id));
        } else {
            dispatch(userActions.inviteUser(data));
        }
    };

    return (
        <div className='add-modal-fragment'>
            <div className='modal-header'>
                <CloseButton handleClose={close} />
            </div>
            <div className='modal-body'>
                <p className='add-modal-title'>Want to Invite User?</p>
                <p className='add-modal-subtitle'>To invite another user in the system, please entered the name and
                    email.</p>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Controller
                            name={'userName'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('userName', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Username'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                        <Controller
                            name={'email'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('email', { require: true, pattern: EmailValidator })}
                                    required={true}
                                    onChange={onChange}
                                    onKeyDown={handleClear}
                                    value={value}
                                    label={'Email'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                    error={emailError()}
                                    helperText={emailError()}
                                />
                            )}
                        />
                        <AddModalButton
                            text={info ? 'Save' : 'Invite'}
                            load={ACTION_TYPE}
                            type='submit'
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
