export const LoginErrorMessage = (type) => {
    return type === 'Check your email to activate your account' ? 'Check your email to activate your account' :
        type === "Your account has been inactivated, contact an administrator." ? "Your account has been inactivated, contact an administrator." : ''

}

export const NotMathEmail = (type, error, validation) => {
    return type === "User with this email was not found" ? "User with this email was not found" :
        error === "notMathLogin" ? "Input is not field"
            : validation === "Not valid email" ? "Not valid email"
            : "";
}

export const NotMathPassword = (loginError, error ) => {
    return loginError === "user password does not match" ? "User password does not match" :
        error === "notMathPassword" ? "Input is not field" : "";
}