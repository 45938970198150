import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {Accordion, AccordionSummary, AccordionDetails, Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FindLoad} from 'hooks';
import {CustomDeleteModal} from 'fragments';
import {SendPageSave, SavePage, useModal} from 'utils';
import {SearchAndFilter, PaginationItem, Loader, TableNoInfo, TextRow, DateRow, MinLoader} from 'components';
import {tableStyle} from 'components/table/styles';
import {shopifyActions} from 'store';
import {Images} from 'assets/images';
import {AddProductModal} from "./addProductModal";

export const ShopifyMap = (
    {
        head,
        list,
        loadingType,
        margin,
        status,
        listCount,
        handleSearchReq,
        type,
        handleOpen,
        renderParams,
    }) => {
    const dispatch = useDispatch();
    const classes = tableStyle();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const info = history?.location?.state;
    const [selectedName, setSelectedName] = useState('');
    const loader = FindLoad(loadingType ? loadingType : '');
    const [listInfo, setListInfo] = useState('');
    const {open} = useModal();
    const loadActionBtn = FindLoad('SYNC_RESYNC_SHOPIFY');
    const [currentId, setCurrentId] = useState('');

    useEffect(() => {
        if (list) {
            setListInfo(list);
        }
    }, [list]);


    useEffect(() => {
        const anotherInfo = {...info};
        info?.status ? (anotherInfo.status = info.status) : status ? (anotherInfo.status = status) : delete anotherInfo.status;
        SavePage(history, info, {...anotherInfo});
    }, []);

    useEffect(() => {
        if (info) {
            info?.page !== page && setPage(info?.page);
            // eslint-disable-next-line no-unused-expressions
            info?.sortType ? setSelectedName(info?.sortType) : '';
        }
    }, [info]);

    const setName = (name) => {
        const anotherInfo = {...info};
        if (name) {
            anotherInfo.sortType = name;
        } else {
            delete anotherInfo.sortType;
        }
        SavePage(history, info, {...anotherInfo});
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    const handleChange = (e, type, nameRow) => {
        if (e?.target?.name === 'searchRequest') {
            handleSearchReq(e.target.value, nameRow);
        } else {
            if (e.target.name) {
                if (type !== 'notChange') {
                    const info = list.filter((el) =>
                        type === 'date'
                            ? el[e.target.name] &&
                            moment(el[e.target.name]).format('DD/MM/YYYY').toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                            : el[e.target.name] && el[e.target.name].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1,
                    );
                    setListInfo(info);
                }
            }
        }
    };

    const handleOpenEdit = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentId(item?.id);
        if (info?.tab === 1) {
            open( <AddProductModal product={item}/>)
        } else {
            dispatch(shopifyActions.syncResync('', item?.id, renderParams()));

        }
    };

    const handleOpenDel = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open(<CustomDeleteModal
            handleDelete={() => dispatch(shopifyActions.deleteShopifyProduct(item?.id))}
            actionType={'DELETE_SHOPIFY_PRODUCT'}/>,
        );
    };

    const renderStatus = (status) => {
        if (status === 'FULLY_SUCCESSFUL') {
            return (
                <div className="fully-successful">
                    Fully Successful
                </div>
            );
        } else if (status === 'PARTIALLY_SUCCESSFUL') {
            return (
                <div className="partial-successful">
                    Partial Successful
                </div>
            );
        } else if (status === 'UNSUCCESSFUL') {
            return (
                <div className="unsuccessful">
                    Unsuccessful
                </div>
            );
        }
    };

    const renderItemStatus = (status) => {
        if (status === 'FAILURE') {
            return (
                <div className="failure">
                    Failure
                </div>
            );
        } else if (status === 'SUCCESS') {
            return (
                <div className="successful">
                    Success
                </div>
            );
        }
    };

    const renderTransverStatus = (status) => {
        if (status === 'FULLY_SUCCESSFUL') {
            return (
                <div className="fully-successful" style={{width: '100px'}}>
                    Success
                </div>
            );
        } else if (status === 'PARTIALLY_SUCCESSFUL') {
            return (
                <div className="partial-successful" style={{width: '100px'}}>
                    Partial
                </div>
            );
        } else if (status === 'UNSUCCESSFUL') {
            return (
                <div className="unsuccessful" style={{width: '100px'}}>
                    Error
                </div>
            );
        }
    };

    const renderBody = (item) => {
        if (type === 'map') {
            return (
                <Box className="accordion-tr">
                    <Box className="accordion-th-big">
                        {item?.sku}
                    </Box>
                    <Box className="accordion-th-big">
                        {item?.productType}
                    </Box>
                    <Box style={{width: '30%'}} className="accordion-th-big">
                        <div className="del-edit-wrapper">
                            <button
                                onClick={(e) => handleOpenEdit(e, item)}
                            >
                                <img src={Images.Edit} alt={'icon'}/>
                                <p className="edit-text">Edit</p>
                            </button>
                            <button
                                onClick={(e) => handleOpenDel(e, item)}
                            >
                                <img src={Images.Remove} alt={'icon'}/>
                                <p className="delete-text">Delete</p>
                            </button>
                        </div>
                    </Box>
                </Box>
            );
        } else if (type === 'transfer') {
            return (
                <Box className="accordion-tr">
                    <Box style={{width: '17%'}} className="accordion-th">
                        <TextRow name={item?.transferId}/>
                    </Box>
                    <Box style={{width: '17%'}} className="accordion-th">
                        <DateRow date={item?.transferDate}/>
                    </Box>
                    <Box style={{width: '17%'}} className="accordion-th">
                        <TextRow name={item?.warehouseFrom}/>
                    </Box>
                    <Box style={{width: '17%'}} className="accordion-th">
                        <TextRow name={item?.warehouseTo}/>
                    </Box>
                    <Box style={{width: '17%'}} className="accordion-th">
                        <TextRow name={item?.qty}/>
                    </Box>
                    <Box style={{width: '12%'}} className="accordion-th">
                        {renderTransverStatus(item?.status)}
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box className="accordion-tr">
                    <Box style={{width: '21%'}} className="accordion-th">
                        <TextRow name={item?.orderId}/>
                    </Box>
                    <Box style={{width: '20%'}} className="accordion-th">
                        {item?.addedDate ? moment(item?.addedDate).format('DD/MM/YYYY LT') : ''}
                    </Box>
                    <Box style={{width: '21%'}} className="accordion-th">
                        <DateRow date={item?.createdAt}/>
                    </Box>
                    <Box style={{width: '20%'}} className="accordion-th">
                        {renderStatus(item?.status)}
                    </Box>
                    <Box style={{width: '12%'}} className="accordion-th">
                        {item?.status !== 'FULLY_SUCCESSFUL' &&
                            <div className="resync-sync-btn">
                                <button
                                    onClick={(e) => handleOpenEdit(e, item)}
                                >
                                    {loadActionBtn?.length && currentId === item?.id ?
                                        <MinLoader color="#6A6D84" position={'relative'}/>
                                        :
                                        <p>{
                                            item?.status === 'PARTIALLY_SUCCESSFUL' ?
                                                'Resync'
                                                :
                                                'Sync'
                                        }</p>
                                    }
                                </button>
                            </div>
                        }
                    </Box>
                </Box>
            );
        }
    };

    const renderItemDetailsHead = () => {
        if (type === 'map') {
            return (
                <div className="table-header">
                    <Box style={{width: '50%'}} className="table-th th-sku">Erply Product ID (Code)</Box>
                    <Box style={{width: '50%'}} className="table-th th-quantity">Multiplier</Box>
                </div>
            );
        } else if (type === 'transfer') {
            return (
                <div className="table-header">
                    <Box style={{width: '50%'}} className="table-th th-sku">Erply Product ID</Box>
                    <Box style={{width: '50%'}} className="table-th th-quantity">Quantity</Box>
                    <Box style={{width: '50%'}} className="table-th th-quantity">Status</Box>
                </div>
            );
        } else {
            return (
                <div className="table-header">
                    <Box style={{width: '33%'}} className="table-th th-sku">Shopify SKU</Box>
                    <Box style={{width: '33%'}} className="table-th th-quantity">Quantity</Box>
                    <Box style={{width: '33%'}} className="table-th th-quantity">Status</Box>
                    <Box style={{width: '33%'}} className="table-th th-quantity">Action</Box>
                </div>
            );
        }
    };

    const renderItemDetails = (info, item) => {
        if (type === 'map') {
            return (
                <Box className="body-list" sx={{marginBottom: '4px'}}>
                    <Box style={{width: '50%'}}
                         className="body-th th-sku">
                        {`${info?.erplyProductId ? info?.erplyProductId : 'Not Set'} ${info?.erplyCode ? `(${info?.erplyCode})` : ''}`}
                    </Box>
                    <Box style={{width: '50%'}} className="body-th th-quantity">{info?.multiplier}</Box>
                </Box>
            );
        } else if (type === 'transfer') {
            return (
                <Box className="body-list" sx={{marginBottom: '4px'}}>
                    <Box style={{width: '50%'}} className="body-th th-quantity">{info?.erplyCode}</Box>
                    <Box style={{width: '50%'}} className="body-th th-quantity">{info?.qty}</Box>
                    <Box style={{width: '50%'}} className="body-th th-quantity">{renderItemStatus(info?.status)}</Box>
                </Box>
            );
        } else {
            return (
                <Box className="body-list" sx={{marginBottom: '4px', cursor: 'pointer'}}
                     onClick={() => handleOpen({...info, ...item})}>
                    <Box style={{width: '33%'}}
                         className="body-th th-sku">{info?.shopifySKU ? info?.shopifySKU : 'Not Set'}</Box>
                    <Box style={{width: '33%'}} className="body-th th-quantity">{info?.qty ? info?.qty : 0}</Box>
                    <Box style={{width: '33%'}} className="body-th th-quantity">{renderItemStatus(info?.status)}</Box>
                    <Box style={{width: '33%'}} className="body-th th-quantity see-details">See Details</Box>
                </Box>
            );
        }
    };

    return (
        <div style={{width: '100%'}}>
            <div style={margin ? {margin: 0} : {}} className={classes.tableWrapper}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {head ? head?.map((i, j) => (
                                        <TableCell key={j}>
                                            <SearchAndFilter
                                                setSelectedFilter={() => {
                                                }}
                                                selectedName={selectedName}
                                                setSelectedName={setName}
                                                handleSearch={handleChange}
                                                name={i.name}
                                                width={i.width}
                                                itemKey={i.key}
                                                title={i.title}
                                                custom={i.custom}
                                                top={i.top}
                                                dateType={i?.type}
                                                notSearch={i.notSearch}
                                                smallSize={i?.smallSize}
                                                bigSize={i?.bigSize}
                                                iconType={i?.icon}
                                                nameRow={i?.nameRow}
                                            />
                                        </TableCell>
                                    ))
                                    : ''}
                            </TableRow>
                        </TableHead>
                    </Table>

                    {loader?.length ?
                        <Loader/>
                        :
                        <tbody>
                        <Box className="acordion-container"
                             style={{height: '70vh', overflow: 'auto'}}
                        >
                            {listInfo?.length ? (
                                listInfo?.map((item, j) => (
                                    <React.Fragment key={j}>
                                        <Accordion
                                            style={{borderBottom: '1px solid #CED8E5'}}
                                            key={j}
                                            sx={{boxShadow: 'none'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel-content"
                                                id="panel-header"
                                            >
                                                {renderBody(item)}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box className="accordion-body">
                                                    <div className="table">
                                                        {renderItemDetailsHead()}
                                                        <div className="table-body">
                                                            {type === 'map' ?
                                                                item?.erplyProducts?.length ? item?.erplyProducts?.map((info, index) => (
                                                                        <Fragment key={index}>
                                                                            {renderItemDetails(info, item)}
                                                                        </Fragment>
                                                                    )) :
                                                                    <TableNoInfo text={`No Items Yet`}
                                                                                 styles={{position: 'relative'}}/>
                                                                :
                                                                type === 'transfer' ?
                                                                    item?.items?.length ? item?.items?.map((info, index) => (
                                                                            <Fragment key={index}>
                                                                                {renderItemDetails(info, item)}
                                                                            </Fragment>
                                                                        )) :
                                                                        <TableNoInfo text={`No Items Yet`}
                                                                                     styles={{position: 'relative'}}/>
                                                                    :

                                                                    item?.items?.length ? item?.items?.map((info, index) => (
                                                                            <Fragment key={index}>
                                                                                {renderItemDetails(info, item)}
                                                                            </Fragment>
                                                                        )) :
                                                                        <TableNoInfo text={`No Items Yet`}
                                                                                     styles={{position: 'relative'}}/>
                                                            }
                                                        </div>
                                                    </div>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </React.Fragment>
                                ))
                            ) : (
                                <TableNoInfo text={`No Shopify Map Yet`}/>
                            )}
                        </Box>
                        </tbody>
                    }

                    {listCount > 0 ? (
                        <PaginationItem
                            listLength={list?.length}
                            page={page}
                            handleReturn={(number) => changePage(number)}
                            count={listCount}
                        />
                    ) : (
                        ''
                    )}
                </TableContainer>
            </div>
        </div>
    );
};
