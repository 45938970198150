import React, { useEffect } from 'react';
import { Settings, WareHouses } from './fragments';
import { erplyActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';

const ACTION_TYPE = 'GET_ERPLY';

export const Erplay = ({}) => {
    const dispatch = useDispatch();
    const { erplyInfo, erplyList } = useSelector((state) => ({
        erplyInfo: state.erply.erplyInfo,
        erplyList: state.erply.erplyList,
    }));

    useEffect(() => {
        dispatch(erplyActions.getErply());
        dispatch(erplyActions.getErplySetup());
        dispatch(erplyActions.getWarehouse());
    }, []);

    return (
        <>
            <div className="erplay-page">
                <p className="title-style">Credentials and Settings</p>
                <div>
                    <Settings info={erplyInfo} />
                </div>
            </div>
            <div className="warehouses">
                <WareHouses housesList={erplyList} loadType={ACTION_TYPE} />
            </div>
        </>
    );
};
