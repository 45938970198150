import React, {useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Images} from "assets/images";

export const Toast = ({ info, text, type }) => {

    const screen =
        <div className="toast-wrapper">
            <img src={type === 'error' ? Images.ToastError : Images.ToastSuccess } alt="success"/>
            <p>{text}</p>
       </div>

    useEffect(() => {
        if (info) {
            toast(screen);
        }
    }, [info]);
    return (
        <div>
            <ToastContainer
                className={type === 'error' ? 'error' : 'success'  }
                position={'bottom-right'}
            />
        </div>
    );
}