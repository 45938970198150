import React from 'react';
import { useDispatch } from 'react-redux';
import { AddInviteButton, Loader, TableNoInfo } from 'components';
import { Box, IconButton } from '@mui/material';
import { CustomDeleteModal } from 'fragments';
import { Images } from 'assets/images';
import { AddWareHouse } from './modal';
import { FindLoad } from 'hooks';
import { erplyActions } from 'store';
import { useModal } from 'utils';

export const WareHouses = ({ housesList, loadType }) => {
    const dispatch = useDispatch();
    const loader = FindLoad(loadType ? loadType : '');
    const { open } = useModal();

    const handleDelete = (item) => {
        dispatch(erplyActions.deleteWareHouse(item?.id));
    };

    if (loader?.length) return <Loader />;

    return (
        <div className='warehouse-container'>
            <div className='title-buttons-wrapper'>
                <div className='wrapper-title'>
                    <h2 className='warehouse-title'>Warehouses</h2>
                </div>
                <div className='buttons-wrapper'>
                    <AddInviteButton
                        text={'Add'}
                        handleClick={() => open( <AddWareHouse />)}
                    />
                </div>
            </div>
            <div className='wrapper-row'>
                <div className=''>
                    {housesList?.warehouses?.length ?
                        housesList?.warehouses.map((item, index) => (
                            <Box className='list-card' key={index}>
                                <div className='list-info'>
                                    <div className='list-id'>
                                        <span className='title'>ID:</span>
                                        <span className='subtitle'>{item?.warehouseId}</span>
                                    </div>
                                    <div className='list-name'>
                                        <span className='title'>Name:</span>
                                        <span className='subtitle'>{item?.name}</span>
                                    </div>
                                </div>
                                <div className='list-action'>
                                    <IconButton
                                        variant='outlined'
                                        onClick={() => open(<AddWareHouse werHouse={item} />)}
                                    >
                                        <img src={Images.Edit} alt={'icon'} />
                                    </IconButton>
                                    <IconButton
                                        variant='outlined'
                                        onClick={() => open(
                                            <CustomDeleteModal
                                                handleDelete={() => handleDelete(item)}
                                                actionType={'DELETE_WAREHOUSE'}
                                            />,
                                        )}
                                    >
                                        <img src={Images.Remove} alt={'icon'} />
                                    </IconButton>
                                </div>
                            </Box>
                        ))
                        :
                        <TableNoInfo text={`No Warehouses Yet`} styles={{ position: 'relative' }} />
                    }
                </div>
            </div>
        </div>
    );
};
