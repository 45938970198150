import {
  addAmazon,
  addAmazonProduct,
  deleteAmazonProduct,
  editAmazonProduct,
  getAmazon,
  getAmazonType,
  addAmazonType,
  editAmazonType,
  deleteAmazonType,
  getAmazonSetup,
  createAmazonSetup,
  changeAmazonSetup, getAmazonOrders, getAmazonTransvers, syncResync, orderProductMap,
} from './amazon.action';

export { amazonReducer } from './amazon.reducer';
export { watchAmazon } from './amazon.saga';

export const amazonActions = {
  /** Create, Edit Amazon Product */
  addAmazon,
  addAmazonProduct,
  editAmazonProduct,
  /** End */

  /** Get Amazon */
  getAmazon,
  /** End */

  /** Delete Amazon Product */
  deleteAmazonProduct,
  /** End */

  /**  Amazon Type */
  getAmazonType,
  addAmazonType,
  editAmazonType,
  deleteAmazonType,
  /** End */

  /** Amazon Setup */
  getAmazonSetup,
  createAmazonSetup,
  changeAmazonSetup,
  /** End */

  /** Amazon Orders */
  getAmazonOrders,
  getAmazonTransvers,
  syncResync,
  orderProductMap,
  /** End */
}
