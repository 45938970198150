import React from 'react';
import { tableStyle } from './styles';
import { Pagination } from '@mui/material';

export const PaginationItem = ({ count, handleReturn, page, listLength }) => {
    const classes = tableStyle();
    const limitCountNumber = 100;

    const handleChangePage = (val) => {
        handleReturn(val);
    };

    const firsCountNumber = page <= 1 ? 1 : (page - 1) * limitCountNumber + 1;
    const shownCountNumber = page <= 1 ? listLength : firsCountNumber - 1 + listLength;

    return (
        <div className={classes.PaginationWrapper}>
            <div>
                <p className={classes.showCountText}>
                    {`Showing ${firsCountNumber ? firsCountNumber : 0} to ${shownCountNumber ? shownCountNumber : 0} of ${
                        count ? count : 0
                    } entries`}
                </p>
            </div>

            <Pagination
                onChange={(event, val) => handleChangePage(val, 'vvv')}
                page={page}
                count={count && Math.ceil(count / limitCountNumber)}
                color={'primary'}
            />
        </div>
    );
};
