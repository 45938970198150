
export const ToastFail =(fail)=>{

    if(fail){
        return (
            fail === 'PATCH_AMAZON_SETUP' ? 'Something went wrong' :
                fail === 'POST_AMAZON_SETUP' ? 'Something went wrong' :
                    fail === 'CREATE_ERPLY' ? 'Something went wrong' :
                        fail === 'EDIT_ERPLY' ? 'Something went wrong' :

                false
        )
    }
}