// import { makeStyles } from "@material-ui/table/styles";
// import {Colors, Shadow} from "@eachbase/utils";

import {makeStyles} from "@mui/styles";

export const inputsStyle = makeStyles(() => ({
    inputTextField:{
        width:'100%',
        '& .MuiOutlinedInput-notchedOutline':{
            border:'none',
        },
        '& .MuiOutlinedInput-root':{
            height:'48px'
        },
        '& .MuiInputLabel-outlined':{
            marginTop:'-3px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)'
        },
        '& .MuiSelect-select.MuiSelect-select':{
            background:'none'
        },
    },
    SignInInput: {
        width: "100%",
        "& .MuiFormLabel-root": {
            fontSize: "16px",
            color: 'black',
        },

        "& .MuiInput-underline.Mui-error:after": {
            borderBottomColor: 'red',
        },
    },
  // dateFiled: {
  //   marginRight: '16px',
  //   background: 'white',
  //   width: '180px',
  //   '& .MuiOutlinedInput-notchedOutline': {
  //     borderColor: Colors.ThemeBorder
  //   },
  //   '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
  //     borderColor: Colors.ThemeBorder,
  //   },
  //   '& .MuiFormLabel-root.Mui-disabled': {
  //     color: `${Colors.ThemeBorder}!important`,
  //   },
  //   '& .MuiOutlinedInput-root': {
  //     height: '36px'
  //   },
  //   '& .MuiInputLabel-outlined': {
  //     marginTop: '-3px'
  //   },
  //   '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
  //     transform: 'translate(14px, -2px) scale(0.75)',
  //     color: Colors.ThemeBlue
  //   },
  // },
  //
  // smallDesk:{
  //   "@media (max-width: 1918px)": {
  //     display: 'block'
  //   },
  //   "@media (min-width: 1919px)": {
  //     display: 'none'
  //   },
  // },
  //
  // bigDesk:{
  //   "@media (max-width: 1918px)": {
  //    display: 'none'
  //   },
  //   "@media (min-width: 1919px)": {
  //     display: 'block'
  //   },
  // },
  //
  // search: {
  //   width: '92px',
  //   height: '36px',
  //   boxShadow: '0px 0px 6px #438AFE4D',
  //   borderRadius: '4px',
  //   background: Colors.ThemeBlue,
  //   color: Colors.BackgroundWhite,
  //   fontSize: '14px',
  //   border: 'none',
  //   marginRight: '40px',
  //   textTransform: 'capitalize',
  //   "&:hover": {
  //     background: Colors.ThemeBorderBlue,
  //   },
  // },
  //

  // searchfAddressDescriptionText:{
  //   fontSize:'16px',
  // },
  // suggestionActive:{
  //   background:'green',
  //   width:'200px',
  //   height:'auto'
  // },
  //
  // searchInputTitle: {
  //   display: "flex",
  //   "& p": {
  //     fontSize: "14px",
  //     lineHeight: "19px",
  //     fontWeight: "600",
  //     color: Colors.TextSecondary,
  //     marginRight: "4px",
  //   },
  //   "& img": {
  //     width: "18px",
  //     height: "18px",
  //     cursor: "pointer",
  //   },
  // },
  //
  // searchInput: {
  //   width: "100%",
  //
  //   "@media (max-width: 1918px)": {
  //     marginBottom: "8px",
  //   },
  //   "@media (min-width: 1919px)": {
  //     marginBottom: "16px",
  //   },
  //
  //   "& .MuiFormLabel-root": {
  //     fontSize: "16px",
  //     color: `${Colors.TextPrimary}`,
  //   },
  //
  //   "& .MuiInput-underline.Mui-error:after": {
  //     borderBottomColor: `${Colors.ThemeRed}`,
  //   },
  // },
  //
  // actionStyle: {
  //   width: "100%",
  //
  //   "@media (max-width: 1918px)": {
  //     marginBottom: "8px",
  //   },
  //   "@media (min-width: 1920px)": {
  //     marginBottom: "16px",
  //   },
  //   "& .MuiInput-underline:before": {
  //     borderBottom: "none",
  //   },
  // },
  //
  // PasswordInput: {
  //   "& .MuiInputBase-input::-webkit-input-placeholder": {
  //     fontSize: "16px",
  //     color: `${Colors.TextPrimary}`,
  //     opacity: 1,
  //   },
  // },
  //
  //
  //
  // inputTextFieldBlue:{
  //   width:'100%',
  //   '& .MuiOutlinedInput-notchedOutline':{
  //     borderColor:Colors.ThemeBlue
  //   },
  //   '& .MuiOutlinedInput-root':{
  //     height:'48px'
  //   },
  //   '& .MuiInputLabel-outlined':{
  //     marginTop:'-3px'
  //   },
  //   '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
  //     transform: 'translate(14px, -2px) scale(0.75)',
  //     color:Colors.ThemeBlue
  //   },
  // },
  // inputTextFieldAutoHeight:{
  //     '& .MuiOutlinedInput-notchedOutline':{
  //         borderColor:Colors.ThemeBorder
  //     },
  //   '& .MuiOutlinedInput-root':{
  //
  //   },
  //   '& .MuiInputLabel-outlined':{
  //
  //   },
  //   '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
  //     transform: 'translate(14px, -2px) scale(0.75)'
  //   }
  // },
  //
  // searchAddress:{
  //   height:'48px',
  //   border: '1px solid #51566DB3',
  //   borderRadius: '4px',
  //   padding: '18.5px 14px',
  //   fontSize:'16px',
  //   width:'100%',
  //   '&:disabled':{
  //     background:'none',
  //     borderColor:'rgba(0, 0, 0, 0.26)',
  //     color:'rgba(0, 0, 0, 0.26)',
  //   }
  // },
  // searchAddressFill:{
  //   height:'48px',
  //   border: `1px solid ${Colors.ThemeBlue}`,
  //   borderRadius: '4px',
  //   padding: '18.5px 14px',
  //   fontSize:'16px',
  //   width:'100%',
  //   '&:disabled':{
  //     background:'none',
  //     borderColor:Colors.ThemeWhiteBlue,
  //     color:Colors.ThemeWhiteBlue,
  //   }
  // },
  // searchAddressError:{
  //   height:'48px',
  //   border: `1px solid ${Colors.ThemeError}`,
  //   borderRadius: '4px',
  //   padding: '18.5px 14px',
  //   fontSize:'16px',
  //   width:'100%',
  //   '&::placeholder':{
  //     color: Colors.ThemeError,
  //   },
  //
  // },
  // errorText:{
  //   color: Colors.ThemeError,
  //   fontSize: '14px',
  //   fontWeight: 600,
  //   margin:'3px 0',
  //   position:'absolute',
  // },
  // selectErrorText:{
  //   color: Colors.ThemeError,
  //   fontSize: '14px',
  //   fontWeight: 600,
  //   margin:'3px 0',
  //   position:'absolute',
  //   marginTop:'49px'
  // },
  //
  // inputShrink:{
  //   background:'white',
  //   padding:'0 6px'
  // },
  //
  // valuesContainer:{
  //   backgroundColor: "white",
  //   boxShadow: Shadow.modalShadow,
  //   height:'auto',
  //   maxHeight:'300px',
  //   overflow:'auto',
  //   position:'absolute',
  //   zIndex:'10',
  //   width:'292px',
  // },
  //
  // blueBorder:{
  //   borderColor:'blue'
  // },
  //
  // itemButton:{
  //   border:'none',
  //   background:'none',
  //   outline:'none',
  //   marginTop:'-1px',
  // },
  //
  // filterIcon:{
  //   width:'12px !important',
  //   height:'8px !important',
  // },
  //
  // pickInputs:{
  //   '& div':{
  //     marginBottom:'6px',
  //     marginTop:'0'
  //   },
  // },
  //
  // inputs:{
  //   display:'flex',
  //   width:'100%',
  //
  // }
}));


