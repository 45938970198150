export const Colors = {
    white: '#FFFFFF',
    main: '#BA43B1',
    darkMain: '#901F89',
    text: '#2A374E',
    lightText: '#9698A8',
    placeHolder: '#6A6D84',
    borderColor: '#51566DB3',
    borderRedColor: '#D72525',
    lightOrange: '#FAF1DC',
    orange: '#FFA710',
    lightGreen: '#B8EDE0',
    seccondaryGround: '#F7F9FC',
    green: '#018D6F',
    secondaryBlue: '#ECF1FA',
}