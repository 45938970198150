import React, { useState, Fragment, useContext } from 'react';
import { createContext } from 'react';
import { SimpleModal } from '../components';
import _ from 'lodash'


export const ModalContext = createContext();


export const ModalProvider = ({ children }) => {
    const [params, setParams] = useState({});
    const [modalContent, removeContent] = useState({})

    return (
        <Fragment>
            <ModalContext.Provider
                value={{
                    params,
                    setParams,
                    modalContent,
                    removeContent
                }}>

                {children}
            </ModalContext.Provider>
        </Fragment>
    );
};

export const useModal = () => {
    const {  modalContent, removeContent, params, setParams } = useContext(ModalContext);

    return {
        open: (modalContent, params = {}) => {
            removeContent(modalContent);
            setParams(params)
        },

        close: () => {
            setParams({});
            removeContent({})
        },
        modalContent,
        params,
    };
};


export const AllModals = () => {
    const { close, modalContent } = useModal();

    return (
        <SimpleModal
            handleOpenClose={() => close()}
            openDefault={!_.isEmpty(modalContent)}
            content={
                <div>
                    {modalContent}
                </div>
            }
        />
    );
};