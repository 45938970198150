import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./users.service";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {
  CREATE_USER, EDIT_USER,
  GET_USERS, GET_USERS_SUCCESS,
  GET_USERS_SUCCESS_BY_ID,
  GET_USERS_SUCCESS_BY_ID_SUCCESS, INVITE_USER
} from "./users.types";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";

/** Create, Edit User */
function* createUser({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.createUserService, payload.body );
    // window.location.replace('/humanResources')
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editUser({payload,type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  try {
    const res = yield call( authService.editUserService, payload.id, payload.body );
    yield put(httpRequestsOnSuccessActions.appendSuccess(type))
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  }
}
/** End */

/** Get Users */
function* getUsers({payload, type}) {
  if(payload !== 'noLoad'){
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call( authService.getUsersService, payload.params );
    yield put({
      type: GET_USERS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getUserById({payload,type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.getUserByIdService, payload.id );
    yield put({
      type: GET_USERS_SUCCESS_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
/** End */

/** Invite User */
function* inviteUser({payload,type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  try {
    const res = yield call( authService.inviteUserService, payload.info );
    yield put(httpRequestsOnSuccessActions.appendSuccess(type))
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  }
}
/** End */


export const watchUser = function* watchUserSaga() {
  /** Create, Edit User */
  yield takeLatest( CREATE_USER, createUser );
  yield takeLatest( EDIT_USER, editUser );
  /** End */

  /** Get Users */
  yield takeLatest( GET_USERS, getUsers );
  yield takeLatest( GET_USERS_SUCCESS_BY_ID, getUserById );
  /** End */

  /** Invite User */
  yield takeLatest( INVITE_USER, inviteUser );
  /** End */
};
