import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FindError, FindSuccess} from "hooks";
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, shopifyActions} from "store";
import {AddModalButton, CloseButton, ErrMessage, SettingsButton} from "components";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {Box, FormControlLabel, Switch, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {AUTHENTICATION_TEXT_FIELD_STYLES_SMALL} from "../../../fragments/modals/constants";


export const RightDrawer = ({statusType}) => {
    const {setUp} = useSelector((state) => ({
        setUp: state.shopify.setUp,
    }));

    const dispatch = useDispatch();
    const backError = FindError(statusType);
    const [state, setState] = useState({right: false});
    const [checkedSwitch, setCheckedSwitch] = useState(false);


    const actionType = setUp ? 'PATCH_SHOPIFY_SETUP' : 'POST_SHOPIFY_SETUP'


    const success = FindSuccess(actionType);
    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: {errors},
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            accessToken: setUp?.accessToken || '',
            apiKey: setUp?.apiKey || '',
            secretKey: setUp?.secretKey || '',
            shopUrl: setUp?.shopUrl || '',
            locationId: setUp?.locationId || '',
        },
    });


    useEffect(() => {
        if (success?.type === actionType) {
            setState({right: false});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
        }
    }, [success]);

    useEffect(() => {
        dispatch(shopifyActions.getShopifySetup());
    }, []);


    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };


    useEffect(() => {
        // if (setUp?.cronEnabled) {
        //     setCheckedSwitch(setUp?.cronEnabled);
        // }
        if (setUp) {
            reset({
                accessToken: setUp?.accessToken || '',
                apiKey: setUp?.apiKey || '',
                secretKey: setUp?.secretKey || '',
                shopUrl: setUp?.shopUrl || '',
                locationId: setUp?.locationId || '',
            });

        }
    }, [setUp]);

    const handleChange = () => {
        // setCheckedSwitch(!checkedSwitch);
    };

    const onSubmit = (data) => {
        if (setUp) {
            const info = {
                ...data,
                // cronEnabled: checkedSwitch
            };

            dispatch(shopifyActions.changeShopifySetup(info));
        } else {
            dispatch(shopifyActions.createShopifySetup(data));
        }
    };
    const handleKeyDown = () => {
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };

    const list = () => (
        <div className="list-wrapper">
            <div className="list-wrapper-head ">
                <CloseButton handleClose={toggleDrawer('right', false)}/>
            </div>
            <div className="list-wrapper-body">
                <p className="list-title ">Authentication Credentials</p>
                <form onSubmit={handleSubmit(onSubmit)} className='credentials-form'>
                    {/*{setUp && (*/}
                    {/*    <div className='credentials-swicher'>*/}
                    {/*        <FormControlLabel*/}
                    {/*            value='cronEnabled'*/}
                    {/*            name='cronEnabled'*/}
                    {/*            control={*/}
                    {/*                <Switch*/}
                    {/*                    checked={checkedSwitch}*/}
                    {/*                    onChange={handleChange}*/}
                    {/*                    inputProps={{'aria-label': 'controlled'}}*/}
                    {/*                    className='switch-colors'*/}
                    {/*                    color={'secondary'}*/}
                    {/*                />*/}
                    {/*            }*/}
                    {/*            label='Enable Cron'*/}
                    {/*            labelPlacement='start'*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <Controller
                        name="accessToken"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <TextField
                                {...register('accessToken', {require: true})}
                                required={true}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                value={value}
                                label="Access Token"
                                size='medium'
                                sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                            />
                        )}
                    />
                    <Controller
                        name="apiKey"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <TextField
                                {...register('apiKey', {require: true})}
                                required={true}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                value={value}
                                label="API Key"
                                size='medium'
                                sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                            />
                        )}
                    />
                    <Controller
                        name="secretKey"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <TextField
                                {...register('secretKey', {require: true})}
                                required={true}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                value={value}
                                label="Secret Key"
                                size='medium'
                                sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                            />
                        )}
                    />
                    <Controller
                        name="shopUrl"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <TextField
                                {...register('shopUrl', {require: true})}
                                required={true}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                value={value}
                                label="Shop Url"
                                size='medium'
                                sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                            />
                        )}
                    />
                    <Controller
                        name="locationId"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <TextField
                                {...register('locationId')}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                value={value}
                                label="Location ID"
                                size='medium'
                                sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                            />
                        )}
                    />
                    <Box>{backError?.error && <ErrMessage text={backError.error}/>}</Box>
                    <div className='list-buttons-wrapper'>
                        <button
                            className='chancel-button'
                            onClick={toggleDrawer('right', false)}
                            type={'button'}>
                            Cancel
                        </button>
                        <AddModalButton
                            text='Save'
                            type='submit'
                            load={setUp ? 'PATCH_SHOPIFY_SETUP' : 'POST_SHOPIFY_SETUP'}/>
                    </div>
                </form>

            </div>
        </div>
    );


    return (
        <Fragment key={'right'}>
            <SettingsButton text={'Settings'} handleClick={toggleDrawer('right', true)}/>
            <SwipeableDrawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}>
                {list()}
            </SwipeableDrawer>
        </Fragment>
    );
}
