import {Images} from "assets/images";

export const SettingsButton = ({text, handleClick}) =>{
    return(
        <button className='settings-button' onClick={handleClick}>
            <img src={Images.Settings} alt='icon'/>
            <p className='settings-button-text'>
                {text}
            </p>
        </button>
    )
}