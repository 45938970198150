import { GET_ERPLY_SETUP_SUCCESS, GET_ERPLY_SUCCESS, GET_WAREHOUSE_SUCCESS } from './erply.types';

const initialState = {
    erplyInfo: null,
    erplyList: null,
    warehouseList: null,
};

export const erplyReducer = (state = initialState, action) => {
    switch (action.type) {
        /** Erply */

        case GET_ERPLY_SUCCESS:
            return {
                ...state,
                erplyInfo: action.payload,
            };

        case GET_ERPLY_SETUP_SUCCESS:
            return {
                ...state,

                erplyList: action.payload,
            };

        /** End */

        /** Warehouse  */

        case GET_WAREHOUSE_SUCCESS:
            return {
                ...state,
                warehouseList: action.payload,
            };

        /** End */

        default:
            return state;
    }
};
