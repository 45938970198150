import { fork } from 'redux-saga/effects';
import { watchAuth } from '../auth';
import { watchUser } from '../users';
import { watchErply } from '../erply';
import { watchAmazon } from '../amazon';
import { watchEbay } from '../ebay';
import { watchWalmart } from '../walmart';
import { watchCarecraft } from 'store/carecraft';
import {watchShopify} from "../shopify";

/** Combined Sagas */
export const appSaga = function* startForman() {
    yield fork(watchAuth);

    yield fork(watchUser);
    yield fork(watchErply);
    yield fork(watchAmazon);
    yield fork(watchShopify);
    yield fork(watchEbay);
    yield fork(watchWalmart);
    yield fork(watchCarecraft);
};
