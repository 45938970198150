import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myProfileFragment } from './style';
import { PasswordValidator } from 'utils';
import { Images } from 'assets/images';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { PasswordInput } from 'components';
import { authActions } from 'store';
import { FindSuccess } from 'hooks';

export const ChangePassword = ({ toggleChange, showFormChange }) => {
    // Hooks.
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState(false);
    const classes = myProfileFragment();
    const successRes = FindSuccess('CHANGE_PASSWORD_REQUEST');

    const { httpOnSuccess, httpOnLoad } = useSelector((state) => ({
        httpOnSuccess: state.httpOnSuccess,
        httpOnLoad: state.httpOnLoad,
    }));

    const successType = httpOnSuccess?.length && httpOnSuccess[0].type === 'CHANGE_PASSWORD_REQUEST';

    const handleChange = (e) =>
        setInputs(
            (prevState) => ({ ...prevState, [e.target.name]: e.target.value }),
            error === e.target.name && setError(''),
            error === 'confirmationNotEqual' && setError('')
        );

    const handleChangePassword = () => {
        const data = {
            password: inputs.password,
            newPassword: inputs.newPassword,
            confirmation: inputs.confirmation,
        };
        if (inputs.password && inputs.newPassword && inputs.confirmation) {
            if (inputs.newPassword === inputs.confirmation) {
                dispatch(authActions.changePassword(data));
            } else {
                setError('confirmationNotEqual');
            }
        }
        // else {
        //     !inputs.password ? setError('password') :
        //         !inputs.newPassword ? setError('newPassword') :
        //             !inputs.confirmation ? setError('confirmation') : ''
        // }
    };

    const handleCheck = (bool, name) => {
        if (bool === true) {
            setError(name);
        } else {
            setError('');
        }
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            courrentPassword: '',
            newPassword: '',
            retypePassword: '',
        },
    });

    const onChangePasswrord = () => {
        dispatch(authActions.changePassword(inputs));
        showFormChange(!toggleChange);
    };

    const [values, setValues] = useState({
        showPassword: false,
    });

    const handleChangeValue = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={classes.changePasswordWrapper}>
            <div className={classes.changeBox}>
                <Box className={classes.flexBox}>
                    <h5>Change Password</h5>
                    {toggleChange ? (
                        <div className={classes.fromBtn}>
                            <button className={classes.SaveBtn} onClick={onChangePasswrord}>
                                <span className="save-btn">Save</span>
                            </button>
                            <button onClick={showFormChange} className={classes.CancelBtn}>
                                <span className="cancel-btn">Cancel</span>
                            </button>
                        </div>
                    ) : (
                        <button className={classes.Btn} onClick={showFormChange}>
                            <img src={Images.Edit} alt={'icon'} />
                            <p className="edit-text">Edit</p>
                        </button>
                    )}
                </Box>
                <Box>
                    <p className={classes.changeText}>Use strong password to keep your account secure.</p>
                </Box>
                {toggleChange && (
                    <Box>
                        <div className={classes.spaceTop}>
                            <PasswordInput
                                handleChangePassword={handleChange}
                                type={'password'}
                                name={'password'}
                                disabled={false}
                                variant={'accountPassword'}
                                value={inputs.password}
                                onChange={handleChange}
                                placeholder={'Current Password*'}
                            />
                            <PasswordInput
                                validator={PasswordValidator}
                                sendBoolean={(bool) => handleCheck(bool, 'newPasswordNotValid')}
                                handleChangePassword={handleChange}
                                type={'password'}
                                name={'newPassword'}
                                disabled={false}
                                variant={'accountPassword'}
                                value={inputs.newPassword}
                                onChange={handleChange}
                                placeholder={'New Password*'}
                            />
                            <PasswordInput
                                validator={PasswordValidator}
                                sendBoolean={(bool) => handleCheck(bool, 'confirmationNotValid')}
                                handleChangePassword={handleChange}
                                type={'password'}
                                name={'confirmation'}
                                disabled={false}
                                variant={'accountPassword'}
                                value={inputs.confirmation}
                                onChange={handleChange}
                                placeholder={'Retype New Password*'}
                            />
                        </div>
                    </Box>
                )}
            </div>
        </div>
    );
};
