import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './erply.service';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import {
    CREATE_ERPLY,
    EDIT_ERPLY,
    GET_ERPLY,
    GET_ERPLY_SUCCESS,
    GET_ERPLY_SETUP,
    GET_ERPLY_SETUP_SUCCESS,
    CREATE_WAREHOUSE,
    EDIT_WAREHOUSE,
    DELETE_WAREHOUSE,
    GET_WAREHOUSE,
    GET_WAREHOUSE_SUCCESS,
} from './erply.types';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';

/** Create, Edit Erply */

function* createErply({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        const res = yield call(authService.createErplyService, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editErply({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.editErplyService, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getErply({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getErplyService, payload);
        yield put({
            type: GET_ERPLY_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getErplySetup({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getErplySetup, payload);
        yield put({
            type: GET_ERPLY_SETUP_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

/** Warehouse */

function* createWareHouse({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(authService.createWareHouse, payload.body);
        yield put({
            type: GET_ERPLY_SETUP,
        });
        yield put({
            type: GET_WAREHOUSE,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editWareHouse({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(authService.editWareHouse, payload.body, payload?.warehouseId);
        yield put({
            type: GET_ERPLY_SETUP,
        });
        yield put({
            type: GET_WAREHOUSE,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteWareHouse({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        const res = yield call(authService.deleteWareHouse, payload.warehouseId);
        yield put({
            type: GET_ERPLY_SETUP,
        });
        yield put({
            type: GET_WAREHOUSE,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getWareHouse({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        const res = yield call(authService.getWareHouseService, payload);
        yield put({
            type: GET_WAREHOUSE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

export const watchErply = function* watchErplySaga() {
    /** Get, Create, Edit Erply */
    yield takeLatest(CREATE_ERPLY, createErply);
    yield takeLatest(EDIT_ERPLY, editErply);
    yield takeLatest(GET_ERPLY, getErply);
    yield takeLatest(GET_ERPLY_SETUP, getErplySetup);
    /** End */

    /**  WareHouse  */
    yield takeLatest(CREATE_WAREHOUSE, createWareHouse);
    yield takeLatest(EDIT_WAREHOUSE, editWareHouse);
    yield takeLatest(DELETE_WAREHOUSE, deleteWareHouse);
    yield takeLatest(GET_WAREHOUSE, getWareHouse);
    /** End */
};
