import {makeStyles} from "@mui/styles";
import {Colors} from "utils";

export const errMessageStyle = makeStyles(() => ({
  nameEllipsis:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'80px',
    // textTransform:'uppercase',
    "@media (min-width: 1919px)": {
      width:'110px',
    },
  },

  nameEllipsisBig:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'130px',
    "@media (min-width: 1919px)": {
      width:'170px',
    },
  },

  tableNameEllipsis:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'100px',
    textTransform:'uppercase',
    color: `${Colors.TextSecondary}!important`,
    fontWeight:'bold',
    marginLeft:'4px',
    fontSize:'16px',
    "@media (min-width: 1919px)": {
      width:'120px',
    },
  },

  addressEllipsis:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'200px',
    "@media (min-width: 1919px)": {
      width:'250px',
    },
  } ,
  desc:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'200px',
    "@media (min-width: 1919px)": {
      width:'250px',
    },
  } ,
  id:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'50px',
    "@media (min-width: 1919px)": {
      width:'50px',
    },
  } ,

  emailEllipsis:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'150px',
    "@media (min-width: 1919px)": {
      width:'200px',
    },
  },



  errMessageCenterPosition: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "10px",
  },

  errMessageLeftPosition: {
    display: "flex",
    width: "100%",
    margin: '10px 0 15px 0',
  },

  errMessageStyleText: {
    fontSize: "14px",
    fontWeight: "600",
    color: Colors.ThemeError,
    position:'absolute',
    marginTop:'-5px',
  },

  DoneMessage: {
    width: "420px",
    height: "62px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 12px #0052E01F",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    padding: "20px 16px",
    position: "fixed",
    bottom: "50px",
    right: "40%",
    left: "40%",

    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#252E48",
    },
  },

  notYetText:{
    fontSize: "16px",
    lineHeight: "24px",
    color:Colors.TextLight,
    fontWeight:'bold'
  },

  notYetCenter:{
    width:'100%',
    display:'flex',
    margin:'16px',
    "@media (min-width: 1918px)": {
      margin:'24px',
    }
  },
  notYetScreenCenter:{
    width:'100%',
    display:'flex',
    margin:'16px',
    justifyContent:'center',
    alignItems:'center',
    "@media (min-width: 1918px)": {
      margin:'24px',
    }
  },

  toastWorn:{
    width: '500px',
    height: '78px',
    borderRadius: '8px',
    padding:'16px',
    // position:'absolute',
    // right:'30px',
    display:'flex',
    '& img':{
      width: '30px',
      height: '30px',
      marginRight:'16px'
    },
    '& p':{
      fontSize:'16px',
      color:Colors.TextPrimary,
      lineHeight:'24px',
    }
  }
}));
