import React, { useState } from "react";
import {TextField} from "@mui/material";
import { ErrMessage } from "../messages";
import {MinLoader} from '../loaders';
import {inputsStyle} from "./styles";

export const ValidationInput = ({
  multiline, style, className, autoComplete,
  typeError, Length, disabled,
  value, type, onChange, name,
  label, validator, sendBoolean, variant, loader,handleSendAddress,handleDown, margin,
}) => {
  const [validEmail, setValidEmail] = useState(false);
  const classes = inputsStyle()

  const chechValid = (e) => {
    let Value = e.target.value;
    if (Value.length >= 1) {
      if (validator) {
        if (validator.test(Value)) {
          setValidEmail(false);
          sendBoolean(false);

        } else {
          setValidEmail(true);
          sendBoolean(true);
        }
      }
    }
  };

  return (
    <>
      <div
      className={'input-wrapper'}
          // className={style ? style : classes.SignInInput}
      >
        <TextField

          // className={className ? className :  classes.inputTextField}
          className={className ? className :  'input-text-field'}
          variant={variant}
          label={label}
          name={name}
          type={type}
          value={value ? value : ''}
          InputLabelProps={{
            shrink: type === 'date' ? true : type === 'zero' ? true : !!value,
          }}
          inputProps={{ maxLength: Length }}
          id="standard-basic"
          autoComplete={autoComplete}
          error={!!typeError}
          disabled={disabled === true}
          maxLength={Length}
          onChange={(ev) => onChange(ev)}
          onFocus={() => setValidEmail(false)}
          onKeyDown={handleDown}
          onBlur={handleSendAddress ? (e) => handleSendAddress(e.target.value) :
              (e) => chechValid(e)
          }
          fullWidth
          multiline={multiline}

          InputProps={{
            endAdornment: (
              loader && <MinLoader/>
            )
          }}
        />
        <ErrMessage
            text={typeError}
            style={margin ? { margin } : {}}
        />
      </div>
    </>
  );
};
