import {
    GET_CARECRAFT_SUCCESS,
    GET_CARECRAFT_SETUP_SUCCESS,
    SENT_CARECRAFT_SUCCESS, GET_CARECRAFT_MAP_SUCCESS, EDIT_CARECRAFT_MAP_SUCCESS,
} from './carecraft.type';

const initialState = {
    carecraft: null,
    carecraftSetup: null,
    carecraftPO: null,
    carecraftMap: null,
};

const renderNewList = (list, payload) => {
    return list.map((i) => i?.id === payload?.id ? payload : i);
};


export const carecraftReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CARECRAFT_SUCCESS:
            return {
                ...state,
                carecraft: action.payload,
            };

        case GET_CARECRAFT_SETUP_SUCCESS:
            return {
                ...state,
                carecraftSetup: action.payload,
            };

        case SENT_CARECRAFT_SUCCESS:
            return {
                ...state,
                carecraftPO: renderNewList(state.carecraftPO, action.payload),
            };

        case GET_CARECRAFT_MAP_SUCCESS:
            return {
                ...state,
                carecraftMap: action.payload,
                // carecraftMap: renderNewList(state.carecraftPO, action.payload),
            };

        case EDIT_CARECRAFT_MAP_SUCCESS:
            return {
                ...state,
                carecraftMap: renderNewList(state.carecraftMap, action.payload),
            };
        default:
            return state;
    }
};
