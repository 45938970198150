import { Backgrounds } from "utils/globalColors";
import { Colors } from "utils";
import { makeStyles } from "@mui/styles";

export const myProfileFragment = makeStyles(() => ({

    wrapper: {
        width: '379px',
        height: 'auto',
        overflow: 'auto',
        position: 'absolute',
        right: '20px',
        top: '73px',
        padding: '16px',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',

        "@media (min-width: 1920px)": {
            width: '395px',
            padding: '24px',
            right: '40px',
        },
    },

    avatar: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

        '& p': {
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '16px',
            "@media (min-width: 1920px)": {
                marginTop: '24px'
            },
        },
    },

    avatarImg: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
    },

    userInfo: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '8px',
        marginBottom: '16px',
    },

    title: {
        fontSize: '18px',
        fontWeight: '700',
        color: Colors.text,
    },

    userEmail: {
        marginTop: '16px',
        display: 'flex',
        padding: '16px',
        border: '1px solid #ECF1FA',
        width: '100%',
        borderRadius: '8px',

    },

    emailTitle: {
        fontSize: '14px',
        fontWeight: '400',
        color: Colors.lightText,
        marginLeft: '8px',
    },


    changeBox: {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        border: '1px solid #ECF1FA',
        width: '100%',
        borderRadius: '8px',
        '& form-button': {
            display: 'flex'
        }
    },

    formBtn: {
        display: 'flex',
    },

    circleIcon: {
        fontSize: 70,
        color: Colors.secondaryBlue,
    },

    SaveBtn: {
        color: Colors.main,
        background: 'transparent',
        border: 'none',
        fontSize: '14px',
        fontWeight: '700',
    },

    Photo: {
        background: Colors.seccondaryGround,
        borderRadius: '260px',
        padding: '1px',
        position: 'relative',
        bottom: '25px',
        border: 'none',
        display: 'flex',
        left: '25px',
        width: '28px',
        height: '28px',
    },

    CancelBtn: {
        color: Colors.lightText,
        background: 'transparent',
        border: 'none',
        fontSize: '14px',
        fontWeight: '700',
    },

    changeText: {
        fontSize: '14px',
        fontWeight: '400',
        color: Colors.lightText,
    },

    infoSections: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '10px',
        '& p': {
            marginLeft: '8px',
            fontSize: '14px',
            lineHeight: '19px',
        },
    },

    Btn: {
        background: 'none',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        cursor: 'pointer',
        '& p': {
            marginLeft: '8px',
            fontSize: '14px',

        },
        '& .edit-text': {
            color: Colors.darkMain,
        },
        '& :hover': {
            background: 'transparent',
        }
    },

    changePasswordWrapper: {
        marginTop: '16px',
        marginBottom: '16px'
    },

    changePasswordContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',

            '& p': {
                fontSize: '14px',
                fontWeight: 600,
                marginLeft: '8px',
            }
        }

    },

    changePasswordTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },

    changePasswordTextInfo: {
        marginTop: '17px',
        fontSize: '14px',
        lineHeight: '21px',
    },

    signOutButton: {
        height: '36px',
        borderRadius: '8px',
        width: '100%',
        marginTop: '16px',
        '& p': {
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: '600',
            marginLeft: '8px',
        },
    },

    saveButton: {
        fontSize: '14px',
        fontWeight: '600',
        background: 'none',
        border: 'none',
        outline: 'none',
        padding: '6px 0',
    },

    cancelButton: {
        fontSize: '14px',
        fontWeight: '600',
        background: 'none',
        border: 'none',
        outline: 'none',
        padding: '6px 0',
    },

    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '16px',
    },

    spaceTop: {
        marginTop: '16px',
    }

}))
