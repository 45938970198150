import React, {useState} from "react";
import {MyProfile} from "../../myProfile";
import {Images} from "assets/images";
import {SimpleModal} from "components/modals";

export const UserInfo = ({}) => {
    const [open, setOpen] = useState(false)
    const userInfo = localStorage.getItem('userType') &&
        typeof JSON.parse(localStorage.getItem('userType')) === 'object' &&
        JSON.parse(localStorage.getItem('userType'));

    const handleOpenClose = () => {
        setOpen(!open)
    }

    return (
        <div className='user-info'>
            <div onClick={handleOpenClose} className='user-info-wrapper'>
                <img src={Images.UserProfile} alt="Avatar"/>
                <p style={{marginLeft:'16px'}}>{userInfo && `${userInfo?.firstName} ${userInfo?.lastName ? userInfo?.lastName : ''}`}</p>
            </div>

            <div className='my-profile'>
                <SimpleModal
                    handleOpenClose={handleOpenClose}
                    backdropCustom={true}
                    openDefault={open}
                    disableScrollLock={true}
                    content={
                        <MyProfile
                            info={userInfo}
                        />
                    }
                />
            </div>
        </div>
    );
};
