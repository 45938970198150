
/**Create Users */
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

/**Edit User */
export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'

/**Get Users */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

/**Get User by id */
export const GET_USERS_SUCCESS_BY_ID = 'GET_USERS_SUCCESS_BY_ID';
export const GET_USERS_SUCCESS_BY_ID_SUCCESS = 'GET_USERS_SUCCESS_BY_ID_SUCCESS';

/**Invite User*/
export const INVITE_USER = 'INVITE_USER'