import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Images } from 'assets/images';

export const ListCard = ({ info, openEditType, openDeleteType }) => {
    return (
        <>
            <Box className='list-card'>
                <div>
                    <Typography variant='body2'>
                        {info?.name}
                    </Typography>
                </div>
                <div className='list-action'>
                    <IconButton variant='outlined' onClick={openEditType}>
                        <img src={Images.Edit} alt={'icon'} />
                    </IconButton>
                    <IconButton variant='outlined' onClick={openDeleteType}>
                        <img src={Images.Remove} alt={'icon'} />
                    </IconButton>
                </div>
            </Box>
        </>
    );
};