import React from "react";
import {Button} from "@mui/material";
import {MinLoader} from "../loaders/miniLoader";

export const SignIn = ({ text, width, handleClick, loader,margin }) => {
  return (
    <Button
      onClick={handleClick}
      style={{
        width: `${width}`,
        background: '#BA43B1',
        height: "48px",
        borderRadius: "8px",
        color: 'white',
        fontSize: "16px",
        lineHeight: "22px",
        fontWeight: "600",
        textTransform: "capitalize",
        margin:margin,
      }}
    >
      {loader === true ? <MinLoader color='white'/> : text}
    </Button>
  );
};
