import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {AddInviteButton, FullTable} from 'components';
import {InviteUser} from 'fragments';
import {userActions} from 'store';
import {ACTION_TYPE, usersBody, usersHead} from './constants';
import {useModal} from 'utils';

export const Users = () => {
    const {usersList} = useSelector((state) => ({
        usersList: state.users.usersList,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const {open} = useModal()

    useEffect(() => {
        if (info) {
            const sendInfo = {
                ...info,
            };
            delete sendInfo.page;
            dispatch(userActions.getUsers({...info}));
        }
    }, [info]);

    const handleEdit = (it) => {
        open(<InviteUser info={it}/>)
    };

    return (
        <>
            <div className="add-button-wrapper">
                <AddInviteButton
                    text={'Invite User'}
                    handleClick={() => open(<InviteUser info={null}/>)}
                />
            </div>
            <FullTable
                head={usersHead}
                body={usersBody}
                loadingType={ACTION_TYPE}
                list={usersList?.users}
                listCount={usersList?.count}
                noText={'User'}
                handleClick={handleEdit}
            />
        </>
    );
};
