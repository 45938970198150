import React, {useEffect, useState} from 'react';
import {useDebounce} from 'use-debounce';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AddInviteButton} from 'components';
import {LeftCredentials, AccordCraft, AddPoNumber, AddProductMap} from './fragments';
import {carecraftActions} from 'store';
import {
    ACTION_TYPE,
    carecraftBody,
    carecraftHead,
    carecraftMapBody,
    carecraftMapHead,
    MAP_ACTION_TYPE,
} from './fragments/constants';
import {Box, Tab, Tabs} from '@mui/material';
import {buttonsStyle} from '../amazon/fragments/styles';
import {SavePage, useModal} from 'utils';
import {CustomDeleteModal} from '../../fragments';
import {CarecraftMap} from './fragments/carecraftMap';
import {httpRequestsOnLoadActions} from '../../store/http_requests_on_load';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Carecraft = ({}) => {
    const {carecraft, carecraftSetup, carecraftMap} = useSelector((state) => ({
        carecraft: state.carecraft?.carecraft,
        carecraftSetup: state.carecraft?.carecraftSetup,
        carecraftMap: state.carecraft?.carecraftMap,
    }));
    const classes = buttonsStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const {open} = useModal();
    const [search, setSearch] = useState(null);
    const [searchValue] = useDebounce(search, 500);

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;

        if (searchValue?.value) {
            filteredInfo[searchValue?.searchKey] = searchValue?.value;
        }
        if (!searchValue?.value) {
            dispatch(httpRequestsOnLoadActions.removeLoading(searchValue?.searchKey));
            delete filteredInfo[searchValue?.searchKey];
        }
        return filteredInfo;
    };

    useEffect(() => {
        dispatch(carecraftActions.getCareCraftSetup());
    }, []);

    useEffect(() => {
        handleGet(searchValue?.value ? 'noLoad' : 'loading');
        if (searchValue?.value) {
            dispatch(httpRequestsOnLoadActions.appendLoading(searchValue?.searchKey));
        }
    }, [searchValue, info]);

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad';
        const filteredInfo = renderParams();
        if (info?.tab === 1) {
            dispatch(carecraftActions.getCarecraftMap({...filteredInfo}, loader, searchValue?.searchKey));
        } else {
            dispatch(carecraftActions.getCarecraft({...filteredInfo}, loader, searchValue?.searchKey));
        }
    };

    const handleChange = (event, newValue) => {
        SavePage(history, info, {tab: newValue});
    };

    const handleOpenClose = () => {
        if (info?.tab === 1) {
            open(<AddProductMap/>);
        } else {
            open(<AddPoNumber/>);
        }
    };

    const handleSearchReq = (e, name) => {
        if (info?.page !== 1) {
            SavePage(history);
        }
        setSearch({
            searchKey: name,
            value: e.target.value,
        });
    };

    return (
        <div className='carecraft-container'>
            <div className='title-buttons-wrapper'>
                <div className=''>
                    <Box className='tabBox'>
                        <Box>
                            <Tabs className={classes.buttonsTab} value={info?.tab ? info?.tab : 0}
                                  onChange={handleChange}
                                  aria-label='basic tabs example'>
                                <Tab className='tab' label='Purchase Order' {...tabProps(0)} />
                                <Tab className='tab' label='Product Map' {...tabProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>
                </div>
                <div className='buttons-wrapper'>
                    {carecraftSetup && <AddInviteButton text={'Add'} handleClick={handleOpenClose}/>}
                    <LeftCredentials statusType={'CREATE_CARECRAFT_SETUP'} setUp={carecraftSetup}/>
                </div>
            </div>

            <div className={'accord-craft'}>
                <TabPanel value={info?.tab ? info.tab : 0} index={0}>
                    <AccordCraft
                        head={carecraftHead}
                        body={carecraftBody}
                        loadingType={ACTION_TYPE}
                        list={carecraft?.poList}
                        listCount={carecraft?.count}
                        noText={'CareCraft'}
                        handleSearchReq={handleSearchReq}
                    />
                </TabPanel>
            </div>

            <TabPanel value={info?.tab} index={1}>
                <CarecraftMap
                    head={carecraftMapHead}
                    body={carecraftMapBody}
                    loadingType={MAP_ACTION_TYPE}
                    list={carecraftMap?.mappings}
                    listCount={carecraftMap?.count}
                    handleSearchReq={handleSearchReq}
                />
            </TabPanel>
        </div>
    );
};
