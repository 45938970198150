
import React from "react";
import { RouterSwitcher } from "./root";

function App() {
    return (
        <div className="App">
            <RouterSwitcher />
        </div>
    );
}

export default App;
