import {
    CREATE_PO_NUMBER,
    GET_CARECRAFT,
    GET_CARECRAFT_SETUP,
    CREATE_CARECRAFT_SETUP,
    DELETE_CARECRAFT_PO,
    SENT_CARECRAFT,
    GET_CARECRAFT_MAP,
    ADD_CARECRAFT_MAP,
    EDIT_CARECRAFT_MAP,
    DELETE_CARECRAFT_MAP,
} from './carecraft.type';

export const addPoNumber = (body) => {
    return {
        type: CREATE_PO_NUMBER,
        payload: { body },
    };
};

export const getCarecraft = (params, load, searchType) => {
    return {
        type: GET_CARECRAFT,
        payload: { params, load, searchType } ,
    };
};

export const getCareCraftSetup = (params) => {
    return {
        type: GET_CARECRAFT_SETUP,
        payload: params,
    };
};

export const createCareCraftSetup = (body) => {
    return {
        type: CREATE_CARECRAFT_SETUP,
        payload: { body },
    };
};

export const sentCareCraft = (id) => {
    return {
        type: SENT_CARECRAFT,
        payload: { id },
    };
};

export const deleteCareCraft = (id) => {
    return {
        type: DELETE_CARECRAFT_PO,
        payload: { id },
    };
};


/** CareCraft Map */

export const addCarecraftMap = (body) => {
    return {
        type: ADD_CARECRAFT_MAP,
        payload: { body },
    };
};

export const editCarecraftMap = (body, id) => {
    return {
        type: EDIT_CARECRAFT_MAP,
        payload: { body, id },
    };
};


export const getCarecraftMap = (params, load, searchType) => {
    return {
        type: GET_CARECRAFT_MAP,
        payload: { params, load, searchType } ,
    };
};

export const deleteCarecraftMap = (id) => {
    return {
        type: DELETE_CARECRAFT_MAP,
        payload: { id },
    };
};


/** End */