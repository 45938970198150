import React from "react";
import { FindLoad } from "hooks";
import { MinLoader } from '../loaders';
import { Images } from "assets/images";

export const AddModalButton = ({ text, type, load, signOut }) => {
    const loader = FindLoad(load)

    return (
        <button type={type} className='add-modal-button' >
            {
                loader?.length ?
                    <MinLoader color='white' />
                    :
                    <>
                        {signOut && (
                            <img src={Images.SignOut} alt='' />
                        )}
                        <span className="">{text}</span>
                    </>
            }
        </button>
    )
}