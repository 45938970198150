import React from "react";
import {useDispatch} from "react-redux";
import {CheckCircle} from "@mui/icons-material";
import {Colors} from "utils/colors";
import {SignIn} from "components";
import {Images} from "assets/images";

export const MessageScreen = ({type}) => {
    const dispatch = useDispatch();

    const handleTry = () => {
        // dispatch(authActions.tryAgain());
    };
    return (
        <div className='message-screen-wrapper'>
            {type === true ? (
                <div className='message-screen-modal'>
                    <CheckCircle
                        style={{
                            width: "48px",
                            height: "48px",
                            color: Colors.main,
                            marginBottom: '10px'
                        }}
                    />
                    <p className='login-title'>Success</p>
                    <p className='message-screen-response'>
                        We have sent you a recovery email.
                        <br/> Please check it.
                    </p>
                </div>
            ) : (
                <div className='message-screen-modal'>
                    <img
                        style={{marginBottom: '24px'}}
                        src={Images.Error}
                        alt={"error"}
                    />
                    <p className='login-title'>Oops!</p>
                    <p className='message-screen-response'>
                        Sorry we were unable to send you a <br/> recovery email. Please try
                        again.
                    </p>
                    <SignIn
                        margin={'16px 0 0 0'}
                        handleClick={handleTry}
                        width={"100%"}
                        text={'Try Again'}
                    />
                </div>
            )}
        </div>
    );
}
