import React from "react";
import { CheckCircle } from '@mui/icons-material';

export const MessageMiniScreen = ({ type, text }) => {
  return (
    <div className={"message-mini-screen"}>
      <CheckCircle
        style={{
          width: "30px",
          height: "30px",
          color: '#00C851',
        }}
      />
      <p>{text}</p>
    </div>
  );
};
