import { ActivePending, DateRow, TextRow } from 'components';
import React from 'react';

export const usersHead = [
    { name: 'firstName', title: 'Username', custom: false },
    { name: 'email', title: 'Email Address' },
    { name: 'createdAt', title: 'Created Date', type: 'date' },
    { name: 'status', title: 'Status', type: 'notChange' },
];

export const usersBody = [
    { rowText: (item, width) => <TextRow name={`${item?.firstName} ${item?.lastName ? item?.lastName : ''}`} width={width} /> },
    { rowText: (item, width) => <TextRow name={item?.email} width={width} /> },
    { rowText: (item) => <DateRow date={item?.createdAt} /> },
    { rowText: (item) => <ActivePending type={item?.auth} /> },
];

export const ACTION_TYPE = 'GET_USERS';


