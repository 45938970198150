import React from 'react';
import { TextField } from '@mui/material';
import { Images } from 'assets/images';
import { FindOneLoad } from '../../hooks/findLoad';
import { InputMinLoader } from '../loaders/miniLoader';

export const SearchAndFilter = ({
    top,
    title,
    notSearch,
    custom,
    handleSearch,
    name,
    value,
    width,
    setSelectedName,
    selectedName,
    dateType,
    nameRow,
}) => {
    const loader = FindOneLoad(nameRow)

    const handleClickSort = () => {
        if (selectedName === name) {
            setSelectedName('');
        } else {
            setSelectedName(name);
        }
    };

    return (
        <div style={top === true ? { marginTop: '-30px' } : {}}>
            <div className="search-input-title">
                <span>{title}</span>
                {custom !== false &&
                    (selectedName === name ? (
                        <img onClick={() => handleClickSort(name)} src={Images.ZtoA} alt={'filter icon'} />
                    ) : (
                        <img onClick={() => handleClickSort(name)} src={Images.AtoZ} alt={'filter icon'} />
                    ))}
            </div>

            <div style={notSearch === true ? { width: '150px' } : {}} className={title === 'Action' ? 'action-style' : 'search-input'}>
                {!notSearch && (
                    <TextField
                        style={width ? { width: width } : {}}
                        name={name}
                        value={value}
                        type="search"
                        id="standard-basic"
                        disabled={title === 'Action' || dateType === 'notChange'}
                        onChange={(e) => handleSearch(e, dateType, nameRow)}
                        fullWidth
                        variant={'standard'}
                        InputProps={{
                            endAdornment: (
                                nameRow && loader === nameRow ? <InputMinLoader/> : ''
                            )
                        }}
                    />
                )}
            </div>
        </div>
    );
};
