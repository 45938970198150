import axios from "axios";

export const shopifyService = {

    /** Create, Edit Amazon Product */

    createShopifyService: (body) => axios.post(`/map`,
        {
            ...body,
            type: 'SHOPIFY'
        },
        {auth: true})
    ,

    getShopifyService: (params) => axios.get(`/map`, {auth: true, params: {...params, type: 'SHOPIFY'}}),

    editShopifyMap: (body, id) => axios.patch(`/map/${id}`,
        {
            ...body,
            type: 'SHOPIFY'
        },
        {auth: true}
    ),

    deleteShopifyMap: (id) => axios.delete(`/map/${id}`, {auth: true, params: {type: 'SHOPIFY'}}),

    /** End */

    /**  Amazon Setup  */

    getShopifySetup: () => axios.get(`/shopify/setup`, {auth: true}),

    editShopifySetup: (body) => axios.patch(`/shopify/setup`, body, {auth: true}),

    creatShopifySetup: (body) => axios.post(`/shopify/setup`, body, {auth: true}),

    /**  Shopify Orders  */

    getShopifyOrdersService: (params) => axios.get(`/shopify/orders`, {auth: true, params: params}),

    getShopifyTransverseService: (params) => axios.get(`/transfer`, {auth: true, params: {...params, type: 'SHOPIFY'}}),

    syncResyncService: (params, orderId) => axios.patch(`/core/shopify/orders/${orderId}`, null, {
        auth: true,
        params: params
    }),

    orderProductMapService: (id) => axios.get(`/map/sku/${id}`,
        {
            auth: true,
            params: {
                type: 'SHOPIFY'
            }
        }
    ),

    /** End */
};
