import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./walmart.service";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {
  ADD_WALMART_PRODUCT,
  CREATE_WALMART,
  DELETE_WALMART_PRODUCT,
  EDIT_WALMART_PRODUCT,
  GET_WALMART, GET_WALMART_SUCCESS
} from "./walmart.types";

/** Create, Edit Walmart Product */
function* createWalmart({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.createWalmartService, payload.body );
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* addWalmartProduct({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.addWalmartProductService, payload.body );
    
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editWalmartProduct({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.editWalmartProductService, payload.body );
    
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
/** End */

/** Get Walmart */
function* getWalmart({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.getWalmartService );
    yield put({
      type:GET_WALMART_SUCCESS,
      payload: res.data
    })
    
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
/** End */

/** Delete Walmart Product */
function* deleteWalmartProduct({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.deleteWalmartProductService, payload.body );
    
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
/** End */

export const watchWalmart = function* watchWalmartSaga() {
  /** Create, Edit Walmart Product */
  yield takeLatest( CREATE_WALMART, createWalmart );
  yield takeLatest( ADD_WALMART_PRODUCT, addWalmartProduct );
  yield takeLatest( EDIT_WALMART_PRODUCT, editWalmartProduct );
  /** End */

  /** Get Walmart */
  yield takeLatest( GET_WALMART, getWalmart );
  /** End */

  /** Delete Walmart Product*/
  yield takeLatest( DELETE_WALMART_PRODUCT, deleteWalmartProduct );
  /** End */
};
