import {Colors} from "utils";

export const ActivePending = ({ type }) =>{
    return(
        <div style={{background: type.status === 2 ? Colors.lightGreen : Colors.lightOrange}} className='active-pending-style'>
           <p  style={{color: type.status === 2 ? Colors.green : Colors.orange}} >
               { type.status === 2 ? 'Active' : 'Pending' }
           </p>
        </div>
    )
}