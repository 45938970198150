import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AddInviteButton} from 'components';
import {} from './fragments';
import {shopifyActions} from 'store';
import {Tab, Box, Tabs} from '@mui/material';
import {
    AddProductModal,
    RightDrawer,
    buttonsStyle,
    shopifyOrderBody,
    shopifyOrderHead,
    shopifyBody,
    shopifyHead,
    shopifyInventoryTransferBody,
    shopifyInventoryTransferHead
} from './fragments';
import {SavePage, useModal} from 'utils';
import {ShopifyMap} from './fragments/shopifyMap';
import {useDebounce} from 'use-debounce';
import {httpRequestsOnLoadActions} from '../../store/http_requests_on_load';

import {ProductMapModal} from './fragments/productMapModal';


function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Shopify = ({}) => {
    const {shopifyProducts, shopifyOrders, shopifyTransverse, setUp} = useSelector((state) => ({
        shopifyProducts: state.shopify.shopifyProducts,
        shopifyOrders: state.shopify.shopifyOrders,
        shopifyTransverse: state.shopify.shopifyTransverse,
        setUp: state.shopify.setUp,
    }));

    const classes = buttonsStyle();
    const dispatch = useDispatch();
    const {open} = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const [search, setSearch] = useState(null);
    const [searchValue] = useDebounce(search, 500);
    const [showSelected, setShowSelected] = useState('')
    const checkTab = info?.tab === 0 ? true : !info?.tab


    const handleChange = (event, newValue) => {
        SavePage(history, info, {tab: newValue});
        setShowSelected('')
    };

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;

        if (searchValue?.value) {
            filteredInfo[searchValue?.searchKey] = searchValue?.value;
        }
        if (!searchValue?.value) {
            dispatch(httpRequestsOnLoadActions.removeLoading(searchValue?.searchKey));
            delete filteredInfo[searchValue?.searchKey];
        }
        return filteredInfo;
    };

    useEffect(() => {
        handleGet(searchValue?.value ? 'noLoad' : 'loading');
        if (searchValue?.value) {
            dispatch(httpRequestsOnLoadActions.appendLoading(searchValue?.searchKey));
        }
    }, [searchValue, info]);

    useEffect(() => {
        if (searchValue) {
            const filteredInfo = renderParams();
            dispatch(shopifyActions.getShopifyOrders({
                ...filteredInfo,
                ...searchValue
            }))
        }

    }, [searchValue])

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad';
        const filteredInfo = renderParams();
        if (!info?.tab || info?.tab === 0) {
            dispatch(shopifyActions.getShopifyOrders(filteredInfo))
        } else if (info?.tab === 2) {
            dispatch(shopifyActions.getShopifyTransverse(filteredInfo))
        } else {
            dispatch(shopifyActions.getShopify({...filteredInfo}, loader, searchValue?.searchKey));
        }
    };

    const handleSearchReq = (value, name) => {
        if (info?.page !== 1) {
            SavePage(history);
        }

        setSearch({
            [name]: value,
        });
    };



    const renderTable = () => {
        switch (info?.tab) {
            case 0:
                return {
                    head: shopifyOrderHead,
                    body: shopifyOrderBody,
                    actionType: 'GET_SHOPIFY_ORDERS',
                    list: shopifyOrders?.orders,
                    count: shopifyOrders?.count,
                };

            case 1:
                return {
                    head: shopifyHead,
                    body: shopifyBody,
                    actionType: 'GET_SHOPIFY',
                    list: shopifyProducts?.mappings,
                    count: shopifyProducts?.count,
                };

            case 2:
                return {
                    head: shopifyInventoryTransferHead,
                    body: shopifyInventoryTransferBody,
                    actionType: 'GET_SHOPIFY_TRANSVERSE',
                    list: shopifyTransverse?.inventoryTransfer,
                    count: shopifyTransverse?.count,
                };

            default:
                return {
                    head: shopifyOrderHead,
                    body: shopifyOrderBody,
                    actionType: 'GET_SHOPIFY_ORDERS',
                    list: shopifyOrders?.orders,
                    count: shopifyOrders?.count,
                };
        }
    };

    const checkType = info?.tab ?
        info?.tab === 0 ? true :
            info?.tab === 1 ? true :
                info?.tab === 2 ? true : ''
        : true;

    const renderTableValues = renderTable()

    const handleOpenProductMap = (item) => {
        setShowSelected(item)
        dispatch(shopifyActions.orderProductMap(item?.shopifySKU))
    }


    return (
        <div>
            <div className="title-buttons-wrapper">
                <div className="">
                    <Box className="tabBox">
                        <Box>
                            <Tabs className={classes.buttonsTab} value={info?.tab ? info?.tab : 0}
                                  onChange={handleChange}
                                  aria-label="basic tabs example">
                                <Tab className="tab" label="Orders" {...tabProps(0)} />
                                <Tab className="tab" label="Product Map" {...tabProps(1)} />
                                <Tab className="tab" label="Inventory Transfer" {...tabProps(2)} />
                            </Tabs>
                        </Box>
                    </Box>
                </div>

                {(info?.tab === 1 || info?.tab === 0 || !info?.tab) &&
                    <div className="buttons-wrapper">
                        {info?.tab === 1 &&

                            <AddInviteButton
                                text={'Add'}
                                handleClick={() => open( <AddProductModal/>)}
                            />
                        }

                        <RightDrawer
                            setUp={setUp}
                            statusType="CREATE_SHOPIFY"
                        />
                    </div>
                }

            </div>
            {checkType &&
                <div
                    style={{display: 'flex'}}
                    // value={info?.tab ? info?.tab : 0} index={0 || 1 || 3}
                >
                    <ShopifyMap
                        renderParams={renderParams}
                        handleOpen={handleOpenProductMap}
                        // handleOpen={setShowSelected}
                        head={renderTableValues?.head}
                        body={renderTableValues?.body}
                        loadingType={renderTableValues?.actionType ? renderTableValues?.actionType : 'GET_SHOPIFY_ORDERS'}
                        type={
                            info?.tab === 1 ? 'map' :
                                info?.tab === 2 ? 'transfer' :
                                    ''}
                        list={renderTableValues?.list}
                        listCount={renderTableValues?.count}
                        handleSearchReq={handleSearchReq}
                    />

                    {checkTab && showSelected &&
                        <ProductMapModal handleClose={() => setShowSelected(null)}/>
                    }
                </div>
            }
        </div>
    );
};
