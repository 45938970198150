import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Login } from 'pages';
import { MenuBar } from '../fragments/menuBar/menuBar';
import { useSelector } from 'react-redux';

export const RouterSwitcher = () => {
    /**
     *  Router Swicher.
     */

    const { accessToken } = useSelector((state) => ({
        accessToken: state.auth.accessToken,
    }));

    const token = localStorage.getItem('access-token');
    const link = window.location.search;

    useEffect(() => {
        if (link.search('token=') !== -1 || link.search('resetToken=') !== -1) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('poloUserInfo');
            localStorage.removeItem('userType');
            localStorage.removeItem('permissions');
        }
    }, []);

    return (
        <React.Fragment>
            {!token || link.search('resetToken=') !== -1 || link.search('token=') !== -1 ? (
                <Switch>
                    <Route path="/" component={Login} />
                    <Route path="/resetPassword/:resetToken?" component={Login} />
                    <Route path="/register/:token?" component={Login} />
                    <Redirect to="/" />
                </Switch>
            ) : (
                <MenuBar />
            )}
        </React.Fragment>
    );
};
