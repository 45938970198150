import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AddInviteButton} from 'components';
import {LeftDrawer} from 'fragments';
import {amazonActions} from 'store';
import {Tab, Box, Tabs} from '@mui/material';
import {AddProductModal, amazonHead, buttonsStyle, AmazonList, amazonBody} from './fragments';
import {SavePage, useModal} from 'utils';
import {AmazonMap} from './fragments/amazonMap';
import {useDebounce} from 'use-debounce';
import {httpRequestsOnLoadActions} from '../../store/http_requests_on_load';
import {
    amazonInventoryTransferBody,
    amazonInventoryTransferHead,
    amazonOrderBody,
    amazonOrderHead,
} from './fragments/constants';
import {ProductMapModal} from './fragments/productMapModal';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Amazon = ({}) => {
    const {amazonProducts, amazonOrders, amazonTransvers, amazonType, setUp} = useSelector((state) => ({
        amazonProducts: state.amazon.amazonProducts,
        amazonOrders: state.amazon.amazonOrders,
        amazonTransvers: state.amazon.amazonTransvers,
        amazonType: state.amazon.amazonType,
        setUp: state.amazon.setUp,
    }));
    const classes = buttonsStyle();
    const dispatch = useDispatch();
    const {open} = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const [search, setSearch] = useState(null);
    const [searchValue] = useDebounce(search, 500);
    const [showSelected, setShowSelected] = useState('')
    const checkTab = info?.tab === 0 ? true : !info?.tab

    const handleChange = (event, newValue) => {
        SavePage(history, info, {tab: newValue});
        setShowSelected('')
    };

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;

        if (searchValue?.value) {
            filteredInfo[searchValue?.searchKey] = searchValue?.value;
        }
        if (!searchValue?.value) {
            dispatch(httpRequestsOnLoadActions.removeLoading(searchValue?.searchKey));
            delete filteredInfo[searchValue?.searchKey];
        }
        return filteredInfo;
    };

    useEffect(() => {
        handleGet(searchValue?.value ? 'noLoad' : 'loading');
        if (searchValue?.value) {
            dispatch(httpRequestsOnLoadActions.appendLoading(searchValue?.searchKey));
        }
    }, [searchValue, info]);

    useEffect(() => {
        if (searchValue) {
            const filteredInfo = renderParams();
            dispatch(amazonActions.getAmazonOrders({
                ...filteredInfo,
                ...searchValue
            }))
        }
    }, [searchValue])

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad';
        const filteredInfo = renderParams();
        if (!info?.tab || info?.tab === 0) {
            dispatch(amazonActions.getAmazonOrders(filteredInfo))
        } else if (info?.tab === 3) {
            dispatch(amazonActions.getAmazonTransvers(filteredInfo))
        } else {
            dispatch(amazonActions.getAmazon({...filteredInfo}, loader, searchValue?.searchKey));
        }
    };

    useEffect(() => {
        dispatch(amazonActions.getAmazonType());
    }, []);

    const handleSearchReq = (value, name) => {
        if (info?.page !== 1) {
            SavePage(history);
        }

        setSearch({
            [name]: value,
        });
    };

    const renderTable = () => {
        switch (info?.tab) {
            case 0:
                return {
                    head: amazonOrderHead,
                    body: amazonOrderBody,
                    actionType: 'GET_AMAZON_ORDERS',
                    list: amazonOrders?.orders,
                    count: amazonOrders?.count,
                };

            case 1:
                return {
                    head: amazonHead,
                    body: amazonBody,
                    actionType: 'GET_AMAZON',
                    list: amazonProducts?.mappings,
                    count: amazonProducts?.count,
                };

            case 3:
                return {
                    head: amazonInventoryTransferHead,
                    body: amazonInventoryTransferBody,
                    actionType: 'GET_AMAZON_TRANSVERS',
                    list: amazonTransvers?.inventoryTransfer,
                    count: amazonTransvers?.count,
                };

            default:
                return {
                    head: amazonOrderHead,
                    body: amazonOrderBody,
                    actionType: 'GET_AMAZON_ORDERS',
                    list: amazonOrders?.orders,
                    count: amazonOrders?.count,
                };
        }
    };

    const checkType = info?.tab ?
        info?.tab === 0 ? true :
            info?.tab === 1 ? true :
                info?.tab === 3 ? true : ''
        : true;

    const renderTableValues = renderTable()

    const handleOpenProductMap = (item) => {
        setShowSelected(item)
        dispatch(amazonActions.orderProductMap(item?.amazonSKU))
    }


    return (
        <div>
            <div className="title-buttons-wrapper">
                <div className="">
                    <Box className="tabBox">
                        <Box>
                            <Tabs className={classes.buttonsTab} value={info?.tab ? info?.tab : 0}
                                  onChange={handleChange}
                                  aria-label="basic tabs example">
                                <Tab className="tab" label="Orders" {...tabProps(0)} />
                                <Tab className="tab" label="Product Map" {...tabProps(1)} />
                                <Tab className="tab" label="Product Type" {...tabProps(2)} />
                                <Tab className="tab" label="Inventory Transfer" {...tabProps(3)} />
                            </Tabs>
                        </Box>
                    </Box>
                </div>

                {(info?.tab === 1 || info?.tab === 0 || !info?.tab) &&
                    <div className="buttons-wrapper">
                        {info?.tab === 1
                            &&
                            <AddInviteButton
                                text={'Add'}
                                handleClick={() => open( <AddProductModal/>)}
                            />}

                        <LeftDrawer type={'amazon'} statusType={'CREATE_AMAZON'} setUp={setUp}/>
                    </div>
                }

            </div>
            {checkType &&
                <div
                    style={{display: 'flex'}}
                    // value={info?.tab ? info?.tab : 0} index={0 || 1 || 3}
                >
                    <AmazonMap
                        renderParams={renderParams}
                        handleOpen={handleOpenProductMap}
                        // handleOpen={setShowSelected}
                        head={renderTableValues?.head}
                        body={renderTableValues?.body}
                        loadingType={renderTableValues?.actionType ? renderTableValues?.actionType : 'GET_AMAZON_ORDERS'}
                        type={
                            info?.tab === 1 ? 'map' :
                                info?.tab === 3 ? 'transfer' :
                                    ''}
                        list={renderTableValues?.list}
                        listCount={renderTableValues?.count}
                        handleSearchReq={handleSearchReq}
                    />

                    {checkTab && showSelected &&
                        <ProductMapModal handleClose={() => setShowSelected(null)}/>
                    }
                </div>
            }
            <TabPanel value={info?.tab ? info?.tab : 0} index={2}>
                <AmazonList
                    amazonType={amazonType}
                />
            </TabPanel>
        </div>
    );
};
