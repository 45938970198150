import axios from "axios";

export const authService = {

    /** Create, Edit Ebay Product */
    createEbayService: ( body ) => axios.post(`/user`, body, { auth:true } ),

    addEbayProductService: ( body ) => axios.post(`/user`, body, { auth:true } ),

    editEbayProductService: ( body, id ) => axios.patch(`/user/${id}`, body, { auth:true } ),

    getEbayService: ( ) => axios.get(`/ebay/product`, { auth:true } ),

    deleteEbayProductService: ( body, id ) => axios.patch(`/user/${id}`, body, { auth:true } ),
    /** End */

};
