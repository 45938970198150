import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PasswordInput, ValidationInput, SignIn, ErrMessage } from 'components';
import { EmailValidator } from 'utils';
import { LoginErrorMessage, NotMathEmail, NotMathPassword } from './core/constants';
import { authActions, httpRequestsOnErrorsActions } from 'store';
import { FindError, FindLoad } from '../../../hooks';

const ACTION_TYPE = 'LOG_IN'

export const LoginModal = ({ handleForgot }) => {
    const dispatch = useDispatch();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [validEmail, setValidEmail] = useState(false);
    const backError = FindError(ACTION_TYPE)
    const loader = FindLoad(ACTION_TYPE)
    const errMessage = LoginErrorMessage(backError?.error);
    const notMath = NotMathEmail(backError?.error, error, validEmail);
    const passwordErr = NotMathPassword(backError?.error, error);

    const logInRequest = () => {
        const user = { email: login, password };
        if (validEmail === false && login && login !== 'Not valid email' && password && password !== 'notMath') {
            dispatch(authActions.logIn(user));
        } else {
            if (!login) {
                setError('notMathLogin');
            } else if (validEmail === true) {
                setError('Not valid email');
            } else if (!password) {
                setError('notMathPassword');
            }
        }
    };

    const handleChange = (ev) => {
        let { name, value } = ev.target;
        setError(null);
        if (name === 'email') {
            setLogin(value);
            if(backError?.error === "User with this email was not found"){
                dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
            }
        } else if (name === 'password') {
            setPassword(value);
            if(backError?.error === "user password does not match"){
                dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
            }
        }
    };

    const handleCheck = (bool) => {
        if (bool === true) {
            setValidEmail('Not valid email');
        } else {
            setValidEmail(false);
        }
    };

    return (
        <div className="modal-styles">
            <p className="login-title">Sign in</p>
            <div style={{marginTop:'20px'}}>
            <ValidationInput
                validator={EmailValidator}
                value={login}
                onChange={handleChange}
                sendBoolean={handleCheck}
                typeError={notMath}
                name={'email'}
                type={'email'}
                label={'Email'}
                variant={'standard'}
                id={'email'}
                autoComplete={'current-email'}
                margin={'0'}
            />
            <PasswordInput
                styles={{ marginTop: '15px' }}
                handleChangePassword={handleChange}
                type={'password'}
                name={'password'}
                disabled={false}
                value={password}
                onChange={handleChange}
                typeError={passwordErr}
                placeholder={'Password'}
                margin={'0'}
            />
            </div>
            <ErrMessage type={'login'} text={errMessage} />
            <div className="login-modal-buttons">
                <SignIn loader={!!loader?.length} handleClick={logInRequest} width={'200px'} text={'Sign In'} />

                <button onClick={handleForgot} className="forgot-button">
                    Forgot Password?
                </button>
            </div>
        </div>
    );
};
