/** Create Erply */
export const CREATE_ERPLY = 'CREATE_ERPLY';
export const EDIT_ERPLY = 'EDIT_ERPLY';

/** Add, Edit Erply product */
export const ADD_ERPLY_PRODUCT = 'ADD_ERPLY_PRODUCT';
export const ADD_ERPLY_PRODUCT_SUCCESS = 'ADD_ERPLY_PRODUCT_SUCCESS';

export const EDIT_ERPLY_PRODUCT = 'EDIT_ERPLY_PRODUCT';
export const EDIT_ERPLY_PRODUCT_SUCCESS = 'EDIT_ERPLY_PRODUCT_SUCCESS';

/** Get Erply */
export const GET_ERPLY = 'GET_ERPLY';
export const GET_ERPLY_SUCCESS = 'GET_ERPLY_SUCCESS';

/** Get Erply Setup */
export const GET_ERPLY_SETUP = 'GET_ERPLY_SETUP';
export const GET_ERPLY_SETUP_SUCCESS = 'GET_ERPLY_SETUP_SUCCESS';

/** Erply Warehouse  */
export const GET_WAREHOUSE = 'GET_WAREHOUSE';
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS';

export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE';

export const EDIT_WAREHOUSE = 'EDIT_WAREHOUSE';

export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';

/** Get Erply by id */
export const GET_ERPLY_BY_ID = 'GET_ERPLY_BY_ID';
export const GET_ERPLY_BY_ID_SUCCESS = 'GET_ERPLY_BY_ID_SUCCESS';
