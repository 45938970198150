import React from 'react';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import { store } from 'store'
import { initAxiosInterceptors } from "hooks";
import App from './App';
import '../src/assets/styles/allStyles.scss';
import { AllModals, ModalProvider } from './utils/modalContext';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
initAxiosInterceptors();

root.render(
    <StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ModalProvider>
                   <App />

                    <AllModals/>
                </ModalProvider>
            </Provider>
        </BrowserRouter>
    </StrictMode>
);

reportWebVitals();