import {makeStyles} from "@mui/styles";


export const modalsStyle = makeStyles(() => ({
  closeButton:{
    display:"flex",
    justifyContent:"flex-end",
  },

  buttons:{
    display:'flex'
  },

  deleteModalWrapper:{
    padding:'8px 40px 0 40px',
  },

  deleteInfo:{
    // color:Colors.ThemeRed,
    fontSize: '18px',
  },

  deleteModal:{
    width:'500px',
    height: "auto",
    // background: Backgrounds.whiteModal,
    borderRadius: "8px",
    padding:'8px 0 40px 0',
    display:'flex',
    flexDirection:'column',
    textAlign:'center',
  },
  payroll:{
    width:'580px',
    height: "auto",
    // background: Backgrounds.whiteModal,
    borderRadius: "8px",
    padding:'8px 0 40px 0',
    display:'flex',
    flexDirection:'column',
    // textAlign:'center',
  },

  filterTypeText:{
    fontSize:'16px',
    fontWeight:'bold',
    // color: Colors.TextSecondary,
    padding:'8px 24px 0 24px',
  },

  radioButton:{
    '& .MuiSvgIcon-root': {
      color: 'red',
    },
  },

  leftModalWrapper:{

    '& .MuiDialog-container':{
      position:'absolute',
      right: 0
    }
  },

  paymentInfo:{
    height:'230px',
    boxShadow: '0px 0px 6px #8A8A8A29',
    borderRadius: '0 0 8px 8px',
    width:'100%',
    overflow:'auto',
    padding:'16px',
  },

  editPayment:{
    display:'flex',
    justifyContent:'flex-end',
  },

  rows:{
    height:'48px',
    background: '#E6ECF380 0% 0% no-repeat padding-box',
    borderRadius: '4px 4px 0px 0px',
    marginBottom:'8px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'0 16px',
    '& p':{
      fontSize:'14px',
      // color:Colors.TextPrimary,
    },
    '& span':{
      fontSize:'14px',
      // color:Colors.TextSecondary,
      fontWeight:'bold'
    },
  },

  editSave:{
    display:'flex',
    width:'100%',
  },

  inputTextFieldBlue: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      // borderColor: Colors.ThemeBlue
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      // borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      // color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      // color: Colors.ThemeBlue
    },
    '& .MuiSelect-icon': {
      // color: Colors.ThemeBlue
    },

    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '60px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiOutlinedInput-root': {
      height: '120px',
      minHeight: '120px',
    },
  },

  inputTextField: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      // borderColor: Colors.ThemeBorder
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none'
    },
    '& .MuiOutlinedInput-root': {
      height: '120px',
      minHeight: '120px',
    },
  },


}));


