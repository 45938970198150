import axios from 'axios';

export const carecraftService = {
    /** CareCraft */
    getCareCraft: (params) => axios.get(`/carecraft/PO`, { auth: true, params: { ...params } }),

    createPoNumber: (body) => axios.post(`/core/carecraftPO/${body?.poNumber}/${body?.warehouseId}`, {}, { auth: true }),

    sentCareCraft: (id) => axios.patch(`/core/carecraftPO/${id}`, {}, { auth: true }),

    deleteCareCraft: (id) => axios.delete(`/carecraft/PO/${id}`, { auth: true }),
    /** End */

    /** CareCraft Setup*/

    getCareCraftSetup: (params) => axios.get(`/carecraft/setup`, { auth: true, params: { ...params } }),

    createCareCraftSetup: (body) => axios.post(`/carecraft/setup`, body, { auth: true }),
    /** End */

    /** CareCraft Map */

    getCareCraftMapService: (params) => axios.get(`/carecraft/map`, { auth: true, params: { ...params } }),

    addCarecraftMapService: (body) => axios.post(`/carecraft/map`, body, { auth: true }),

    editCarecraftMapService: (id, body) => axios.patch(`/carecraft/map/${id}`, body, { auth: true }),

    deleteCareCraftMapService: (id) => axios.delete(`/carecraft/map/${id}`, { auth: true }),

    /** End */
};
