import React, { useState, useEffect, Fragment } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FindLoad, FindSuccess } from 'hooks';
import { DeleteModal } from 'fragments';
import { SendPageSave, SavePage } from 'utils';
import { SearchAndFilter, PaginationItem, Loader, MinLoader, TableNoInfo, DateRow, TextRow } from 'components';
import moment from 'moment';
import { tableStyle } from 'components/table/styles';
import { useDispatch } from 'react-redux';
import { carecraftActions, httpRequestsOnSuccessActions } from 'store';

export const AccordCraft = ({
                                head,
                                list,
                                loadingType,
                                noText,
                                margin,
                                status,
                                listCount,
                                handleSearchReq,
                            }) => {
    /**
     *  Accord Craft
     */

    const [expanded, setExpanded] = useState(false);
    const SEND_RESEND = 'SENT_CARECRAFT';
    const dispatch = useDispatch();
    const classes = tableStyle();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const [modalInfo, setModalInfo] = useState('');
    const info = history?.location?.state;
    const [selectedName, setSelectedName] = useState('');
    const [openDel, setOpenDel] = useState(false);
    const loader = FindLoad(loadingType ? loadingType : '');
    const [listInfo, setListInfo] = useState('');
    const [selectedId, setSelectedIf] = useState('');
    const minLoad = FindLoad(SEND_RESEND);
    const resendSuccess = FindSuccess(SEND_RESEND);


    useEffect(() => {
        if (list) {
            setListInfo(list);
        }
    }, [list]);

    useEffect(() => {
        const anotherInfo = { ...info };
        info?.status ? (anotherInfo.status = info.status) : status ? (anotherInfo.status = status) : delete anotherInfo.status;
        SavePage(history, info, { ...anotherInfo });
    }, []);

    useEffect(() => {
        if (info) {
            info?.page !== page && setPage(info?.page);
            // eslint-disable-next-line no-unused-expressions
            info?.sortType ? setSelectedName(info?.sortType) : '';
        }
    }, [info]);

    useEffect(() => {
        if (resendSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(SEND_RESEND));
        }
    }, [resendSuccess]);

    const handleChangeExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handle = (e) => {
            if (e.target.value === 'All') {
                const cleanList = {
                    ...info,
                };
                delete cleanList[e.target.name];
                SavePage(history, info, { ...cleanList });
            } else {
                if (!e.target.value) {
                    const filteredList = {
                        ...info,
                    };
                    delete filteredList[e.target.name];
                    SavePage(history, info, { ...filteredList });
                } else {
                    const newObj = {
                        ...info,
                    };
                    newObj[e.target.name] = e.target.value;
                    SavePage(history, info, { ...newObj });
                }
            }
    };

    const setName = (name) => {
        const anotherInfo = { ...info };
        if (name) {
            anotherInfo.sortType = name;
        } else {
            delete anotherInfo.sortType;
        }
        SavePage(history, info, { ...anotherInfo });
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    const handleChange = (e, type, nameRow) => {
        if (e.target.name === 'searchRequest') {
            handleSearchReq(e, nameRow)
        }else {
            if (e.target.name) {
                if (type !== 'notChange') {
                    const info = list.filter((el) =>
                        type === 'date'
                            ? el[e.target.name] &&
                            moment(el[e.target.name]).format('DD/MM/YYYY').toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                            : el[e.target.name] && el[e.target.name].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1,
                    );
                    setListInfo(info);
                }
            }
        }
    };

    const openDeleteType = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setModalInfo(item);
        setOpenDel(!openDel);
    };

    const handleDelete = () => {
        dispatch(carecraftActions.deleteCareCraft(modalInfo?.id));
    };

    const functionStatus = (params) => {
        switch (params) {
            case 'FULLY_SUCCESSFUL':
                return <div className='partial-success'>Fully Successful</div>;
            case 'PARTIALLY_SUCCESSFUL':
                return <div className='partial-worring'>Partial Successful</div>;
            case 'UNSUCCESSFUL':
                return <div className='partial-error'>Unsuccessful</div>;
            default:
                return <div className='partial-success'>Fully Successful</div>;
        }
    };

    const handleSend = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIf(item?.id);
        dispatch(carecraftActions.sentCareCraft(item?.id));
    };

    return (
        <div style={{ width: '100%' }}>
            {}
            <div style={margin ? { margin: 0 } : {}} className={classes.tableWrapper}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {head
                                    ? head.map((i, j) => (
                                        <TableCell key={j}>
                                            <SearchAndFilter
                                                setSelectedFilter={handle}
                                                selectedName={selectedName}
                                                setSelectedName={setName}
                                                handleSearch={handleChange}
                                                name={i.name}
                                                width={i.width}
                                                itemKey={i.key}
                                                title={i.title}
                                                custom={i.custom}
                                                top={i.top}
                                                dateType={i?.type}
                                                notSearch={i.notSearch}
                                                smallSize={i?.smallSize}
                                                bigSize={i?.bigSize}
                                                iconType={i?.icon}
                                                nameRow={i?.nameRow}
                                            />
                                        </TableCell>
                                    ))
                                    : ''}
                            </TableRow>
                        </TableHead>
                    </Table>

                    {loader?.length ?
                        <Loader />
                        :
                        <tbody>
                        <Box className='acordion-container'
                             style={{ height: '70vh', overflow: 'auto' }}
                        >
                            {listInfo?.length ? (
                                listInfo?.map((item, j) => (
                                    <React.Fragment key={j}>
                                        <Accordion
                                            style={{ borderBottom: '1px solid #CED8E5' }}
                                            key={j}
                                            expanded={item?.action === 'UNSUCCESSFUL' ? false : expanded === j}
                                            onChange={handleChangeExpand(j)}
                                            sx={{ boxShadow: 'none' }}>
                                            <AccordionSummary
                                                expandIcon={item?.action === 'UNSUCCESSFUL' ?
                                                    <div style={{ width: '24px' }} /> : <ExpandMoreIcon />}
                                                aria-controls='panel-content'
                                                id='panel-header'>
                                                <Box className='accordion-tr'>
                                                    <Box className='accordion-th'>
                                                        {item?.poNumber}
                                                    </Box>
                                                    <Box className='accordion-th'>
                                                        <DateRow date={item?.addedDate} />
                                                    </Box>
                                                    <Box className='accordion-th'>
                                                        <div className='header-status'>
                                                            <DateRow date={item?.createdDate} />
                                                        </div>
                                                    </Box>
                                                    <Box className='accordion-th'>
                                                        <div className='header-status'>
                                                            <TextRow name={item?.poStatus} />
                                                        </div>
                                                    </Box>
                                                    <Box className='accordion-th'>
                                                        <div style={{ width: '145px' }} className='header-status'>
                                                            {item?.action ? <>{functionStatus(item?.action)}</> : 'Not Set'}
                                                        </div>
                                                    </Box>
                                                    <Box className='accordion-th' sx={{
                                                        display: 'flex ',
                                                        alignItems: 'center',
                                                        width: '14%!important',
                                                    }}>
                                                        {minLoad?.length && selectedId === item?.id ? (
                                                            <div className='min-load'>
                                                                <MinLoader color='#BA43B1' position={'relative'}
                                                                           margin={0} />
                                                            </div>
                                                        ) : (
                                                            <div className='header-status'>
                                                                {item?.action === 'UNSUCCESSFUL' && (
                                                                    <>
                                                                        <button
                                                                            type='button'
                                                                            className='btn-resent'
                                                                            onClick={(event) => handleSend(event, item)}>
                                                                            Send
                                                                        </button>
                                                                    </>
                                                                )}
                                                                {item?.action === 'PARTIALLY_SUCCESSFUL' && (
                                                                    <>
                                                                        <button
                                                                            type='button'
                                                                            className='btn-resent'
                                                                            onClick={(event) => handleSend(event, item)}>
                                                                            Resent
                                                                        </button>
                                                                    </>
                                                                )}
                                                                {item?.action === 'UNSUCCESSFUL' && (
                                                                    <button
                                                                        type='button'
                                                                        className='btn-delete'
                                                                        onClick={(event) => openDeleteType(event, item)}>
                                                                        Delete
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box className='accordion-body'>
                                                    {/*<div className='banner'>*/}
                                                    {/*    <h5 className='titile-banner'>Not Moved Products</h5>*/}
                                                    {/*</div>*/}
                                                    <div className='table'>
                                                        <div className='table-header'>
                                                            <Box className='table-th th-sku'>SKU</Box>
                                                            <Box className='table-th th-sku'>Erply Product Code</Box>
                                                            <Box className='table-th th-quantity'>Quantity</Box>
                                                            <Box className='table-th th-status '>Status</Box>
                                                        </div>
                                                        <div className='table-body'>
                                                            {item?.items.map((info, index) => (
                                                                <Box className='body-list' sx={{ marginBottom: '4px' }}
                                                                     key={index}>
                                                                    <Box className='body-th th-sku'>{info?.sku}</Box>
                                                                    <Box
                                                                        className='body-th th-sku'>{info?.erplyCode ? info?.erplyCode : 'Not Set'}</Box>
                                                                    <Box
                                                                        className='body-th th-quantity'>{info?.quantity}</Box>
                                                                    <Box className='body-th th-status'>
                                                                        {info?.status === 'SUCCESS' ? (
                                                                            <span
                                                                                className='text-success'>Success</span>
                                                                        ) : (
                                                                            <span className='text-error'>Failure</span>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </React.Fragment>
                                ))
                            ) : (
                                <>{loader?.length ? <Loader /> : <TableNoInfo text={`No ${noText} Yet`} />}</>
                            )}
                        </Box>
                        </tbody>
                    }

                    {listCount > 0 ? (
                        <PaginationItem
                            listLength={list?.length}
                            page={page}
                            handleReturn={(number) => changePage(number)}
                            count={listCount}
                        />
                    ) : (
                        ''
                    )}
                </TableContainer>
            </div>
            <DeleteModal
                handleDelete={handleDelete}
                handleOpenClose={openDeleteType}
                open={openDel}
                item={modalInfo}
                loadType={'DELETE_CARECRAFT_PO'}
            />
        </div>
    );
};
