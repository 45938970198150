import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CloseButton, AddModalButton, ErrMessage } from 'components';
import { TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { TEXT_FIELD_STYLES_SMALL, SELECT_FIELD_STYLES } from 'fragments/modals/constants';
import { carecraftActions, erplyActions, httpRequestsOnSuccessActions } from 'store';
import { FindError, FindSuccess } from 'hooks';
import { SavePage, useModal } from 'utils';

export const AddPoNumber = ({}) => {
    const { warehouseList } = useSelector((state) => ({
        warehouseList: state.erply.warehouseList,
    }));
    const ACTION_TYPE = 'CREATE_PO_NUMBER';
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);
    const history = useHistory();
    const info = history?.location?.state;
    const { handleSubmit, control, register, reset, formState: { errors } } = useForm();
    const { close } = useModal();

    useEffect(() => {
        dispatch(erplyActions.getWarehouse());
    }, []);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            reset({ poNumber: '', warehouseId: '' });

            const sendInfo = {
                ...info,
            };
            delete sendInfo.page;
            SavePage(history, info, { ...sendInfo });
        }
    }, [success]);

    const onSubmit = (data) => {
        dispatch(carecraftActions.addPoNumber(data));
    };

    return (
        <div className='add-modal-fragment'>
            <div className='modal-header'>
                <CloseButton handleClose={close} />
            </div>
            <div className='modal-body'>
                <p className='add-modal-title'>Add PO Number</p>
                <p className='add-modal-subtitle'>*All product codes are unique.</p>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}
                          style={{ display: 'flex', flexDirection: 'column' }}>
                        <Controller
                            name={'poNumber'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('poNumber', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Set Purchase order number'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                    style={{ marginBottom: 0 }}
                                    error={
                                        backError?.error === 'PO not found' ? 'PO not found'
                                            : backError?.error === 'PO is exist' ? 'PO is exist' : ''
                                    }
                                />
                            )}
                        />
                        <ErrMessage
                            style={{ margin: '0 0 10px' }}
                            text={
                                backError?.error === 'PO not found'
                                    ? 'PO not found'
                                    : backError?.error === 'PO is exist'
                                        ? 'PO is exist'
                                        : ''
                            }
                        />
                        <Controller
                            name={'warehouseId'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth>
                                    <InputLabel id='select-label'>Choose Warehouse*</InputLabel>
                                    <Select
                                        {...register('warehouseId', { require: true })}
                                        labelId='select-label'
                                        id='simple-select'
                                        value={value}
                                        required={true}
                                        onChange={onChange}
                                        label={'Choose Warehouse'}
                                        size='medium'
                                        sx={SELECT_FIELD_STYLES}>
                                        {warehouseList?.map((item) => (
                                            <MenuItem key={item.id} value={item.warehouseId}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <AddModalButton text='Add' load={ACTION_TYPE} type='submit' />
                    </form>
                </div>
            </div>
        </div>
    );
};
