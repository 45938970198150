import {createUser, editUser, getUsers, getUsersById, inviteUser} from "./users.action";

export { usersReducer } from './users.reducer';
export { watchUser } from './users.saga';

export const userActions = {
  /** Create, Edit User */
  createUser,
  editUser,
  /** End */

  /** Get Users */
  getUsers,
  getUsersById,
  /** End */

  /** Invite User */
  inviteUser
  /** End */
}