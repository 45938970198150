import axios from "axios";

export const authService = {

    signIn: (body) => axios.post('/authn/signin', body),

    getAccount: (body) => axios.get('/user/myProfile/byToken', { auth: true }),

    logOut: () => axios.post(`/authn/logout`, {}, { auth: true }),

    getLink: (email) => axios.get(`/authn/forgotPassword/${email}`),

    confirmAccount: (passwords) => {
        const res = axios.post(`/authn/register`,
            {
                "newPassword": passwords.newPassword,
                "confirmation": passwords.confirmation
            },
            { headers: { 'registration-token': passwords.token } },
        );
        return res;
    },

    resetPass: (passwords) => {
        let endpoint = `/authn/resetPassword`;
        const res = axios.post(endpoint,
            {
                "newPassword": passwords.newPassword,
                "confirmation": passwords.confirmation
            },
            { headers: { 'reset-token': passwords.token } },
        );
        return res;
    },

    changePasswordService: (data) => axios.post('/authn/changePassword', data, { auth: true }),

    muAuthnService: () => axios.get(`/authn/myAuth`, { auth: true }),

    myProfileService: (type) => axios.get('/user/myProfile/byToken', { auth: true })

};
