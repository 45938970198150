import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PasswordValidator } from 'utils';
import { ErrMessage, PasswordInput, SignIn } from 'components';
import { authActions, httpRequestsOnErrorsActions } from 'store';
import { FindError, FindLoad, FindSuccess } from 'hooks';

export const ResetModal = ({ resetToken, reset, register }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const backError = FindError('RESET_PASSWORD_REQUEST');
    const loader = FindLoad('RESET_PASSWORD_REQUEST');
    const success = FindSuccess('RESET_PASSWORD_REQUEST');

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        setError('');
        backError?.error && dispatch(httpRequestsOnErrorsActions.removeError('RESET_PASSWORD_REQUEST'));
    };

    const resetPassword = () => {
        if (inputs.newPassword && inputs.confirmPassword) {
            const passwords = {
                newPassword: inputs.newPassword,
                confirmation: inputs.confirmPassword,
                token: resetToken,
                type: register ? 'register' : 'reset',
            };
            if (inputs.newPassword === inputs.confirmPassword) {
                dispatch(authActions.resetPassword(passwords));
            } else {
                setError('Password and confirm password does not match');
            }
        } else {
            if (!inputs.newPassword) {
                setError('New password is not field');
            } else if (!inputs.confirmPassword) {
                setError('Confirm password is not field');
            }
        }
    };

    const handleCheck = (bool, name) => {
        if (bool === true) {
            setError(name);
        } else {
            setError('');
        }
    };

    const errorNewPassword =
        error === 'newPasswordNotValid' ? 'Not valid password' : error === 'New password is not field' ? 'New password is not field' : '';
    const errorConfirmPassword =
        error === 'confirmationNotValid'
            ? 'Not valid password'
            : error === 'Password and confirm password does not match'
            ? 'Password and confirm password does not match'
            : error === 'Confirm password is not field'
            ? 'Confirm password is not field'
            : '';
    return (
        <div className="modal-styles">
            <p className="login-title">{register === true ? 'Welcome Starfish' : 'Reset Password'}</p>
            <span className="login-text">
                {register === true ? (
                    <span>
                        You have been invited to join <span style={{ color: '#6A6D84', fontWeight: '600' }}>Starfish</span> as a an admin.
                        <br />
                        Please create password for your account
                    </span>
                ) : (
                    <span>
                        {' '}
                        Enter your new password.
                        <br />
                        Use at least 8 characters, 1 upper case and 1 digit.
                    </span>
                )}
            </span>

            <PasswordInput
                // styles={{marginBottom:'20px'}}
                handleChangePassword={handleChange}
                validator={PasswordValidator}
                sendBoolean={(bool) => handleCheck(bool, 'newPasswordNotValid')}
                type={'password'}
                name={'newPassword'}
                disabled={false}
                value={inputs.newPassword}
                onChange={handleChange}
                typeError={errorNewPassword}
                placeholder={register ? 'Password' : 'New Password'}
            />

            <PasswordInput
                validator={PasswordValidator}
                // styles={{marginBottom: '20px'}}
                sendBoolean={(bool) => handleCheck(bool, 'confirmationNotValid')}
                handleChangePassword={handleChange}
                type={'password'}
                name={'confirmPassword'}
                disabled={false}
                value={inputs.confirmPassword}
                onChange={handleChange}
                typeError={errorConfirmPassword}
                placeholder={register ? 'Confirm Password' : 'Confirm New Password'}
            />

            {backError?.error && (
                <ErrMessage style={{ position: 'relative' }} textStyle={{ position: 'relative' }} text={backError?.error} />
            )}
            <SignIn
                loader={!!loader?.length}
                handleClick={resetPassword}
                width={'400px'}
                text={register ? 'Complete Registration' : 'Reset Password'}
            />
        </div>
    );
};
