
/** Create Amazon */
export const CREATE_WALMART = 'CREATE_WALMART';
export const CREATE_WALMART_SUCCESS = 'CREATE_WALMART_SUCCESS';

/** Add, Edit Amazon product */
export const ADD_WALMART_PRODUCT = 'ADD_WALMART_PRODUCT'
export const ADD_WALMART_PRODUCT_SUCCESS = 'ADD_WALMART_PRODUCT_SUCCESS'

export const EDIT_WALMART_PRODUCT = 'EDIT_WALMART_PRODUCT'
export const EDIT_WALMART_PRODUCT_SUCCESS = 'EDIT_WALMART_PRODUCT_SUCCESS'

/** Get Amazon */
export const GET_WALMART = 'GET_WALMART';
export const GET_WALMART_SUCCESS = 'GET_WALMART_SUCCESS';

/** Delete Amazon Product */
export const DELETE_WALMART_PRODUCT = 'DELETE_WALMART_PRODUCT';
export const DELETE_WALMART_PRODUCT_SUCCESS = 'DELETE_WALMART_PRODUCT_SUCCESS';

