import {GET_EBAY_SUCCESS} from "./ebay.types";

const initialState = {
    ebayProducts: null,
};

export const ebayReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Ebay Products */

        case GET_EBAY_SUCCESS:
            return {
                ...state,
                ebayProducts: action.payload,
            }

        /** End */

        default:
            return state;
    }
};
