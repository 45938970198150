import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {CloseButton, MinLoader, ErrMessage} from 'components';
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, shopifyActions} from 'store';
import {FindError, FindLoad, FindSuccess} from 'hooks';
import {SavePage, useModal} from 'utils';
import {TEXT_FIELD_STYLES_SMALL} from 'fragments';
import {TextField} from '@mui/material';
import {Images, Images as SVG} from 'assets/images';

export const AddProductModal = ({product}) => {
    const [erCodes, setErCodes] = useState([]);
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const {close} = useModal();
    const history = useHistory();
    const info = history?.location?.state;

    const ACTION_TYPE = product ? 'EDIT_SHOPIFY_PRODUCT' : 'ADD_SHOPIFY_PRODUCT';
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    useEffect(() => {
        if (product?.id) {
            setInputs({
                sku: product?.sku,
            })
            setErCodes(product?.erplyProducts)
        }
    }, [product]);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            SavePage(history, info, {...info});
        }
    }, [success]);


    const onSubmit = () => {
        const lastAdded = erCodes[erCodes?.length - 1];

        if (inputs?.sku && erCodes?.length > 0 && lastAdded?.erplyProductId && lastAdded?.erplyCode && lastAdded?.multiplier) {
            const sendInfo = {
                'sku': inputs?.sku,
                'erplyProducts': [...erCodes],
            }
            if (product?.id) {
                dispatch(shopifyActions.editShopifyProduct(sendInfo, product.id));
            } else {
                dispatch(shopifyActions.addShopifyProduct(sendInfo));
            }
        } else {
            setError(
                !inputs?.sku ? 'sku' :
                    erCodes?.length === 0 ? 'noErCodes' :
                        !lastAdded?.erplyProductId ? 'lastAdded' :
                            !lastAdded?.erplyCode ? 'lastAdded' :
                                !lastAdded?.multiplier ? 'lastAdded' :
                                    '',
            );
        }
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({...prevState, [e.target.name]: e.target.value}));
        e.target.name === error && setError('');
        backError?.error && dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    };

    const handlePush = () => {
        const lastAdded = erCodes[erCodes?.length - 1];
        if (lastAdded) {
            if (lastAdded?.erplyProductId && lastAdded?.erplyCode && lastAdded?.multiplier) {
                setErCodes([...erCodes, {
                    'erplyProductId': '',
                    'erplyCode': '',
                    'multiplier': '',
                }]);
                setError('');
            } else {
                setError('lastAdded');
            }
        } else {
            setErCodes([...erCodes, {
                'erplyProductId': '',
                'erplyCode': '',
                'multiplier': '',
            }]);
            setError('');
        }
    };


    const handleDelete = (item, key) => {
        setError('');
        const newList = erCodes?.filter((i, j) => j !== key);
        setErCodes(newList);
    };

    const handleChangeCodes = (event, key) => {
        const allObj = [...erCodes];
        if (event.target.type === 'number' && event.target.value >= 0) {
            allObj[key][event.target.name] = event.target.value
        } else if (event.target.type !== 'number') {
            allObj[key][event.target.name] = event.target.value
        }
        setErCodes(allObj);
    };

    return (
        <div className='add-modal-fragment' style={{maxHeight: '587px', height: 'auto', overflow: 'auto'}}>
            <div className='modal-header'>
                <CloseButton handleClose={close}/>
            </div>
            <div className='modal-body'>
                <p className='add-modal-title'>Add product code</p>
                <p className='add-modal-subtitle'>*All product codes are unique.</p>
                <div>

                    <TextField
                        name={'sku'}
                        onChange={handleChange}
                        value={inputs?.sku}
                        label={'Set Shopify SKU'}
                        size='medium'
                        sx={TEXT_FIELD_STYLES_SMALL}
                        style={{marginBottom: 0}}
                        error={error === 'sku' || backError?.error === 'SKU must be unique'}
                    />
                    <div>
                        <ErrMessage
                            text={error === 'sku' ? 'Input is not field' : backError?.error === 'SKU must be unique' ? 'SKU must be unique' : ''}
                            style={{position: 'relative', margin: 0, height: '26px'}}
                        />
                    </div>
                    {erCodes?.map((i, j) => (
                        <div className={'row-for-erply'} key={j}>
                            <div>
                                <TextField
                                    disabled={product ? false : erCodes?.length - 1 !== j}
                                    name={'erplyProductId'}
                                    required={true}
                                    onChange={(e) => handleChangeCodes(e, j)}
                                    value={i?.erplyProductId}
                                    label={'Set Erply Product ID'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                    error={error === 'lastAdded' && erCodes?.length - 1 === j}
                                />
                                <TextField
                                    disabled={product ? false : erCodes?.length - 1 !== j}
                                    name={'erplyCode'}
                                    required={true}
                                    onChange={(e) => handleChangeCodes(e, j)}
                                    value={i?.erplyCode}
                                    label={'Set Erply Code'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                    error={error === 'lastAdded' && erCodes?.length - 1 === j}
                                />
                                <TextField
                                    disabled={product ? false : erCodes?.length - 1 !== j}
                                    name={'multiplier'}
                                    required={true}
                                    onChange={(e) => handleChangeCodes(e, j)}
                                    value={i?.multiplier}
                                    type='number'
                                    label={'Set Multiplier'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                    error={error === 'lastAdded' && erCodes?.length - 1 === j}
                                    onWheel={() => document.activeElement.blur()}
                                    InputProps={{inputProps: {min: 0}}}
                                />
                            </div>

                            <div style={{marginLeft: 16}}>
                                <button onClick={() => handleDelete(i, j)} className='close-erply-code'>
                                    <img src={SVG.Close} alt='icon'/>
                                </button>
                            </div>
                        </div>
                    ))}

                    <ErrMessage
                        text={error === 'noErCodes' && 'Please Add Erply Product'}
                        style={{position: 'relative', margin: 0, height: '24px'}}
                    />

                    <div onClick={handlePush} className={'add-erply-code'}
                         style={erCodes?.length > 0 ? {marginTop: '24px'} : {}}>
                        <button>
                            <img src={Images.AddPrimary} alt={'icon'}/>
                            Add Erply Product
                        </button>
                    </div>


                    <button className={'add-modal-button'} onClick={onSubmit}>
                        {loader?.length ?
                            <MinLoader color='white'/>
                            :
                            product?.id ? 'Save' : 'Add'
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};
