import { Colors } from "utils";

export const TEXT_FIELD_STYLES_SMALL = {
    fontSize: '10px',
    width: '100%',
    marginBottom: '16px',
    '& label.Mui-focused': {
        color: Colors.borderColor,
    },
    '& label': {
        fontSize: '16px',
        '@media (min-width: 1279px)': {
            fontSize: '14px',
        },
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Colors.borderColor,
    },
    '& .MuiFilledInput-underline:after': {
        borderBottomColor: Colors.borderColor,
    },
    '& .MuiOutlinedInput-root': {
        height: '48px',
        '&.Mui-focused fieldset': {
            borderColor: Colors.borderColor,
        },
    },
    '& .MuiFormHelperText-root': {
        fontSize: '10px',
    },
};
export const TEXT_FIELD_ERROR_STYLES_SMALL = {
    fontSize: '10px',
    width: '100%',
    marginBottom: '16px',
    '& label.Mui-focused': {
        color: Colors.borderRedColor,
    },
    '& label': {
        fontSize: '16px',
        '@media (min-width: 1279px)': {
            fontSize: '14px',
        },
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Colors.borderRedColor,
    },
    '& .MuiFilledInput-underline:after': {
        borderBottomColor: Colors.borderRedColor,
    },
    '& .MuiOutlinedInput-root': {
        height: '48px',
        '&.Mui-focused fieldset': {
            borderColor: Colors.borderRedColor,
        },
    },
    '& .MuiFormHelperText-root': {
        fontSize: '10px',
    },
};

export const SELECT_FIELD_STYLES = {
    fontSize: '16px',
    width: '100%',
    marginBottom: '16px',
    // '.MuiOutlinedInput-notchedOutline': {
    //     borderColor: 'rgba(228, 219, 233, 0.25)',
    // },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.main,
    },

    // '&:hover .MuiOutlinedInput-notchedOutline': {
    //     borderColor: Colors.main,
    // },

    '.MuiSvgIcon-root ': {
        fill: "white !important",
    },


    '& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root':{
        height: '48px',
    },
    '& label.Mui-focused': {
        color: Colors.borderColor,
    },
    '& label': {
        fontSize: '16px',
        color: Colors.borderColor,
        '@media (min-width: 1279px)': {
            fontSize: '16px',
        },
    },
    '& select:befor': {
        borderColor: Colors.borderColor
    },
    '& .MuiInputLabel-formControl': {
        color: Colors.borderColor,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Colors.borderColor,
    },
    '& .MuiFilledInput-underline:after': {
        borderBottomColor: Colors.borderColor,
    },
    '& .MuiOutlinedInput-root': {
        height: '48px',
        fontSize: '16px',
        '&.Mui-focused fieldset': {
            borderColor: Colors.borderColor,
        },
    },
    '& .MuiSelect-select': {
        padding: '14px',
        fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        // borderColor: Colors.borderColor,
    },
}

export const AUTHENTICATION_TEXT_FIELD_STYLES_SMALL = {
    fontSize: '10px',
    width: '100%',
    marginBottom: '16px',
    '& label.Mui-focused': {
        color: Colors.darkMain,
    },
    '& label': {
        fontSize: '16px',
        '@media (min-width: 1279px)': {
            fontSize: '14px',
        },
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Colors.darkMain,
    },
    '& .MuiFilledInput-underline:after': {
        borderBottomColor: Colors.darkMain,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: Colors.darkMain,
            // border: `2px solid ${Colors.darkMain}`
        },
        height: '48px',
        borderColor: Colors.darkMain,
        '&.Mui-focused fieldset': {
            borderColor: Colors.darkMain,
        },
    },
    '& .MuiFormHelperText-root': {
        fontSize: '10px',
    },
};