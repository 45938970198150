import React from 'react';
import {Images} from 'assets/images';

export const LeftBarImages = ({item, linkInfo}) => {
    return (
        <img
            src={
                item.icon === 'Users'
                    ? linkInfo === item.path
                        ? Images.AddUserFill
                        : linkInfo.slice(0, 5) === item.path.slice(0, 5)
                            ? Images.AddUserFill
                            : Images.AddUser
                    : item.icon === 'Erply'
                        ? linkInfo === item.path
                            ? Images.ErplyFill
                            : linkInfo.slice(0, 5) === item.path.slice(0, 5)
                                ? Images.ErplyFill
                                : Images.Erply
                        : item.icon === 'Amazon'
                            ? linkInfo === item.path
                                ? Images.AmazonFill
                                : linkInfo.slice(0, 5) === item.path.slice(0, 5)
                                    ? Images.AmazonFill
                                    : Images.Amazon
                            : item.icon === 'Walmart'
                                ? linkInfo === item.path
                                    ? Images.WalmartFill
                                    : linkInfo.slice(0, 5) === item.path.slice(0, 5)
                                        ? Images.WalmartFill
                                        : Images.Walmart
                                : item.icon === 'Ebay'
                                    ? linkInfo === item.path
                                        ? Images.EbayFill
                                        : linkInfo.slice(0, 5) === item.path.slice(0, 5)
                                            ? Images.Ebay
                                            : Images.Ebay
                                    : item.icon === 'Carecraft'
                                        ? linkInfo === item.path
                                            ? Images.CareCraftFill
                                            : linkInfo.slice(0, 5) === item.path.slice(0, 5)
                                                ? Images.CareCraft
                                                : Images.CareCraft
                                        : item.icon === 'Shopify'
                                            ? linkInfo === item.path
                                                ? Images.ShopifyFill
                                                : linkInfo.slice(0, 5) === item.path.slice(0, 5)
                                                    ? Images.Shopify
                                                    : Images.Shopify
                                            : ''
            }

            alt={'icons'}
            style={{
                width: '25px',
                height: '25px',
            }}
        />
    );
};
