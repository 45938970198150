import {Images} from "assets/images";

export const AddInviteButton = ({text, handleClick}) =>{
    return(
        <button className='add-invite-button' onClick={handleClick}>
            <img src={Images.Add} alt='icon'/>
            <p className='add-invite-button-text'>
              {text}
            </p>
        </button>
    )
}