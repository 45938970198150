import axios from "axios";

export const authService = {

    /** Create, Edit Walmart Product */
    createWalmartService: ( body ) => axios.post(`/user`, body, { auth:true } ),

    addWalmartProductService: ( body ) => axios.post(`/user`, body, { auth:true } ),

    editWalmartProductService: ( body, id ) => axios.patch(`/user/${id}`, body, { auth:true } ),

    getWalmartService: ( ) => axios.get(`/walmart/product`, { auth:true } ),

    deleteWalmartProductService: ( body, id ) => axios.patch(`/user/${id}`, body, { auth:true } ),
    /** End */

};
