import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MessageMiniScreen } from 'components';
import { Images } from 'assets/images';
import { Logo } from 'components/screens/logo';
import { CopyRight, LoginModal } from './fragments';
import { ForgotModal } from './fragments/forgotModal';
import { ResetModal } from './fragments/resetModal';
import { MessageScreen } from './fragments/messageScreen';

export const Login = () => {
    const history = useHistory();
    const { resetSuccess, closeResetSuccess, httpOnSuccess } =
        useSelector((state) => ({
            httpOnSuccess: state.httpOnSuccess,
            resetSuccess: state.auth.resetSuccess,
            closeResetSuccess: state.auth.closeResetSuccess,
        }));
    const [forgot, setForgot] = useState(false);
    const [resetToken, setResetToken] = useState('');
    const [registerToken, setRegisterToken] = useState('');
    const resetLink = httpOnSuccess.length && httpOnSuccess[0].type === 'GET_RECOVERY_LINK';
    const token = resetToken ? resetToken : registerToken ? registerToken : '';
    const link = window.location.search;

    const handleForgot = () => {
        setForgot(true);
    };

    useEffect(() => {
        if (link.search('resetToken=') !== -1) {
            const pos = link.slice(link.search('resetToken='));
            const resetToken = pos.substring(11);
            setResetToken(resetToken);
        }
        if (link.search('token=') !== -1) {
            const pos = link.slice(link.search('token='));
            const registerToken = pos.substring(6);
            setRegisterToken(registerToken);
        }
    }, []);

    return (
        <div style={{ backgroundImage: `url( ${Images.LoginBackground} )` }} className='login-page'>
            <div className='login-head'>
                <div onClick={() => history.push('/')}>
                    <Logo />
                </div>
            </div>
            <div className='login-body'>
                <div className='about-text'>YOUR TRUSTED<br />MANAGEMENT<br />HELPER</div>
                <div>
                    {resetLink === true ? (
                        <MessageScreen type={resetLink} />
                    ) : closeResetSuccess !== 'close' && token ? (
                        <ResetModal
                            reset={!!resetToken}
                            register={!!registerToken}
                            resetToken={token} />
                    ) : forgot === false ? (
                        <LoginModal handleForgot={handleForgot} />
                    ) : (
                        <ForgotModal />
                    )}
                    {resetSuccess === true && (
                        <MessageMiniScreen text={'Reset password success'} />
                    )}
                </div>
            </div>
            <div className='copy-right-wrapper'>
                <CopyRight />
            </div>
        </div>
    );
};