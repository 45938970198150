import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { CloseButton, SettingsButton } from 'components';
import { Authentication } from './authentication';
import { FindError, FindSuccess } from 'hooks';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, amazonActions } from 'store';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

export const LeftDrawer = ({ type, statusType, setUp }) => {
    /**
     * LeftDrawer
     */

    // Hooks.
    const dispatch = useDispatch();
    const backError = FindError(statusType);
    const success = FindSuccess('PATCH_AMAZON_SETUP');
    const [state, setState] = useState({ right: false });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };

    const list = () => (
        <div className="list-wrapper">
            <div className="list-wrapper-head ">
                <CloseButton handleClose={toggleDrawer('right', false)} />
            </div>
            <div className="list-wrapper-body">
                <p className="list-title ">Authentication Credentials</p>
                <Authentication handleClose={toggleDrawer('right', false)} type={type} setUp={setUp} statusType={statusType} />
            </div>
        </div>
    );

    useEffect(() => {
        if (success) {
            setState({ right: false });
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success?.type));
        }
    }, [success]);

    useEffect(() => {
        dispatch(amazonActions.getAmazonSetup());
    }, []);

    return (
        <Fragment key={'right'}>
            <SettingsButton text={'Settings'} handleClick={toggleDrawer('right', true)} />
            <SwipeableDrawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}>
                {list()}
            </SwipeableDrawer>
        </Fragment>
    );
};
