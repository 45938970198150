
export const ErrMessage = ({ text, style, textStyle }) => {
  return (
    <div
      style={{ ...style }}
      className={'err-message-position'}
    >
      <span className='err-message-text'>{text}</span>
    </div>
  );
};
