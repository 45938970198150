import axios from "axios";

export const authService = {

    /** Create, Edit User */
    createUserService: ( body ) => axios.post(`/user`, body, { auth:true } ),

    editUserService: ( body, id ) => axios.patch(`/user/${id}`, body, { auth:true } ),
    /** End */

    /** Get Users */
    getUsersService: ( info ) => axios.get(`/user`, {auth:true, params:{ ...info }} ),

    getUserByIdService: ( body, id ) => axios.get(`/user/${id}`, body, { auth:true } ),
    /** End */

    /** Invite User */
    inviteUserService: ( body ) => axios.post(`/user/invite`, body, { auth:true } ),
    /** End */

};
