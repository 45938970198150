import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/globalColors';

export const tableStyle = makeStyles(() => ({
    tableWrapper: {
        height: '100%',
        minHeight: '80vh',
        width: '100%',
        boxShadow: '0px 0px 12px #0052E01A',
        borderRadius: '8px',
        marginTop: '25px',
        marginBottom: '24px',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
        },
        '& .MuiAccordionSummary-content':{
            margin:'0',
        }
    },
    root: {
        width: "100%",
        "& .MuiExpansionPanelSummary-root": {
            userSelect: "auto"
        }
    },

    tableContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',

        height: '110vh',
        '@media (min-width: 1919px)': {
            height: '85vh',
        },
    },

    tableHead: {
        background: Colors.BackgroundPrimary,
        boxShadow: 'none',
        borderRadius: '8px 0px 0px 0px',
        height: '50px',
        fontSize: '16px',
        fontWeight: '600',
        position: 'relative',
        zIndex: '10',

        '& .MuiTableCell-sizeSmall': {
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '19px',
            color: Colors.TextSecondary,
            padding: '8px 16px 0 16px',
            // "@media (min-width: 1920px)": {
            //   padding: "17px 24px 17px 24px",
            // },
        },
        '& .MuiTableCell-stickyHeader': {
            background: Colors.BackgroundPrimary,
            height: '50px',
            fontSize: '16px',
            fontWeight: '600',
        },
    },

    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#EAF2FF 0% 0% no-repeat padding-box',
        },
        height: '50px',
        '& .makeStyles-membersTableWrapper-26 .MuiTableContainer-root': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            // boxShadow: "0px 8px 12px #0052E01A",
        },
        '& .MuiTableCell-sizeSmall': {
            fontSize: '14px',
            lineHeight: '22px',
            color: Colors.TextPrimary,
            padding: '16px',
            // "@media (min-width: 1920px)": {
            //   padding: "16px 32px 16px 32px",
            // },
        },

        '& .MuiTableCell-sizeSmall:last-child': {
            paddingTop: '11px',
            fontSize: '16px',
            lineHeight: '30px',
            color: '#545F7E',
        },

        '& MuiSwitch-track': {
            borderRadius: '12px',
            background: 'lightgray',
        },

        '& .MuiSwitch-colorPrimary.Mui-checked': {
            color: 'white',
        },

        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
            background: '#387DFF',
            borderRadius: '12px',
            opacity: '1',
        },
    },

    selectType: {
        width: '400px',
        background: 'white',
        marginBottom: '20px',

        '& .MuiSelect-outlined.MuiSelect-outlined': {
            background: 'transparent',
        },
        '& .MuiSelect-icon': {
            color: Colors.ThemeBlue,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: '40px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-7px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
    },

    PaginationWrapper: {
        width: '100%',
        margin: '21px 0 ',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        '@media (min-width: 1919px)': {
            padding: '0 32px',
        },

        '& .MuiPaginationItem-textPrimary.Mui-selected': {
            background: Colors.BackgroundByzantine,
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
        },

        '& .MuiPaginationItem-page': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '0.5px solid #DDE3F0',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
        },

        '& .MuiPaginationItem-outlined': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #DDE3F0',
            borderRadius: '4px',
            textAlign: 'center',
            fontWeight: '600',
            fontSize: '14px',
            color: '#545F7E',
        },

        '& .MuiPaginationItem-page.Mui-selected:hover': {
            background: '#387DFF 0% 0% no-repeat padding-box',
            color: 'white',
        },
    },
    showCountText: {
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.TextLight,
    },
}));
