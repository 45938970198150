import {
    CREATE_ERPLY,
    EDIT_ERPLY,
    GET_ERPLY,
    GET_ERPLY_SETUP,
    CREATE_WAREHOUSE,
    EDIT_WAREHOUSE,
    DELETE_WAREHOUSE,
    GET_WAREHOUSE,
} from './erply.types';

/** Create, Edit Erply */

export const createErply = (body) => {
    return {
        type: CREATE_ERPLY,
        payload: { body },
    };
};

export const editErply = (body) => {
    return {
        type: EDIT_ERPLY,
        payload: { body },
    };
};

export const getErply = () => {
    return {
        type: GET_ERPLY,
    };
};

export const getErplySetup = () => {
    return {
        type: GET_ERPLY_SETUP,
    };
};

/** End  */

/** Erply Warehouse  */

export const getWarehouse = () => {
    return {
        type: GET_WAREHOUSE,
    };
};

export const createWareHouse = (body) => {
    return {
        type: CREATE_WAREHOUSE,
        payload: { body },
    };
};

export const editWareHouse = (body, warehouseId) => {
    return {
        type: EDIT_WAREHOUSE,
        payload: { body, warehouseId },
    };
};

export const deleteWareHouse = (warehouseId) => {
    return {
        type: DELETE_WAREHOUSE,
        payload: { warehouseId },
    };
};

/** End  */