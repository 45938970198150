import React, { useEffect, Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TextField, Grid } from '@mui/material';
import { Images } from 'assets/images';
import { AUTHENTICATION_TEXT_FIELD_STYLES_SMALL } from 'fragments/modals/constants';
import { ListCard } from './listCard';
import { amazonActions } from 'store';
import { FindLoad, FindSuccess } from 'hooks';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';
import { Loader, PaginationItem, TableNoInfo } from 'components';
import { MinLoader } from 'components/loaders/miniLoader';
import { CustomDeleteModal } from 'fragments';
import { EditTypeModal } from './core';
import { useModal } from 'utils';

const ADD_ACTION_TYPE = 'ADD_AMAZON_TYPE';
const ACTION_GET_TYPE = 'GET_AMAZON_TYPE';

export const AmazonList = ({ amazonType }) => {
    const dispatch = useDispatch();
    const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
        mode: 'onBlur',
    });
    const [page, setPage] = useState(1);
    const load = FindLoad(ACTION_GET_TYPE);
    const addLoad = FindLoad(ADD_ACTION_TYPE);
    const addSuccess = FindSuccess(ADD_ACTION_TYPE);
    const { open } = useModal();

    useEffect(() => {
        dispatch(amazonActions.getAmazonType({ skip: 0, limit: 10 }));
    }, []);


    useEffect(() => {
        if (addSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ADD_ACTION_TYPE));
            reset({
                name: '',
            });
            handleGet();
        }
    }, [addSuccess]);

    useEffect(() => {
        return () => dispatch(httpRequestsOnSuccessActions.removeSuccess(ADD_ACTION_TYPE));
    }, []);

    const changePage = (number) => {
        setPage(number);
        handleGet(number);
    };

    const onSubmit = (data) => {
        dispatch(amazonActions.addAmazonType(data));
    };

    const handleGet = (num) => {
        const number = num ? num : page;
        dispatch(amazonActions.getAmazonType({ skip: number - 1 + '0', limit: 10 }, 'noLoad'));
    };

    const openDeleteType = (item) => {
        dispatch(amazonActions.deleteAmazonType(item?.id));
    };

    return (

        <Fragment>
            <div className='list-amazon'>
                <div className='input-box'>
                    <form className='boxFlex' onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Controller
                                    name={'name'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            {...register('name')}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Type'}
                                            size='medium'
                                            className='fieldType'
                                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <button
                                    style={{ width: '180px', marginLeft: 10 }}
                                    className='add-invite-button'
                                    type='submit'
                                    onClick={onSubmit}
                                >
                                    {addLoad ? (
                                        <div>
                                            <MinLoader color='white' position={'relative'} margin={'-2px'} />
                                        </div>
                                    ) : (
                                        <>
                                            <img src={Images.Add} alt='icon' />
                                            <p className='add-invite-button-text'>Add Type</p>
                                        </>
                                    )}
                                </button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                {load ? (
                    <Loader />
                ) : (
                    <div className='type-list'>
                        {amazonType?.productTypes ? (
                            <>
                                {amazonType?.productTypes?.map((item) =>
                                    <div key={item.id}>
                                        <ListCard
                                            openEditType={() => open(
                                                <EditTypeModal
                                                    info={item}
                                                    callBack={handleGet}
                                                />,
                                            )}
                                            openDeleteType={() => open(
                                                <CustomDeleteModal
                                                    handleDelete={() => openDeleteType(item)}
                                                    actionType={'DELETE_AMAZON_TYPE'}
                                                    callBack={handleGet}
                                                />)
                                            }
                                            info={item}
                                        />
                                    </div>,
                                )}
                                <>
                                    {amazonType?.count > 0 ?
                                        <PaginationItem
                                            listLength={amazonType?.productTypes?.length}
                                            page={page}
                                            handleReturn={(number) => changePage(number)}
                                            count={amazonType?.count}
                                        />
                                        : ''
                                    }
                                </>
                            </>
                        ) : (
                            <TableNoInfo text={'No Product Type Yet'} />
                        )}
                    </div>

                )}
            </div>
        </Fragment>
    );
};