import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FindLoad } from 'hooks';
import { CustomDeleteModal } from 'fragments';
import { SendPageSave, SavePage, useModal } from 'utils';
import { SearchAndFilter, PaginationItem, Loader, TableNoInfo } from 'components';
import { tableStyle } from 'components/table/styles';
import { carecraftActions } from 'store';
import { Images } from 'assets/images';
import { AddProductMap } from './modal';

export const CarecraftMap = ({
                                 head,
                                 list,
                                 loadingType,
                                 margin,
                                 status,
                                 listCount,
                                 handleSearchReq
                             }) => {
    const dispatch = useDispatch();
    const classes = tableStyle();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const info = history?.location?.state;
    const [selectedName, setSelectedName] = useState('');
    const loader = FindLoad(loadingType ? loadingType : '');
    const [listInfo, setListInfo] = useState('');
    const { open } = useModal();

    useEffect(() => {
        if (list) {
            setListInfo(list);
        }
    }, [list]);

    useEffect(() => {
        const anotherInfo = { ...info };
        info?.status ? (anotherInfo.status = info.status) : status ? (anotherInfo.status = status) : delete anotherInfo.status;
        SavePage(history, info, { ...anotherInfo });
    }, []);

    useEffect(() => {
        if (info) {
            info?.page !== page && setPage(info?.page);
            // eslint-disable-next-line no-unused-expressions
            info?.sortType ? setSelectedName(info?.sortType) : '';
        }
    }, [info]);


    const setName = (name) => {
        const anotherInfo = { ...info };
        if (name) {
            anotherInfo.sortType = name;
        } else {
            delete anotherInfo.sortType;
        }
        SavePage(history, info, { ...anotherInfo });
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    const handleChange = (e, type, nameRow) => {
        if (e.target.name === 'searchRequest') {
            handleSearchReq(e, nameRow)
        }else {
            if (e.target.name) {
                if (type !== 'notChange') {
                    const info = list.filter((el) =>
                        type === 'date'
                            ? el[e.target.name] &&
                            moment(el[e.target.name]).format('DD/MM/YYYY').toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                            : el[e.target.name] && el[e.target.name].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1,
                    );
                    setListInfo(info);
                }
            }
        }
    };

    const handleOpenEdit = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open( <AddProductMap product={item} />);
    };

    const handleOpenDel = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open( <CustomDeleteModal
            handleDelete={() => dispatch(carecraftActions.deleteCarecraftMap(item?.id))}
            actionType={'DELETE_CARECRAFT_MAP'} />,
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={margin ? { margin: 0 } : {}} className={classes.tableWrapper}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {head ? head?.map((i, j) => (
                                        <TableCell key={j}>
                                            <SearchAndFilter
                                                setSelectedFilter={() => {
                                                }}
                                                selectedName={selectedName}
                                                setSelectedName={setName}
                                                handleSearch={handleChange}
                                                name={i.name}
                                                width={i.width}
                                                itemKey={i.key}
                                                title={i.title}
                                                custom={i.custom}
                                                top={i.top}
                                                dateType={i?.type}
                                                notSearch={i.notSearch}
                                                smallSize={i?.smallSize}
                                                bigSize={i?.bigSize}
                                                iconType={i?.icon}
                                                nameRow={i?.nameRow}
                                            />
                                        </TableCell>
                                    ))
                                    : ''}
                            </TableRow>
                        </TableHead>
                    </Table>
                    {loader?.length ?
                        <Loader />
                        :
                        <tbody>
                        <Box className='acordion-container' style={{ height: '70vh', overflow: 'auto' }}>
                            {listInfo?.length ? (
                                listInfo?.map((item, j) => (
                                    <React.Fragment key={j}>
                                        <Accordion
                                            style={{ borderBottom: '1px solid #CED8E5' }}
                                            key={j}
                                            sx={{ boxShadow: 'none' }}>
                                            <AccordionSummary

                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls='panel-content'
                                                id='panel-header'
                                            >
                                                <Box className='accordion-tr'>
                                                    <Box style={{ width: '50%' }} className='accordion-th-big'>
                                                        {item?.sku}
                                                    </Box>
                                                    <Box style={{ width: '50%' }} className='accordion-th-big'>
                                                        <div className='del-edit-wrapper'>
                                                            <button
                                                                onClick={(e) => handleOpenEdit(e, item)}
                                                            >
                                                                <img src={Images.Edit} alt={'icon'} />
                                                                <p className='edit-text'>Edit</p>
                                                            </button>
                                                            <button
                                                                onClick={(e) => handleOpenDel(e, item)}
                                                            >
                                                                <img src={Images.Remove} alt={'icon'} />
                                                                <p className='delete-text'>Delete</p>
                                                            </button>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box className='accordion-body'>
                                                    <div className='table'>
                                                        <div className='table-header'>
                                                            <Box style={{ width: '50%' }} className='table-th th-sku'>Erply
                                                                Code</Box>
                                                            <Box style={{ width: '50%' }}
                                                                 className='table-th th-quantity'>Multiplier</Box>
                                                        </div>
                                                        <div className='table-body'>
                                                            {item?.erplyProducts?.map((info, index) => (
                                                                <Box className='body-list' sx={{ marginBottom: '4px' }}
                                                                     key={index}>
                                                                    <Box style={{ width: '50%' }}
                                                                         className='body-th th-sku'>{info?.erplyCode}</Box>
                                                                    <Box style={{ width: '50%' }}
                                                                         className='body-th th-quantity'>{info?.multiplier}</Box>
                                                                </Box>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </React.Fragment>
                                ))
                            ) : (
                                <>{loader?.length ? <Loader /> : <TableNoInfo text={`No Product Map Yet`} />}</>
                            )}
                        </Box>
                        </tbody>
                    }

                    {listCount > 0 ? (
                        <PaginationItem
                            listLength={list?.length}
                            page={page}
                            handleReturn={(number) => changePage(number)}
                            count={listCount}
                        />
                    ) : (
                        ''
                    )}
                </TableContainer>
            </div>
        </div>
    );
};
