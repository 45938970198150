import axios from 'axios';

export const authService = {
    /** Create, Edit Erply */
    createErplyService: (body) => axios.post(`/erply/setup`, body, { auth: true }),

    editErplyService: (body) => axios.patch(`/erply/setup`, body, { auth: true }),

    getErplyService: () => axios.get(`/erply/setup`, { auth: true }),

    /** End */

    getErplySetup: () => axios.get(`/erply/setup`, { auth: true }),

    /**  Werkhouse */

    getWareHouseService: () => axios.get(`/erply/setup/warehouse`, { auth: true }),

    createWareHouse: (body) => axios.post(`/erply/setup/warehouse`, body, { auth: true }),

    editWareHouse: (body, warehouseId) => axios.patch(`/erply/setup/warehouse/${warehouseId}`, body, { auth: true }),

    deleteWareHouse: (warehouseId) => axios.delete(`/erply/setup/warehouse/${warehouseId}`, { auth: true }),
};
