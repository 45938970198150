import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./ebay.service";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {
  ADD_EBAY_PRODUCT,
  CREATE_EBAY,
  DELETE_EBAY_PRODUCT,
  EDIT_EBAY_PRODUCT,
  GET_EBAY, GET_EBAY_SUCCESS
} from "./ebay.types";

/** Create, Edit Ebay Product */
function* createEbay({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.createEbayService, payload.body );
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* addEbayProduct({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.addEbayProductService, payload.body );
    
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editEbayProduct({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.editEbayProductService, payload.body );
    
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
/** End */

/** Get Ebay */
function* getEbay({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.getEbayService );
    yield put({
      type:GET_EBAY_SUCCESS,
      payload: res.data
    })
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
/** End */

/** Delete Ebay Product */
function* deleteEbayProduct({payload, type}) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( authService.deleteEbayProductService, payload.body );
    
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}
/** End */

export const watchEbay = function* watchEbaySaga() {
  /** Create, Edit Ebay Product */
  yield takeLatest( CREATE_EBAY, createEbay );
  yield takeLatest( ADD_EBAY_PRODUCT, addEbayProduct );
  yield takeLatest( EDIT_EBAY_PRODUCT, editEbayProduct );
  /** End */

  /** Get Ebay */
  yield takeLatest( GET_EBAY, getEbay );
  /** End */

  /** Delete Ebay Product*/
  yield takeLatest( DELETE_EBAY_PRODUCT, deleteEbayProduct );
  /** End */
};
