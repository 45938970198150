import React from 'react';
import { CloseButton, SimpleModal, MinLoader } from 'components';
import { FindLoad } from 'hooks';

export const DeleteModal = ({ handleOpenClose, open, loadType, handleDelete, item }) => {
    /**
     * Delete Modal.
     */

    const load = FindLoad(loadType);

    return (
        <SimpleModal
            handleOpenClose={handleOpenClose}
            openDefault={open}
            content={
                <div className="delete-modal-fragment">
                    <div className="modal-header">
                        <CloseButton handleClose={handleOpenClose} />
                    </div>
                    <div className="modal-body">
                        <p style={{ textAlign: 'center' }} className="add-modal-title">
                            Want to Delete <br /> Product
                            {/*<br />*/}
                            {/**/}
                            {/*{item?.name ? <span>{item?.name}</span> : null}*/}
                            {/*{loadType === 'DELETE_CARECRAFT_PO' ? <>{item?.poNumber}</> : null}*/}
                            {/*<span className="mx-2">{loadType === 'DELETE_AMAZON_TYPE' && 'Type'}</span>*/}
                            ?
                        </p>
                        <div className="delete-modal-buttons">
                            <button onClick={handleDelete} className="delete-button">
                                {load ? <MinLoader color="white" /> : 'Delete'}
                            </button>
                            <button className="cancel-button" onClick={handleOpenClose}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
    );
};
