import {CREATE_USER, EDIT_USER, GET_USERS, GET_USERS_SUCCESS_BY_ID, INVITE_USER} from "./users.types";

/** Create, Edit User */

export const createUser = (body) => {
  return {
    type: CREATE_USER,
    payload: { body }
  }
}

export const editUser = (id, body) => {
  return {
    type: EDIT_USER,
    payload: { id, body }
  }
}

/** End */

/** Get Users */

export const getUsers = ( params ) => {
  return {
    type: GET_USERS,
    payload: { params }
  }
}

export const getUsersById = (adminId) => {
  return {
    type: GET_USERS_SUCCESS_BY_ID,
    payload: { adminId }
  }
}

/** End */

/** Invite User */

export const inviteUser = ( info ) => {
  return {
    type: INVITE_USER,
    payload: { info }
  }
}

/** End */