import {GET_USERS_SUCCESS, GET_USERS_SUCCESS_BY_ID_SUCCESS} from "./users.types";

const initialState = {
    usersList: null,
    userById: null
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Users */

        case GET_USERS_SUCCESS:
            return {
                ...state,
                usersList: action.payload,
            }

        case GET_USERS_SUCCESS_BY_ID_SUCCESS:
            return {
                ...state,
                userById: action.payload,
            }

        /** End */

        default:
            return state;
    }
};
