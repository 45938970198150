
/** CareCraft */
export const GET_CARECRAFT = 'GET_CARECRAFT';

export const CREATE_PO_NUMBER = 'CREATE_PO_NUMBER';
export const GET_CARECRAFT_SUCCESS = 'GET_CARECRAFT_SUCCESS';

export const SENT_CARECRAFT = 'SENT_CARECRAFT';
export const SENT_CARECRAFT_SUCCESS = 'SENT_CARECRAFT_SUCCESS';

export const DELETE_CARECRAFT_PO = 'DELETE_CARECRAFT_PO';

/** End */

/** CareCraft Setup*/
export const GET_CARECRAFT_SETUP = 'GET_CARECRAFT_SETUP';
export const GET_CARECRAFT_SETUP_SUCCESS = 'GET_CARECRAFT_SETUP_SUCCESS';

export const CREATE_CARECRAFT_SETUP = 'CREATE_CARECRAFT_SETUP';
/** End */


/** CareCraft Map*/
export const GET_CARECRAFT_MAP = 'GET_CARECRAFT_MAP';
export const GET_CARECRAFT_MAP_SUCCESS = 'GET_CARECRAFT_MAP_SUCCESS';

export const ADD_CARECRAFT_MAP = 'ADD_CARECRAFT_MAP';

export const EDIT_CARECRAFT_MAP = 'EDIT_CARECRAFT_MAP';
export const EDIT_CARECRAFT_MAP_SUCCESS = 'EDIT_CARECRAFT_MAP_SUCCESS';

export const DELETE_CARECRAFT_MAP = 'DELETE_CARECRAFT_MAP';

/** End */