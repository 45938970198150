import {
    createErply,
    editErply,
    getErply,
    getErplySetup,
    createWareHouse,
    editWareHouse,
    deleteWareHouse,
    getWarehouse,
} from './erply.action';

export { erplyReducer } from './erply.reducer';
export { watchErply } from './erply.saga';

export const erplyActions = {
    /** Create, Edit Erply */
    createErply,
    editErply,
    /** End */

    /** Get Erply */
    getErply,
    getErplySetup,

    /** Erply Warehouse  */
    getWarehouse,
    createWareHouse,
    editWareHouse,
    deleteWareHouse,
};
