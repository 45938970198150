import React, { useState, useEffect } from "react";
import { ChangePassword, myProfileFragment } from "./core";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FindLoad } from "hooks";
import { Images } from "assets/images";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { authActions } from "store";
import { MinLoader } from "../../components";

export const MyProfile = ({ info }) => {
    // Hooks.
    const classes = myProfileFragment()
    const dispatch = useDispatch();
    const [toggleChange, SetToggleChange] = useState(false);
    const loader = FindLoad('LOG_OUT');
    const [url, setUrl] = useState('');

    const uploader = (file) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            localStorage.setItem('image', reader.result)
        });
        reader.readAsDataURL(file);
    };

    const showFormChange = () => {
        SetToggleChange(!toggleChange);
    };

    const handleChangeImg = (e) => {
        uploader(e.target.files[0])
    }

    const handleLogOut = () => {
        dispatch(authActions.logOut());
    };

    useEffect(() => {
        setUrl(localStorage.getItem('recent-image'));
    }, [url]);

    return (
        <div className={classes.wrapper}>
            <div className={classes.avatar}>
                <div className="">
                    {url ? (
                        <img src={url} alt={'icon'} className={classes.avatarImg} />
                    ) : (
                        <AccountCircleIcon className={classes.circleIcon} style={{width:'70px', height:'70px'}}/>
                    )}
                </div>
                <form>
                    {/*<div className={classes.Photo}>*/}
                        {/*<label onChange={handleChangeImg} htmlFor="formId">*/}
                        {/*    <input name="" type="file" id="formId" hidden />*/}
                        {/*    <img src={Images.Photo} />*/}
                        {/*</label>*/}
                    {/*</div>*/}
                </form>
                <div className="text-center">
                    <h4 className={classes.title}>{ `${info?.firstName} ${info?.lastName ? info?.lastName : ''}`}</h4>
                </div>
            </div>
            <div className={classes.userInfo}>
                <div className={classes.userEmail}>
                    <img src={Images.Email} alt="" />
                    <p className={classes.emailTitle}>{info?.email}</p>

                </div>
            </div>
            <div className={classes.changeWrapper}>
                <ChangePassword showFormChange={showFormChange}
                    toggleChange={toggleChange}
                />
            </div>
            <Box>
                <button className='add-modal-button' onClick={handleLogOut}>
                    {
                        loader ?
                            <MinLoader color='white' />
                            :
                            <>
                                <img src={Images.SignOut} alt='' />
                                <span className="">Sign Out</span>
                            </>
                    }
                </button>
            </Box>
        </div>
    )
}