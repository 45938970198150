import { call, put, takeLatest } from 'redux-saga/effects';
import { carecraftService } from './carecraft.service';
import {
    CREATE_PO_NUMBER,
    GET_CARECRAFT,
    GET_CARECRAFT_SUCCESS,
    GET_CARECRAFT_SETUP,
    GET_CARECRAFT_SETUP_SUCCESS,
    CREATE_CARECRAFT_SETUP,
    DELETE_CARECRAFT_PO,
    SENT_CARECRAFT,
    SENT_CARECRAFT_SUCCESS,
    GET_CARECRAFT_MAP,
    ADD_CARECRAFT_MAP,
    EDIT_CARECRAFT_MAP, DELETE_CARECRAFT_MAP, GET_CARECRAFT_MAP_SUCCESS, EDIT_CARECRAFT_MAP_SUCCESS,
} from './carecraft.type';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';

/** CareCraft */
function* getCareCraft({ payload, type }) {
    if(payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(carecraftService.getCareCraft, payload?.params);
        yield put({
            type: GET_CARECRAFT_SUCCESS,
            payload: res.data,
        });
        if (payload?.searchType) {
            yield put(httpRequestsOnLoadActions.removeLoading(payload?.searchType));
        }
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* createPoNumber({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(carecraftService.createPoNumber, payload.body);
        yield put({
            type: GET_CARECRAFT,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* sentCareCraft({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        const res = yield call(carecraftService.sentCareCraft, payload?.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess('SEND_CARECRAFT_SUCCESS'));
        yield put({
            type: SENT_CARECRAFT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteCareCraft({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(carecraftService.deleteCareCraft, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

/** CareCraft Setup*/

function* getCareCraftSetup({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(carecraftService.getCareCraftSetup, payload);
        yield put({
            type: GET_CARECRAFT_SETUP_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* createCareCraftSetup({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(carecraftService.createCareCraftSetup, payload.body);
        yield put({
            type: GET_CARECRAFT_SETUP,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

/** End */

/** CareCraft map */
function* getCareCraftMap({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if(payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(carecraftService.getCareCraftMapService, payload?.params);
        yield put({
            type: GET_CARECRAFT_MAP_SUCCESS,
            payload: res.data,
        });
        if (payload?.searchType) {
            yield put(httpRequestsOnLoadActions.removeLoading(payload?.searchType));
        }
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}


function* addCarecraftMap({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(carecraftService.addCarecraftMapService, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}


function* editCarecraftMap({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(carecraftService.editCarecraftMapService, payload?.id, payload?.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* deleteCareCraftMap({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        yield call(carecraftService.deleteCareCraftMapService, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */


export const watchCarecraft = function* watchCarecraftSaga() {
    /** CareCraft */
    yield takeLatest(GET_CARECRAFT, getCareCraft);
    yield takeLatest(CREATE_PO_NUMBER, createPoNumber);
    yield takeLatest(SENT_CARECRAFT, sentCareCraft);
    yield takeLatest(DELETE_CARECRAFT_PO, deleteCareCraft);
    /** End */

    /** CareCraft Setup*/
    yield takeLatest(GET_CARECRAFT_SETUP, getCareCraftSetup);
    yield takeLatest(CREATE_CARECRAFT_SETUP, createCareCraftSetup);
    /** End */

    /** CareCraft Map*/
    yield takeLatest(GET_CARECRAFT_MAP, getCareCraftMap);
    yield takeLatest(ADD_CARECRAFT_MAP, addCarecraftMap);
    yield takeLatest(EDIT_CARECRAFT_MAP, editCarecraftMap);
    yield takeLatest(DELETE_CARECRAFT_MAP, deleteCareCraftMap);
    /** End */
};
