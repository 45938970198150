import { call, put, takeLatest } from 'redux-saga/effects';
import { shopifyService } from './shopify.service';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import {
    ADD_SHOPIFY_PRODUCT,
    CREATE_SHOPIFY,
    DELETE_SHOPIFY_PRODUCT,
    EDIT_SHOPIFY_PRODUCT,
    GET_SHOPIFY,
    GET_SHOPIFY_ORDERS,
    GET_SHOPIFY_ORDERS_SUCCESS,
    GET_SHOPIFY_SETUP,
    GET_SHOPIFY_SETUP_SUCCESS,
    GET_SHOPIFY_SUCCESS,
    GET_SHOPIFY_TRANSVERSE,
    GET_SHOPIFY_TRANSVERSE_SUCCESS,
    PATCH_SHOPIFY_SETUP,
    POST_SHOPIFY_SETUP,
    GET_ORDER_PRODUCT_MAP_SHOPIFY,
    GET_ORDER_PRODUCT_MAP_SHOPIFY_SUCCESS,
    SYNC_RESYNC_SHOPIFY,
} from './shopify.types';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';

/** Create, Edit SHOPIFY Product */

function* createShopify({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(shopifyService.createShopifyService, payload.body);

        yield put({
            type: GET_SHOPIFY_SUCCESS,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* addShopifyProduct({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(shopifyService.createShopifyService, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* editShopifyProduct({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(shopifyService.editShopifyMap, payload.body, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteShopifyProduct({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(shopifyService.deleteShopifyMap, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getShopify({ type, payload }) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(shopifyService.getShopifyService, payload?.params);
        yield put({
            type: GET_SHOPIFY_SUCCESS,
            payload: res.data,
        });
        if (payload?.searchType) {
            yield put(httpRequestsOnLoadActions.removeLoading(payload?.searchType));
        }
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */


/** Shopify Setup Endpoinds */

function* addShopifySetup({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type))
    try {
        yield call(shopifyService.creatShopifySetup, payload.body,);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put({
            type: GET_SHOPIFY_SETUP,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type))
    }
}

function* changeShopifySetup({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type))
    try {
        const res = yield call(
            shopifyService.editShopifySetup,
            payload.body,
        );
        yield put({
            type: GET_SHOPIFY_SETUP_SUCCESS,
            payload: res.data
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getShopifySetup({ type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(shopifyService.getShopifySetup);
        yield put({
            type: GET_SHOPIFY_SETUP_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** Shopify Orders */
function* getShopifyOrders({ type, payload }) {
    if(payload?.load !== 'load') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(shopifyService.getShopifyOrdersService, payload?.params);
        yield put({
            type: GET_SHOPIFY_ORDERS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getShopifyTransverse({ type, payload }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(shopifyService.getShopifyTransverseService, payload?.params);
        yield put({
            type: GET_SHOPIFY_TRANSVERSE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* syncResync({ type, payload }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(shopifyService.syncResyncService, payload?.params, payload?.orderId);
        yield put({
            type: GET_SHOPIFY_ORDERS,
            payload: {  params: payload?.requestParams, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* orderProductMapShopify({ type, payload }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(shopifyService.orderProductMapService, payload?.id);
        yield put({
            type: GET_ORDER_PRODUCT_MAP_SHOPIFY_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchShopify = function* watchShopifySaga() {
    /** Shopify Product */
    yield takeLatest(CREATE_SHOPIFY, createShopify);
    yield takeLatest(ADD_SHOPIFY_PRODUCT, addShopifyProduct);
    yield takeLatest(EDIT_SHOPIFY_PRODUCT, editShopifyProduct);
    yield takeLatest(DELETE_SHOPIFY_PRODUCT, deleteShopifyProduct);
    yield takeLatest(GET_SHOPIFY, getShopify);

    /** Shopify Setup */
    yield takeLatest(GET_SHOPIFY_SETUP, getShopifySetup);
    yield takeLatest(POST_SHOPIFY_SETUP, addShopifySetup);
    yield takeLatest(PATCH_SHOPIFY_SETUP, changeShopifySetup);

    /** Shopify Orders */
    yield takeLatest(GET_SHOPIFY_ORDERS, getShopifyOrders);
    yield takeLatest(GET_SHOPIFY_TRANSVERSE, getShopifyTransverse);
    yield takeLatest(SYNC_RESYNC_SHOPIFY, syncResync);
    yield takeLatest(GET_ORDER_PRODUCT_MAP_SHOPIFY, orderProductMapShopify);
};
