export const RoutersInfo = [
    {
        name: 'Users',
        icon: 'Users',
        path: '/users',
    },
    {
        name: 'Erply',
        icon: 'Erply',
        path: '/erply',
    },
    {
        name: 'Amazon',
        icon: 'Amazon',
        path: '/amazon',
    },
    // {
    //     "name": "Walmart",
    //     "icon": "Walmart",
    //     "path": "/walmart",
    // },
    // {
    //     "name": "Ebay",
    //     "icon": "Ebay",
    //     "path": "/ebay",
    // },
    {
        name: 'Carecraft',
        icon: 'Carecraft',
        path: '/carecraft',
    },
    {
        name: 'Shopify',
        icon: 'Shopify',
        path: '/shopify',
    },
];
