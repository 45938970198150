import { makeStyles } from '@mui/styles';

export const buttonsStyle = makeStyles((theme) => ({
    buttonsTab: {
        '& .MuiPaper-root': {
            background: 'none',
            boxShadow: 'none',
        },
        '& span': {
            background: 'none'
        },

        "& .MuiTabs-flexContainer": {
            borderRadius: "18px",
            display: 'flex',
            alignItems: 'center',
        },

        "& .MuiTabs-root": {
            minHeight: "40px !important",
            height: "40px  !important",
            display: "flex",
            alignItems: "center",
            justifyContent: 'space-between'
        },

        "& .MuiTabs-fixed": {
            minHeight: "40px",
            display: 'flex',
            alignItems: 'center',
            background:'white',
            borderRadius:'8px',
            padding:'4px'
        },
        "& .PrivateTabIndicator-colorPrimary-30": {
            display: "none",
        },
        "& .MuiTab-root": {
            // minWidth: '103px',
            // maxWidth: '103px',
            minHeight: '40px',
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            // minWidth: "103px",
            minHeight: "40px",
            maxHeight: "40px",
            background: "#F4E5F3",
            borderRadius: "8px",
            fontSize: "14px",
            lineHeight: "19px",
            color: '#BA43B1',
            textTransform: "capitalize",
            marginLeft: '2px',

            '& :hover': {
                background: '#F4E5F3',
                // minWidth: "103px",
                minHeight: "40px",
                maxHeight: "40px",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "19px",
                color: 'white',
                textTransform: "capitalize",
            }
        },

        "& .MuiTab-textColorPrimary": {
            // minWidth: "103px",
            minHeight: "40px",
            maxHeight: "40px",
            borderRadius: "8px",
            fontSize: "14px",
            lineHeight: "19px",
            color: 'rgba(42, 55, 78, 0.5)',
            textTransform: "capitalize",
            marginLeft: '2px',

            '& :hover': {
                background: '#E6ECF380 0% 0% no-repeat padding-box',
                minWidth: "103px",
                minHeight: "32px",
                maxHeight: "32px",
                borderRadius: "18px",
                fontSize: "14px",
                lineHeight: "19px",
                color: 'rgba(42, 55, 78, 0.5)',
                textTransform: "capitalize",
            }
        },
    },

}))