import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { CloseButton, AddModalButton } from 'components';
import { httpRequestsOnSuccessActions, erplyActions } from 'store';
import { FindSuccess } from 'hooks';
import { useModal } from 'utils';
import { TextField } from '@mui/material';
import { TEXT_FIELD_STYLES_SMALL } from 'fragments/modals/constants';

export const AddWareHouse = ({ werHouse }) => {
    const { handleSubmit, control, register, reset, formState: { errors } } = useForm({
        defaultValues: { name: '', warehouseId: '' },
    });
    const ACTION_TYPE = werHouse ? 'EDIT_WAREHOUSE' : 'CREATE_WAREHOUSE';
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const { close } = useModal();

    useEffect(() => {
        if (werHouse?.id) {
            reset({
                name: werHouse?.name,
                warehouseId: werHouse?.warehouseId,
            });
        }
    }, [werHouse]);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            reset({ warehouseId: '', name: '', });
        }
    }, [success]);

    const onSubmit = (data) => {
        if (werHouse?.id) {
            dispatch(erplyActions.editWareHouse({ ...data, id: werHouse?.id }, werHouse?.id));
        } else {
            dispatch(erplyActions.createWareHouse(data));
        }
    };

    return (
        <div className='add-modal-fragment'>
            <div className='modal-header'>
                <CloseButton handleClose={close} />
            </div>
            <div className='modal-body'>
                <p className='add-modal-title'>
                    {werHouse?.id ? 'Edit Warehouse' : 'Add Warehouse'}
                </p>
                <p className='add-modal-subtitle'>*All product codes are unique.</p>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Controller
                            name={'warehouseId'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('warehouseId', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Set Warehouse ID'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                        <Controller
                            name={'name'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('name', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Set Name'}
                                    size='medium'
                                    sx={TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                        <AddModalButton
                            type='submit'
                            text={werHouse?.id ? 'Save' : 'Add'}
                            load={ACTION_TYPE}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
