import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { amazonActions, httpRequestsOnSuccessActions } from 'store';
import { CloseButton } from 'components';
import { TextField } from '@mui/material';
import { AUTHENTICATION_TEXT_FIELD_STYLES_SMALL } from '../../../../../fragments/modals/constants';
import { AddModalButton } from 'components';
import { FindSuccess } from 'hooks';
import { useModal } from 'utils';

const ACTION_TYPE = 'EDIT_AMAZON_TYPE'

export const EditTypeModal = ({ info, callBack }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: info?.name,
        },
    });
    const success = FindSuccess(ACTION_TYPE);
    const { close } = useModal()

    useEffect(() => {
        if (success) {
            close()
            callBack()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        if (info) {
            reset({
                'name': info?.name,
            });
        }
    }, [info]);

    const onSubmit = (data) => {
        dispatch(amazonActions.editAmazonType(data, info?.id));
    };

    return (
        <div className='delete-modal-fragment'>
            <div className='modal-header'>
                <CloseButton handleClose={close} />
            </div>
            <div className='modal-body'>
                <div className='body-title'>
                    <p className='add-modal-title'>
                        Edit product Type
                    </p>
                </div>
                <form className='modal-form' onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name={'name'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register('name', { require: true })}
                                required={true}
                                onChange={onChange}
                                value={value}
                                label={'Pillow'}
                                size='medium'
                                sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                            />
                        )}
                    />
                    <AddModalButton text='Save' load={'EDIT_AMAZON_TYPE'} type='submit' />
                </form>
            </div>
        </div>
    );
};