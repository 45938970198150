
/** Create Amazon */
export const CREATE_EBAY = 'CREATE_EBAY';
export const CREATE_EBAY_SUCCESS = 'CREATE_EBAY_SUCCESS';

/** Add, Edit Amazon product */
export const ADD_EBAY_PRODUCT = 'ADD_EBAY_PRODUCT'
export const ADD_EBAY_PRODUCT_SUCCESS = 'ADD_EBAY_PRODUCT_SUCCESS'

export const EDIT_EBAY_PRODUCT = 'EDIT_EBAY_PRODUCT'
export const EDIT_EBAY_PRODUCT_SUCCESS = 'EDIT_EBAY_PRODUCT_SUCCESS'

/** Get Amazon */
export const GET_EBAY = 'GET_EBAY';
export const GET_EBAY_SUCCESS = 'GET_EBAY_SUCCESS';

/** Delete Amazon Product */
export const DELETE_EBAY_PRODUCT = 'DELETE_EBAY_PRODUCT';
export const DELETE_EBAY_PRODUCT_SUCCESS = 'DELETE_EBAY_PRODUCT_SUCCESS';

