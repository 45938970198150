import {
    ADD_AMAZON_TYPE_SUCCESS,
    DELETE_AMAZON_TYPE_SUCCESS,
    EDIT_AMAZON_TYPE_SUCCESS,
    GET_AMAZON_SETUP,
    GET_AMAZON_SUCCESS,
    GET_AMAZON_TYPE_SUCCESS,
    POST_AMAZON_SETUP,
    PATCH_AMAZON_SETUP,
    GET_AMAZON_SETUP_SUCCESS,
    DELETE_AMAZON_PRODUCT_SUCCESS,
    EDIT_AMAZON_PRODUCT, GET_AMAZON_ORDERS_SUCCESS, GET_AMAZON_TRANSVERS_SUCCESS, GET_ORDER_PRODUCT_MAP_SUCCESS,
} from './amazon.types';

const initialState = {
    amazonProducts: null,
    deleteAmazonProduct: null,
    editProduct: null,
    setUp: null,
    amazonType: null,
    addAmazonType: null,
    editAmazonType: null,
    deleteAmazonId: null,
    getAmazonSetup: null,
    addAmazonSetup: null,
    editAmazonSetup: null,
    amazonOrders: null,
    amazonTransvers: null,
    orderProductMap: null,
};

export const amazonReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Amazon Products */

        case GET_AMAZON_SUCCESS:
            return {
                ...state,
                amazonProducts: action.payload,
            };

        case GET_AMAZON_SETUP_SUCCESS:
            return {
                ...state,
                setUp: { ...action.payload },
            };

        case DELETE_AMAZON_PRODUCT_SUCCESS:
            return {
                ...state,
                deleteAmazonProduct: action.payload,

            };
        case EDIT_AMAZON_PRODUCT: {
            return {
                ...state,
                editProduct: action.payload,
            };
        }

        /** Amazon Type */

        case GET_AMAZON_TYPE_SUCCESS:
            return {
                ...state,
                amazonType: action.payload,
            };

        case ADD_AMAZON_TYPE_SUCCESS:
            return {
                ...state,
                addAmazonType: action.payload,
            };

        case EDIT_AMAZON_TYPE_SUCCESS:
            return {
                ...state,
                editAmazonType: action.payload,
            };

        case DELETE_AMAZON_TYPE_SUCCESS:
            return {
                ...state,
                deleteAmazonId: action.payload,
            };

        /** Amazon Setup  */

        case GET_AMAZON_SETUP:
            return {
                ...state,
                getAmazonSetup: action.payload,
            };

        case POST_AMAZON_SETUP:
            return {
                ...state,
                addAmazonSetup: action.payload,
            };

        case PATCH_AMAZON_SETUP:
            return {
                ...state,
                editAmazonSetup: action.payload,
            };

        /** Amazon Orders  */

        case GET_AMAZON_ORDERS_SUCCESS:
            return {
                ...state,
                amazonOrders: action.payload,
            };

        case GET_AMAZON_TRANSVERS_SUCCESS:
            return {
                ...state,
                amazonTransvers: action.payload,
            };

        case GET_ORDER_PRODUCT_MAP_SUCCESS:
            return {
                ...state,
                orderProductMap: action.payload,
            };


        default:
            return state;
    }
};
