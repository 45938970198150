import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {erplyActions} from 'store';
import {FindLoad, FindSuccess} from 'hooks';
import {AddModalButton, Line, Loader, NumberCircle} from 'components';
import {Images} from 'assets/images';
import {TextField} from '@mui/material';
import {TEXT_FIELD_STYLES_SMALL} from '../../../fragments/modals/constants';

export const Settings = ({info}) => {
    const dispatch = useDispatch();
    const loader = FindLoad('GET_ERPLY');
    const editSuccess = FindSuccess('EDIT_ERPLY')
    const success = FindSuccess('CREATE_ERPLY')

    const [open, setOpen] = useState(false)
    const {
        handleSubmit,
        control,
        register,
        setError,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            'clientCode': info?.clientCode,
            'userName': info?.userName,
            'password': info?.password,
            'wareHouseId': info?.wareHouseId,
            'amazonCustomer': info?.amazonCustomer,
            'walmartCustomer': info?.walmartCustomer,
            'ebayCustomer': info?.ebayCustomer,
            'shopifyCustomer': info?.shopifyCustomer,
            'shopifyWarehouseId': info?.shopifyWarehouseId,
        },
    });
    const onSubmit = (data) => {
        const sendData = {
            username: data?.userName,
            warehouseId: data?.wareHouseId,
            ...data,
        };
        delete sendData.userName;
        delete sendData.wareHouseId;
        if (info) {
            dispatch(erplyActions.editErply(sendData));
        } else {
            dispatch(erplyActions.createErply(sendData));
        }
    };

    useEffect(() => {
        if (info) {
            reset({
                'clientCode': info?.clientCode,
                'userName': info?.userName,
                'password': info?.password,
                'wareHouseId': info?.wareHouseId,
                'amazonCustomer': info?.amazonCustomer,
                'walmartCustomer': info?.walmartCustomer,
                'ebayCustomer': info?.ebayCustomer,
                'shopifyCustomer': info?.shopifyCustomer,
                'shopifyWarehouseId': info?.shopifyWarehouseId,
            });
        }
    }, [info]);

    useEffect(() => {
        if (editSuccess || success) {
            setOpen(!open)
        }
    }, [editSuccess, success])


    const handleOpenSave = () => {
        setOpen(prev => !prev)
    }

    return (
        <div className='settings-wrapper'>
            {loader?.length ?
                <Loader/>
                :
                <form onSubmit={handleSubmit(onSubmit)}
                      style={{display: 'flex', flexDirection: 'column', width: '100%'}}>


                    <>
                        <div className='space-between'>
                            <div className='flex'>
                                <NumberCircle number={1}/>
                                <p className='title-style'>Authentication configuration</p>
                            </div>
                            <div style={{height: '36px'}}>
                                {!open &&
                                    <button onClick={handleOpenSave} className='edit-button'>
                                        <img src={Images.Edit} alt='icon'/>
                                        <p>Edit</p>
                                    </button>
                                }
                            </div>
                        </div>

                        <div className='line-inputs-wrapper'>
                            <Line height={'96px'}/>
                            <div className='inputs-wrapper'>
                                <Controller
                                    name={'clientCode'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{marginRight: '15px'}}
                                            {...register('clientCode', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Client Code'}
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />
                                <Controller
                                    name={'userName'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{marginRight: '15px'}}
                                            {...register('userName', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Username'}
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />
                                <Controller
                                    name={'password'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            {...register('password', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Password'}
                                            // type='password'
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />

                            </div>
                        </div>
                    </>

                    <div className='settings-wrapper'>
                        <div className='space-between'>
                            <div className='flex'>
                                <NumberCircle number={2}/>
                                <p className='title-style'>Additional Information</p>
                            </div>
                        </div>
                        <div className='line-inputs-wrapper' style={{alignItems: 'flex-start'}}>
                            <div>
                                <Line height={'130px'}/>
                                <img src={Images.Success} alt='icon' style={{marginTop: '16px'}}/>
                            </div>

                            <div className='inputs-wrapper-flex'>
                                <Controller
                                    name='wareHouseId'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{ width: `calc(100% / 3 - 15px)`}}
                                            {...register('wareHouseId', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Warehouse ID'}
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />

                                <Controller
                                    name='walmartCustomer'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{ width: `calc(100% / 3 - 15px)`}}
                                            {...register('walmartCustomer', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Walmart Customer'}
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />
                                <Controller
                                    name='amazonCustomer'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{ width: `calc(100% / 3 - 15px)`}}
                                            {...register('amazonCustomer', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Amazon Customer'}
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />
                                <Controller
                                    name='ebayCustomer'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{ width: `calc(100% / 3 - 15px)`}}
                                            {...register('ebayCustomer', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label={'Ebay Customer'}
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />

                                <Controller
                                    name='shopifyCustomer'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{ width: `calc(100% / 3 - 15px)`}}
                                            {...register('shopifyCustomer', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label='Shopify Customer'
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />

                                <Controller
                                    name='shopifyWarehouseId'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            style={{ width: `calc(100% / 3 - 15px)`}}
                                            {...register('shopifyWarehouseId', {require: true})}
                                            required={true}
                                            onChange={onChange}
                                            value={value}
                                            label='Shopify Warehouse ID'
                                            size='medium'
                                            sx={TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        {open &&
                            <div className='flex-end'>
                                <div className='list-buttons-wrapper'>
                                    <button
                                        onClick={() => setOpen(false)}
                                        className='chancel-button' type={'button'}>Cancel
                                    </button>
                                    <AddModalButton
                                        text={info ? 'Save' : 'Create'}
                                        type='submit'
                                        load={info ? 'EDIT_ERPLY' : 'CREATE_ERPLY'}
                                    />
                                </div>
                            </div>
                        }

                    </div>
                </form>
            }
        </div>
    );
};