import LoginBackground from './png/loginBackground.png';
import Error from './svg/error.svg';
import UserProfile from './svg/userProfile.svg';
import AddUser from './menuIcons/Icon-add-user.svg';
import AddUserFill from './menuIcons/Icon-add-user-fill.svg';
import Amazon from './menuIcons/icon-amazon.svg';
import AmazonFill from './menuIcons/icon-amazon-fill.svg';
import Ebay from './menuIcons/icon-ebay.svg';
import EbayFill from './menuIcons/icon-ebay-fill.svg';
import Erply from './menuIcons/icon-erply.svg';
import ErplyFill from './menuIcons/icon-erply-fill.svg';
import Walmart from './menuIcons/icon-walmart.svg';
import WalmartFill from './menuIcons/icon-walmart-fill.svg';
import ZtoA from './svg/zToA.svg';
import AtoZ from './svg/aToZ.svg';
import Edit from './svg/edit.svg';
import Remove from './svg/remove.svg';
import Add from './svg/add.svg';
import Settings from './svg/settings.svg';
import Success from './svg/success.svg';
import Email from './svg/email.svg';
import Photo from './svg/photo.svg';
import SignOut from './svg/signOut.svg';
import ToastSuccess from './toast/success.svg';
import ToastError from './toast/error.svg';

import CareCraftFill from './svg/carecraft-active.svg';
import Shopify from './svg/Shopify.svg';
import ShopifyFill from './svg/ShopifyFill.svg';
import CareCraft from './svg/carecraft.svg';
import CreatedBy from './svg/createdBy.svg';
import Close from './svg/close.svg';
import AddPrimary from './svg/addPrimary.svg';

export const Images = {
    // Menu Images
    AddUser,
    AddUserFill,
    Amazon,
    AmazonFill,
    Ebay,
    EbayFill,
    Erply,
    ErplyFill,
    Shopify,
    ShopifyFill,
    Walmart,
    WalmartFill,

    // SVG
    LoginBackground,
    Error,
    UserProfile,
    ZtoA,
    AtoZ,
    Edit,
    Remove,
    Add,
    Settings,
    Success,
    Email,
    Photo,
    SignOut,
    CreatedBy,
    CareCraftFill,
    CareCraft,
    Close,
    AddPrimary,
    //PNG
    ToastSuccess,
    ToastError,
};
