import {
    DELETE_SHOPIFY_PRODUCT_SUCCESS,
    EDIT_SHOPIFY_PRODUCT,
    GET_ORDER_PRODUCT_MAP_SHOPIFY_SUCCESS,
    GET_SHOPIFY_ORDERS_SUCCESS,
    GET_SHOPIFY_SETUP,
    GET_SHOPIFY_SETUP_SUCCESS,
    GET_SHOPIFY_SUCCESS,
    GET_SHOPIFY_TRANSVERSE_SUCCESS,
    PATCH_SHOPIFY_SETUP,
    POST_SHOPIFY_SETUP
} from './shopify.types';

const initialState = {
    shopifyProducts: null,
    deleteShopifyProduct: null,
    editProduct: null,
    setUp: null,
    getShopifySetup: null,
    addShopifySetup: null,
    editShopifySetup: null,
    shopifyOrders: null,
    shopifyTransverse: null,
    orderProductMap: null,
};

export const shopifyReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Amazon Products */

        case GET_SHOPIFY_SUCCESS:
            return {
                ...state,
                shopifyProducts: action.payload,
            };

        case GET_SHOPIFY_SETUP_SUCCESS:
            return {
                ...state,
                setUp: { ...action.payload },
            };

        case DELETE_SHOPIFY_PRODUCT_SUCCESS:
            return {
                ...state,
                deleteShopifyProduct: action.payload,

            };
        case EDIT_SHOPIFY_PRODUCT: {
            return {
                ...state,
                editProduct: action.payload,
            };
        }

        /** Shopify Setup  */

        case GET_SHOPIFY_SETUP:
            return {
                ...state,
                getShopifySetup: action.payload,
            };

        case POST_SHOPIFY_SETUP:
            return {
                ...state,
                addShopifySetup: action.payload,
            };

        case PATCH_SHOPIFY_SETUP:
            return {
                ...state,
                editShopifySetup: action.payload,
            };

        /** Shopify Orders  */

        case GET_SHOPIFY_ORDERS_SUCCESS:
            return {
                ...state,
                shopifyOrders: action.payload,
            };

        case GET_SHOPIFY_TRANSVERSE_SUCCESS:
            return {
                ...state,
                shopifyTransverse: action.payload,
            };

        case GET_ORDER_PRODUCT_MAP_SHOPIFY_SUCCESS:
            return {
                ...state,
                orderProductMap: action.payload,
            };


        default:
            return state;
    }
};
