import React from 'react';
import { CloseButton, Loader, TableNoInfo } from 'components';
import { FindLoad } from 'hooks';
import { useSelector } from 'react-redux';

export const ProductMapModal = ({ item, handleClose }) => {
    const loader = FindLoad('GET_ORDER_PRODUCT_MAP');

    const { orderProductMap } = useSelector((state) => ({
        orderProductMap: state.shopify.orderProductMap,
    }));

    return (
        <div className="product-map-modal">
            <div className="product-map-modal-head">
                <p>Product Map</p>
                <CloseButton handleClose={handleClose} />
            </div>
            {loader?.length ?
                <Loader style={{ position: 'relative' }} />
                :
                <div className="product-map-modal-body">
                    <div className="product-map-box">
                        <p>Erply Product ID</p>
                        <p>Multiplier</p>
                    </div>
                    <div className="product-map-item-body">
                        {orderProductMap?.erplyProducts?.length ? orderProductMap?.erplyProducts?.map((i, j) => (
                                <div className="product-map-item-box" key={j}>
                                    <p>{i?.erplyProductId}</p>
                                    <p>{i?.multiplier}</p>
                                </div>
                            ))
                            :
                            <TableNoInfo text={`No Items Yet`} styles={{ position: 'relative' }} />
                        }
                    </div>
                </div>
            }
        </div>
    );
};