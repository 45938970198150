import { NameRow, ActivePending, EmailRow, DateRow, TextRow } from 'components';
import React from 'react';
import { Images } from 'assets/images';

const renderButtons = (data, info, load) => {
    return (
        <div style={{alignItems:'flex-start'}} className="del-edit-wrapper">
            <button onClick={() => data('edit')}>
                <img src={Images.Edit} alt={'icon'} />
                <p className="edit-text">Edit</p>
            </button>
            <button onClick={() => data('delete')}>
                <img src={Images.Remove} alt={'icon'} />
                <p className="delete-text">Delete</p>
            </button>
        </div>
    );
};

export const carecraftHead = [
    {name: 'searchRequest',nameRow: 'poId', title: 'ID', custom: false, },
    { name: 'addedDate', title: 'Added Date', type: 'date', custom: false },
    { name: 'createdDate', title: 'Created Date', type: 'date', custom: false },
    { name: 'poStatus', title: 'Order Status', custom: false },
    { name: 'action', title: 'Status',  custom: false },
    { name: '', title: 'Action', type: 'notChange', custom: false },
];

export const carecraftBody = [
    { rowText: (item) => <NameRow big={true} size={20} name={`${item?.firstName} ${item?.lastName ? item?.lastName : ''}`} /> },
    { rowText: (item) => <EmailRow name={item?.email} /> },
    { rowText: (item) => <DateRow date={item?.createdAt} /> },
    { rowText: (item) => <ActivePending type={item?.auth} /> },
    { rowText: (item) => <ActivePending type={item?.auth} /> },
];

export const ACTION_TYPE = 'GET_CARECRAFT';

export const carecraftMapHead = [
    {name: 'searchRequest',nameRow: 'sku', title: 'Carecraft SKU', custom: false, },
    // { name: 'sku', title: 'Carecraft SKU',},
    // { name: 'multiplier', title: 'Multiplier', },
    { name: '', title: 'Action', custom:false},
];

export const carecraftMapBody = [
    // { rowText: (item, width) => <TextRow name={item?.erplyCode} width={width}/> },
    { rowText: (item, width) => <TextRow name={item?.sku} width={width}/> },
    // { rowText: (item, width) => <TextRow name={item?.multiplier} width={width}/> },
    { button: (item, info, load) => renderButtons(item, info, load), notClickable: true },
];

export const MAP_ACTION_TYPE = 'GET_CARECRAFT_MAP';
