import React, { useState } from 'react';
import { inputsStyle } from './styles';
import { ErrMessage } from '../messages';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const PasswordInput = ({
                                  name,
                                  variant,
                                  handleChangePassword,
                                  disabled,
                                  value,
                                  placeholder,
                                  typeError,
                                  validator,
                                  sendBoolean,
                                  styles,
                                  margin,
                                  textStyle,

                              }) => {
        const [validEmail, setValidEmail] = useState(false);
        const [values, setValues] = useState({
            amount: '',
            password: '',
            weight: '',
            weightRange: '',
            showPassword: false,
        });

        const handleChanges = (prop) => (event) => {
            setValues({ ...values, [prop]: event.target.value });
            handleChangePassword(event);
        };

        const handleClickShowPassword = () => {
            setValues({ ...values, showPassword: !values.showPassword });
        };

        const handleMouseDownPassword = (event) => {
            event.preventDefault();
        };


        const chechValid = (e) => {
            let Value = e.target.value;
            if (Value.length >= 1) {
                if (validator) {
                    if (validator.test(Value)) {
                        setValidEmail(false);
                        sendBoolean(false);
                    } else {
                        setValidEmail(true);
                        sendBoolean(true);
                    }
                }
            }
        };

        return (
            <div style={{ ...styles }}>
                {variant === 'accountPassword' ?
                    <FormControl
                        className='input-wrapper'
                        variant='outlined'>
                        <InputLabel
                            htmlFor='outlined-adornment-password'>{placeholder}</InputLabel>
                        <OutlinedInput
                            error={typeError}
                            disabled={disabled}
                            id='outlined-adornment-password'
                            name={name}
                            type={values.showPassword ? 'text' : 'password'}
                            value={value}
                            onChange={handleChanges('password')}
                            onFocus={() => setValidEmail(false)}
                            onBlur={(e) => chechValid(e)}
                            label={placeholder}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        disabled={disabled}
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {disabled === true ? (
                                            <VisibilityOff />
                                        ) : values.showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <ErrMessage
                            type={'Pass'}
                            text={typeError}
                            style={margin ? { margin } : {}}
                            textStyle={textStyle ? { textStyle } : {}}
                        />
                    </FormControl>
                    :
                    <FormControl disabled={disabled} className='input-wrapper' variant='standard'>
                        <InputLabel htmlFor='outlined-adornment-password'>{placeholder}</InputLabel>
                        <Input
                            error={typeError}
                            className='password-input'
                            disabled={disabled}
                            id='standard-adornment-password'
                            name={name}
                            type={values.showPassword ? 'text' : 'password'}
                            value={value}
                            onChange={handleChanges('password')}
                            placeholder={placeholder}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        disabled={disabled}
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {disabled === true ? (
                                            <VisibilityOff />
                                        ) : values.showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <ErrMessage
                            type={'Pass'}
                            text={typeError}
                            style={margin ? { margin } : {}}
                            textStyle={textStyle ? { textStyle } : {}}
                        />
                    </FormControl>
                }
            </div>
        );
    }
;
