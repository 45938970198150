import {
  ADD_AMAZON_PRODUCT,
  ADD_AMAZON_TYPE,
  CREATE_AMAZON,
  DELETE_AMAZON_PRODUCT,
  DELETE_AMAZON_TYPE,
  EDIT_AMAZON_PRODUCT,
  EDIT_AMAZON_TYPE,
  GET_AMAZON, GET_AMAZON_ORDERS,
  GET_AMAZON_SETUP, GET_AMAZON_TRANSVERS, GET_AMAZON_TYPE, GET_ORDER_PRODUCT_MAP,
  PATCH_AMAZON_SETUP,
  POST_AMAZON_SETUP, SYNC_RESYNC,
} from './amazon.types';

/** Create, Edit Amazon Product */

export const addAmazon = (body) => {
  return {
    type: CREATE_AMAZON,
    payload: { body }
  }
}

export const addAmazonProduct = (body) => {
  return {
    type: ADD_AMAZON_PRODUCT,
    payload: { body }
  }
}

export const editAmazonProduct = (body, id,) => {
  return {
    type: EDIT_AMAZON_PRODUCT,
    payload: { body, id }
  }
}

export const getAmazon = (  params, load, searchType ) => {
  return {
    type: GET_AMAZON,
    payload: { params, load, searchType } ,
  }
}

export const deleteAmazonProduct = (id) => {
  return {
    type: DELETE_AMAZON_PRODUCT,
    payload: { id }
  }
}

/** End */

/** Amazon Type  */
export const getAmazonType = ( params, load ) => {
  return {
    type: GET_AMAZON_TYPE,
    payload: { params, load }
  }
}

export const addAmazonType = (body) => {
  return {
    type: ADD_AMAZON_TYPE,
    payload: { body }
  }
}

export const editAmazonType = (body, id) => {
  return {
    type: EDIT_AMAZON_TYPE,
    payload: { body, id }
  }
}

export const deleteAmazonType = (id) => {
  return {
    type: DELETE_AMAZON_TYPE,
    payload: { id }
  }
}

/** End */

/** Amazon Setup  */

export const getAmazonSetup = () => {
  return {
    type: GET_AMAZON_SETUP,
  }
}

export const changeAmazonSetup = (body) => {
  return {
    type: PATCH_AMAZON_SETUP,
    payload: { body }
  }
}

export const createAmazonSetup = (body) => {
  return {
    type: POST_AMAZON_SETUP,
    payload: { body }
  }
}

/** End */

/** Amazon Orders  */

export const getAmazonOrders = (params) => {
  return {
    type: GET_AMAZON_ORDERS,
    payload: { params }
  }
}

export const getAmazonTransvers = (params) => {
  return {
    type: GET_AMAZON_TRANSVERS,
    payload: { params }
  }
}

export const syncResync = (params, orderId, requestParams) => {
  return {
    type: SYNC_RESYNC,
    payload: { params, orderId, requestParams }
  }
}
export const orderProductMap = (id) => {
  return {
    type: GET_ORDER_PRODUCT_MAP,
    payload: { id }
  }
}

/** End */