import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { amazonActions, httpRequestsOnErrorsActions } from 'store';
import { AddModalButton, ErrMessage } from 'components';
import { FindError } from 'hooks';
import { Box, Grid, TextField, Switch, FormControlLabel } from '@mui/material';
import { AUTHENTICATION_TEXT_FIELD_STYLES_SMALL } from './constants';

export const Authentication = ({ handleClose, statusType, setUp }) => {
    /**
     * Authentication
     */


    const dispatch = useDispatch();
    const backError = FindError(statusType);
    const [checkedSwitch, setCheckedSwitch] = useState(false);
    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            refreshToken: setUp?.refreshToken || '',
            sellerId: setUp?.sellerId || '',
            clientId: setUp?.clientId || '',
            clientSecret: setUp?.clientSecret || '',
            accessKeyId: setUp?.accessKeyId || '',
            secretAccessKey: setUp?.secretAccessKey || '',
            lastOrderDate: setUp?.lastOrderDate || '',
            lastOrderId: setUp?.lastOrderId || '',
        },
    });

    useEffect(() => {
        if (setUp?.cronEnabled) {
            setCheckedSwitch(setUp?.cronEnabled);
        }
        if (setUp) {
            reset({
                refreshToken: setUp?.refreshToken || '',
                sellerId: setUp?.sellerId || '',
                clientId: setUp?.clientId || '',
                clientSecret: setUp?.clientSecret || '',
                accessKeyId: setUp?.accessKeyId || '',
                secretAccessKey: setUp?.secretAccessKey || '',
                lastOrderDate: setUp?.lastOrderDate || '',
                lastOrderId: setUp?.lastOrderId || '',
            });

        }
    }, [setUp]);

    const handleChange = () => {
        setCheckedSwitch(!checkedSwitch);
    };

    const onSubmit = (data) => {
        if (setUp) {
            const info = {
                ...data,
            };

            if (data?.lastOrderDate) {
                const newDate = moment(info?.lastOrderDate).format('YYYY-MM-DDT00:00:01');
                info.data = new Date(newDate);
            } else {
                delete info.lastOrderDate;
            }
            info.cronEnabled = checkedSwitch;
            dispatch(amazonActions.changeAmazonSetup(info));
        } else {
            dispatch(amazonActions.createAmazonSetup(data));
        }
    };

    const handleKeyDown = () => {
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='credentials-form'>
            {setUp && (
                <div className='credentials-swicher'>
                    <FormControlLabel
                        value='cronEnabled'
                        name='cronEnabled'
                        control={
                            <Switch
                                checked={checkedSwitch}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                className='switch-colors'
                                color={'secondary'}
                            />
                        }
                        label='Enable Cron'
                        labelPlacement='start'
                    />
                </div>
            )}
            <Controller
                name={'refreshToken'}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        {...register('refreshToken', { require: true })}
                        required={true}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        value={value}
                        label={'Refresh token'}
                        size='medium'
                        sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                    />
                )}
            />
            <Controller
                name={'sellerId'}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        {...register('sellerId', { require: true })}
                        required={true}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        value={value}
                        label={'Seller ID'}
                        size='medium'
                        sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                    />
                )}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                            name={'clientId'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('clientId', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    onKeyDown={handleKeyDown}
                                    value={value}
                                    label={'Client ID'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name={'clientSecret'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('clientSecret', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    onKeyDown={handleKeyDown}
                                    value={value}
                                    label={'Client Secret'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                            name={'accessKeyId'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('accessKeyId', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    onKeyDown={handleKeyDown}
                                    value={value}
                                    label={'Access Key ID'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name={'secretAccessKey'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register('secretAccessKey', { require: true })}
                                    required={true}
                                    onChange={onChange}
                                    onKeyDown={handleKeyDown}
                                    value={value}
                                    label={'Secret Access Key'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>

                <Controller
                    name={'lastOrderId'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            {...register('lastOrderId', { require: false })}
                            required={false}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            value={value}
                            label={'Last Order Id'}
                            size='medium'
                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                        />
                    )}
                />

            {setUp && (
                <Controller
                    name={'lastOrderDate'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            {...register('lastOrderDate', { require: false })}
                            required={false}
                            id='date'
                            label={'Last Order Date'}
                            type='date'
                            value={value ? moment(value).format('YYYY-MM-DD') : ''}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            size='medium'
                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                            // sx={SELECT_FIELD_STYLES}
                            style={{ borderColor: '#9c27b0' }}
                        />
                    )}
                />
            )}
            <Box>{backError?.error && <ErrMessage text={backError.error} />}</Box>
            <div className='list-buttons-wrapper'>
                <button className='chancel-button' onClick={handleClose} type={'button'}>
                    Cancel
                </button>
                <AddModalButton text='Save' type='submit'
                                load={setUp?.cronEnabled ? 'PATCH_AMAZON_SETUP' : 'POST_AMAZON_SETUP'} />
            </div>
        </form>
    );
};
